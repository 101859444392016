const getHost = () => {
    console.log(`HOST: ${process.env.REACT_APP_API_HOST}`);    
    return process.env.REACT_APP_API_HOST; //"https://go2drive-edge-uat.azure.cld";
}

const getContext = () => {
    if (process.env.REACT_APP_STAGE === 'mock') {
        return "";
    }
    let context = "/api";
    return context;
}

const getRefreshTokenUrl = () => {
    return getHost() + getContext() + "/token/refresh";
}

const getCustomersResourceUrl = (cid) => {
    return getHost() + getContext() + "/customers/" + cid;
}

const getStaticDataUrl = (dataType) => {
    return getHost() + getContext() + "/staticData/" + dataType;
}

const getEnumerationsUrl = (enumType) => {
    return getHost() + getContext() + "/enumerations/" + enumType;
}

const getUserInfoUrl = () => {
    return getHost() + getContext() + "/userInfo";
}

const getAvailableBranchesUrl = () => {
    return getHost() + getContext() + "/availableBranches";
}

const getCasesUrl = () => {
    return getHost() + getContext() + "/cases";
}

const getDocumentsUrl = (caseId) => {
    return getHost() + getContext() + "/documents";
}

const getCaseDetailsUrl = (caseId) => {
    return getCasesUrl() + "/" + caseId;
}

const getIncomeToolTokenUrl = (cid, creditInstitution, pools) => {
    return getHost() + getContext() + "/income-tool-token?cid=" + cid + "&creditInstitution=" + creditInstitution + "&pools=" + pools;
}

const getIncomeToolResultUrl = (cid, creditInstitution) => {
    return getHost() + getContext() + "/income?cid=" + cid  + "&creditInstitution=" + creditInstitution;
}

const getAuthEndpointUrl = (redirectTo) => {
    console.log(`Authentication URL: ${getHost() + "/oauth_login?redirect_uri=" + redirectTo} `);
    return getHost() + "/oauth_login?redirect_uri=" + redirectTo
}

const getLogoutEndpointUrl = (redirectTo) => {
    return getHost() + "/oauth_signout";
}

const getAdfsLogoutUrl = () => {
    return "https://sts.extranet.eurobank.gr/adfs/ls/?wa=wsignout1.0";
}

const getHistoryUrl = () => {
    return getHost() + getContext() + "/history";
}

const getIncomeToolRedirectUrl = () => {
    return process.env.REACT_APP_INCOME_TOOL_URL;
}

const getAuthConfig = () => {
    return {
        authToken: 'AUTH',
        refreshToken: 'REFRESH_TOKEN',
        xsrfToken: 'XSRF-TOKEN'
    }
}

const putWithdrawCaseUrl = () => {
    return getHost() + getContext() + "/cases/withdraw"
}

const postCreateCaseUrl = () => {
    return getHost() + getContext() + "/cases"
}

let apiConfig = {
    getRefreshTokenUrl,
    getCustomersResourceUrl,
    getStaticDataUrl,
    getEnumerationsUrl,
    getAvailableBranchesUrl,
    getCaseDetailsUrl,
    getCasesUrl,
    getDocumentsUrl,
    getIncomeToolTokenUrl,
    getIncomeToolResultUrl,
    getAuthEndpointUrl,
    getHistoryUrl,
    getIncomeToolRedirectUrl,
    getAuthConfig,
    postCreateCaseUrl,
    putWithdrawCaseUrl,
    getLogoutEndpointUrl,
    getAdfsLogoutUrl,
    getUserInfoUrl
}

export default apiConfig;