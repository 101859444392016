import React from 'react';
import { fields } from "../config/fields_el";
import FormInput from "../components/FormInput";
import StyledDivider from '../components/styled-components/layout/StyledDivider';
import {Col, Descriptions, Select, Tag} from 'antd';
import moment from "moment";
import { getBrowserLocale } from '../config/Properties';
import {
    originBranchCodeOfExternalFPS,
    originCodeOfFPS,
    categoryType,
    assetGroup,
    withdrawCaseAction,
    subCategoryOfCase,
    idTypeExcluded
} from "../config/Properties";
const { Option } = Select;

/**
 * Given an array of sections it renders the form sections using Styledivider to separate them.
 * If no section type or section title is provided no dividers are rendered.
 *
 * @param {*} sections
 * @param {*} fieldDecorator
 */
export const renderFormComponents = (sections, fieldDecorator) => {
    return sections.map((sec, i) => {
        return <div key={i}>
            {(sec.type && sec.title) ? <StyledDivider subsection={sec.subsection} orientation={sec.orientation} type={sec.type} title={sec.title} /> : null}
            {renderColumns(sec.columns, fieldDecorator)}
        </div>

    })
}
/**
 * Given an array of form columns it renders the columns applying the provided styles.
 *
 * @param {*} columns
 * @param {*} fieldDecorator
 */
const renderColumns = (columns, fieldDecorator) => {
    if(!columns) return null;

    return columns.map((col, i) => {
        return <Col sm={col.styles.sm} md={col.styles.md} lg={col.styles.lg} key={i}>
            {renderInputBlock(col.inputs, fieldDecorator)}
        </Col>
    })
}

/**
 * Given an array of objects that describe an input it renders the inputs.
 * NOTE: For layouts, in case a labelCol or wrapperCol is defined on an FormInput,
 * it takes precedence over formItemLayout that is defined on the block
 *
 * @param {Array} inputs
 * @param {Function} fieldDecorator from antd Form props
 */
export const renderInputBlock = (inputBlock, fieldDecorator) => {
    return inputBlock.inputs.map((block, i) => {
        return (
            <FormInput key={i}
                inputType={block.type}
                fieldDecorator={fieldDecorator}
                label={block.label}
                rules={block.rules}
                disabled={block.disabled}
                value={block.value}
                iconType={block.iconType}
                fieldName={block.fieldName}
                selectOptions={block.selectOptions}
                radioOptions={block.radioOptions}
                formItemLayout={inputBlock.layout}
                wrapperCol={block.wrapperCol}
                showSearch={block.showSearch}
                labelCol={block.labelCol}
                checkboxmessage={block.checkBoxMessage}
                addonBefore={block.addonBefore}
                onChange={block.onChange}
                onSelect={block.onSelect}
            />
        );
    })
}

/**
 * Returns
 *      <Descriptions> (list of Description.Item) </Descriptions>
 *  given a list of Description Items and layout preferences
 * @param displayItems the description used to build the Description Items
 * @param layoutOption vertical or horizontal
 * @param numberOfColumns the number of columns to display the Description Items
 * @returns the Descriptions
 */
export const renderDescriptions = (displayItems, layoutOption, numberOfColumns) => {
    return <Descriptions layout={layoutOption} column={numberOfColumns}>
        {renderDescriptionItems(displayItems)}
    </Descriptions>
};

/**
 * Builds the Description Items given a list of such items
 * @param displayItems the list of Description Items
 * @returns {*}
 */
const renderDescriptionItems = (displayItems) => {
    return displayItems.map((item, i) => {
        return (
            <Descriptions.Item key={i} label={item.titleOfField}>{item.fieldValue}</Descriptions.Item>
        );
    });
};

/**
 * Builds the Option Items given a list of such items
 * @param displayItems the list of Description Items
 * @returns {*}
 */
export const renderOptionItems = (displayItems) => {
    if(displayItems){
        return Object.keys(displayItems).map((key) => displayItems[key]).map((item, i) => {
        return (
            <Option key={i} value={item}>{item}</Option>
    );
    });}
};

/**
 * Builds the Option Items given a list of such items for creditInstitution
 * @param displayItems the list of Description Items
 * @returns {*}
 */
export const renderOptionItemsCredit = (displayItems) => {
    if(displayItems){
        return Object.keys(displayItems).map((key) => displayItems[key]).map((item, i) => {
            return (
                <Option key={i} value={Object.keys(displayItems)[i]}>{item}</Option>
            );
        });}
};

/**
 * Filter array with idTypes to exclude an option
 * @param initialMap
 * @param typeToExclude
 * @returns {*}
 */
export const filterOutSingleType = (initialMap, typeToExclude ) => {
    return initialMap.filter(a => a.value !== idTypeExcluded)
};

/**
 * Given gender value it maps it to display name.
 *
 * @param {string} gender
 * @returns {string} the display name
 */
export const mapGenderValues = (gender) => {
    switch (gender) {
        case 'male':
            return fields.male;
        case 'female':
            return fields.female;
        default:
            return null;
    }
};

export const displayBooleanValues = (value) => {
    return value ? fields.valueTrue : fields.valueFalse;
};

/**
 * Displays a static datum. If displayCode is true the display will be "code - name" else "name".
 *
 * @param {object} staticDatum
 * @param {Boolean} displayCode
 * @returns {string}
 */
export const displayStaticDatum = (staticDatum, displayCode) => {
    return displayCode ? staticDatum.code + " - " + staticDatum.name : staticDatum.name;
};

/**
 * Converts an array of static data to select items of type {value: '', display: '', disabled: true/false}.
 * If displayCode is true the display will be "code - name" else "name".
 * If use objectValue is true then value will be the whole staticData object and not only the code
 *
 * @param {Array} staticData
 * @param {boolean} displayCode
 * @param {boolean} useObjectValue
 * @returns {Array}
 */
export const mapStaticDataToSelectItems = (staticData, displayCode) => {
    let staticDataSelectItems = [{ value: null, display: fields.select_value, disabled: true }];
    staticData.forEach(ta => {
        let staticDatumSelect = { value: ta.code, display: displayStaticDatum(ta, displayCode) };
        staticDataSelectItems.push(staticDatumSelect);

    });
    return staticDataSelectItems;

};

export const mapEnumerationsToSelectItems = (enumerations, nullValueText) => {
    let selectItems = [{ value: null, display: (!nullValueText ? fields.select_value : nullValueText), disabled: true }];
    Object.keys(enumerations).forEach(function (key) {
        let enumSelect = { value: key, display: enumerations[key]};
        selectItems.push(enumSelect);
    });
    return selectItems;
};

export const mapOfficesToSelectItems = (offices) => {
    if(offices) {
        let selectItems = [];
        Object.keys(offices).forEach(function (key) {
            let office = { value: key, display: key + ' - ' + offices[key]};
            selectItems.push(office);
        });
        return selectItems;
    }
};

/**
 * convert interests to percent format adding symbol %
 * @param interest
 * @returns {string}
 */
export const percentageFormat = (interest) => {
    return interest.toLocaleString(getBrowserLocale()) + ' %';
};

/**
 * Converts currency amount to currency separated
 * @param amount
 * @returns {string}
 */
export const convertToCurrency = (amount) => {
    console.log(getBrowserLocale());
    return amount.toLocaleString(getBrowserLocale(), { style: 'currency', currency: 'EUR' })
};

/**
 * Converts idCode to display id name as type of String
 * @param staticData
 * @param idCode
 * @returns {string}
 */
export const getIdStaticDataNameFromCode = (staticData, idCode) => {
    let staticDatum =  staticData.filter(sd => sd.code === idCode);
    return staticDatum.length === 1 ? staticDatum[0].name : '';
};

export const getNameFromCode = (data, code) => {
    return data[code];
}

/**
 * Converts an array of radio selection options to items of type {value: '', display: '', disabled: true/false}
 *
 * @param inputItems input values
 * @param singleSelection the single item that is selectable
 * @returns {Array}
 */
export const mapInputItemsToRadioOptions = (inputItems, singleSelection) => {
    let radioOptions = [];
    Object.keys(inputItems).forEach(function (key) {
        let ro = {value: inputItems[key], disabled: (singleSelection ? (singleSelection !== inputItems[key]) : false), display: inputItems[key]};
        radioOptions.push(ro);
    });
    return radioOptions;
};

/**
 * Returns the actual value of the input, if exists
 * Otherwise, it returns '-'
 * @param datum the value to display
 * @returns {string} as described above
 */
export const getDisplayValue = (datum) => {
    return datum || datum === 0 ? datum : '-';
};

/**
 * Applies format to date
 * @param date the given date which is a Moment object
 * @param dateFormat the dateFormat
 * @returns {string}
 */
export const applyDateFormat = (date, dateFormat) => {
    if (date instanceof moment)
        return date.format(dateFormat);
    return moment(date).utc(false).format(dateFormat);
};

/**
 * Returns a Tag with/without a label
 * @param key the key of the Tag
 * @param color the color to display
 * @param fieldLabel the label
 * @param value the actual value of the tag
 * @param showOnlyTitle true if only title should be displayed
 * @returns a Tag according to the specified values and preferences
 */
export const createTag = (key, color, fieldLabel, value, showOnlyTitle) => {
    if(!value && !showOnlyTitle) return null;

    let contentValue  = '';
    if(fieldLabel) { contentValue = fieldLabel + (showOnlyTitle ? ' ' : ': ')}
    if(value) { contentValue = contentValue + value}

    return <Tag key={key} color={color}>
        <div style={{overflow: 'hidden'}}>
            {contentValue}
        </div>
    </Tag>
};

export const mapStates = (state) => {
    let color = 'green';
    let indication = fields.in_progress;

    switch (state) {
        case 'WITHDRAWN': {
            color = 'volcano';
            indication = fields.cancel;
            break;
        }
        case 'REJECTED': {
            color = 'volcano';
            indication = fields.rejection;
            break;
        }
        case 'COMPLETED': {
            color = 'orange';
            indication = fields.completed;
            break;
        }
        case 'APPROVED': {
            color = 'grey';
            indication = fields.approved;
            break;
        }
        case 'IN_PROGRESS': {
            color = 'green';
            indication = fields.in_progress;
            break;
        }
        default: {
            color = 'volcano';
            indication = fields.financial_problems_not_available;
            break;
        }

    }

    let options = {};
    options.color = color;
    options.indication = indication;
    return options;
}

/**
 * Maps a DRIVE state to a tag lable and color
 * @param state the DRIVE state
 * @returns {a}
 */
export const mapDriveState = (state) => {
    let options = mapStates(state);
    return (
        createTag(state, options.color, '', options.indication, false)
    );
};

export const getDisplayValueOfApprovedAmounts = (value) => {
    return value ? value : 'Η πληροφορία δεν διαθέσιμη';
};

export const pad = (str, max) => {
    str = str.toString();
    return str.length < max ? pad("0" + str, max) : str;
};

export const prepareRequestData = (action, caseId, comment, creditInstitution, data, email) => {
    console.log(action)
    let payload = {};
    payload.action = action;
    payload.creditInstitution = creditInstitution;
    payload.category = categoryType;
    payload.businessUnit = assetGroup;
    payload.originBranchCode = originBranchCodeOfExternalFPS; //V209 - EXTERNAL FPS
    payload.comment = comment;

    if(action === withdrawCaseAction) {
        console.log(caseId)
        payload.caseId = caseId;
        payload.subCategory = subCategoryOfCase;
        payload.agentCode = "0706";
        return payload;
    } else {
        console.log(caseId)
        payload.originCode = originCodeOfFPS;
        payload.serviceBranchCode = data.serviceBranchCode;

        let borrower = {};
        borrower.participationType = "BORROWER";
        borrower.customerType = "INDIVIDUAL";
        borrower.legalType = "301"; //ΕΛΛΗΝΕΣ
        borrower.nationality = data.nationality;
        borrower.customerId = data.customerId;
        borrower.surname = data.surname;
        borrower.firstName = data.firstName;
        borrower.maritalStatus = data.maritalStatus;
        borrower.fatherName = data.fatherName;
        borrower.motherName = data.motherName;
        borrower.idType = data.idType;
        borrower.idNumber = data.idNumber;
        borrower.gender = data.gender.toLocaleUpperCase();
        borrower.taxAuthority = data.taxAuthority;
        borrower.birthdate = data.birthdate;
        borrower.taxNumber = data.taxNumber;
        borrower.contactAddressStreet = data.contactAddressStreet;
        borrower.contactAddressStreetNumber = data.contactAddressStreetNumber;
        borrower.contactAddressZipCode = data.contactAddressZipCode;
        borrower.contactAddressCity = data.contactAddressCity;
        borrower.contactPhone = data.contactPhone;
        borrower.contactMobile = data.contactMobile;
        borrower.email = data.email;
        borrower.residenceStatus = data.residenceStatus;
        borrower.yearsOfResidence = data.contactInfoYearsOfResidence;
        borrower.occupation = data.occupation;
        borrower.occupationType = data.occupationType;
        borrower.yearsInProfession = data.yearsInProfession;
        borrower.mailingAddressCode = data.mailingAddressCode;
        borrower.propertyOwner = data.propertyOwner;
        payload.customers = [borrower];
        //SPOUSE DATA MAP//
        if(data.maritalStatus === "MARRIED" && ((data.customerRelations && data.customerRelations[0].customerId) || (data.spouseInfoSurname && data.spouseInfoName &&  data.spouseInfoProfession))){
            let spouse = {};
            spouse.relationshipType = "SPOUSE";
            spouse.customerType = "INDIVIDUAL";
            spouse.legalType = "301"; //ΕΛΛΗΝΕΣ
            spouse.customerId = data.customerRelations ? data.customerRelations[0].customerId : null;
            spouse.surname = data.spouseInfoSurname;
            spouse.firstName = data.spouseInfoName;
            spouse.familyName = data.spouseInfoFamilyName;
            spouse.occupation = data.spouseInfoProfession;
            spouse.taxNumber = data.spouseInfoTaxNumber;
            spouse.idType = data.spouseInfoPersonalDocType;
            spouse.idNumber = data.spouseInfoPersonalDocNumber;
            payload.customers.push(spouse);
        }
        // ATTORNEY DATA MAP
        if(data.attorneySurname && data.attorneyName) {
            let attorney = {};
            attorney.participationType = "ATTORNEY";
            attorney.customerType = "INDIVIDUAL";
            attorney.legalType = "301"; //ΕΛΛΗΝΕΣ
            attorney.surname = data.attorneySurname;
            attorney.firstName = data.attorneyName;
            attorney.contactPhone = data.attorneyPhoneNumber;
            attorney.occupation = data.attorneyProfession;
            attorney.contactAddressStreet = data.attorneyHomeAddressStreet;
            attorney.contactAddressStreetNumber = data.attorneyHomeAddressNumber;
            attorney.contactAddressCity = data.attorneyHomeAddressCity;
            attorney.contactAddressZipCode = data.attorneyHomeAddressPostalCode;
            payload.customers.push(attorney);
        }

        let additionalCaseInfo = {};
        additionalCaseInfo.incomeToolCaseId = data.financialInfoFinancialPlanId; //123123123
        additionalCaseInfo.incomeToolAvailableIncome = data.financialInfoAvailableIncome; //123123.43
        additionalCaseInfo.financialProblemsType = data.financialProblemsType; //FINAL_RESOLUTION
        additionalCaseInfo.sbbAssesment = data.sbbEvaluation; //1
        additionalCaseInfo.hlbAssesment = data.mortgageEvaluation; //2
        additionalCaseInfo.loanReasonCL = data.loanInfoPurposeGeneralCategory; //test
        additionalCaseInfo.loanPeriodicity = data.loanInfoInstallmentPaymentFreq; //23
        additionalCaseInfo.paymentDate = data.loanInfoInstallmentPaymentDate; //1955-08-24T00:00:00.000Z
        additionalCaseInfo.solutionProduct = data.product;//solutionProduct
        additionalCaseInfo.createdBy = email;
        payload.additionalCaseInfo = additionalCaseInfo;

        return payload;
    }
}

