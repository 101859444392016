import { Layout } from 'antd';
import styled from 'styled-components';

const {
    Footer
  } = Layout;
  
  const StyledFooter = styled(Footer)`
  && {
    text-align: center;
    position: relative;
    bottom: 0;
    width: 100%;
    height: 64px; 
    background-color: #4a4a4a;
    color: white; 
  }
  `;

  export default StyledFooter;