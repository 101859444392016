import React, { Component } from 'react';
import AppFooter from '../../components/layout/AppFooter';
import { Col, Row, Icon } from "antd";
import { LogoutRedirectButton, StyledLogoutTitle, StyledLogoutMessage, StyledLogoutPage } from "../../components/styled-components/layout/StyledLogoutPage";
import { fields } from "../../config/fields_el";
import { Link } from "react-router-dom";
import authService from "../../services/AuthService";

class Logout extends Component {

    componentDidMount() {
        authService.logout();
    }

    render() {
        return (
            <div>
                <StyledLogoutPage>
                    <Row type="flex" justify="center">
                        <Col span={24}>
                            <StyledLogoutTitle >{fields.app_name}</StyledLogoutTitle>
                        </Col>
                        <Row type="flex" justify="center">
                            <Icon type="logout" style={{ color: 'white', fontSize: '40px' }} />
                        </Row>
                        <Col span={24}>
                            <StyledLogoutMessage>{fields.app_logout_upper_line}</StyledLogoutMessage>
                            <StyledLogoutMessage>{fields.app_logout_lower_line}</StyledLogoutMessage>
                        </Col>
                    </Row>
                    <Row type="flex" justify="center">
                        <Col xs={24} lg={{ span: 4 }} md={{ span: 6 }} sm={24} style={{ textAlign: 'center' }}>
                            <Link to={'/'}>
                                <LogoutRedirectButton type={'button'}>{fields.redirect_to_login}</LogoutRedirectButton>
                            </Link>
                        </Col>
                    </Row>
                </StyledLogoutPage>
                <AppFooter />
            </div>
        );
    }
}

export default Logout;
