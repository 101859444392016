import React, { Component } from 'react';
import { Form, Col, Collapse, Menu, Tooltip, DatePicker } from 'antd';
import { renderFormComponents, mapStaticDataToSelectItems, applyDateFormat, mapOfficesToSelectItems, mapEnumerationsToSelectItems } from "../../helpers/InputHelper";
import { fields } from '../../config/fields_el';
import { displayDateFormat } from '../../config/Properties';
import { showNotification } from '../../helpers/NotificationHelper';
import StyledButton from "../../components/styled-components/controls/StyledButton";
import StyledPartialForm from "../../components/styled-components/form/StyledPartialForm";
import StyledFormItem from "../../components/styled-components/form/StyledFormItem";
import StyledDateButton from "../../components/styled-components/controls/StyledDateButton";
import StyledSideDrawer from "../../components/styled-components/controls/StyledSideDrawer";
import StyledPanel from "../../components/styled-components/layout/StyledPanel"
import StyledCollapse from "../../components/styled-components/layout/StyledCollapse";
import moment from 'moment';
import MediaQuery from "react-responsive";
import { messages } from "../../config/messages_el";
import { StyledSearchButton } from '../../components/styled-components/controls/StyledSearchButton';
import { getTimeInterval, validateOnlyDigits, validateLettersDotsSlashesDashes, validateLettersNumbersDotsSlashesDashes } from "../../validators/InputValidators";
import appAuth from "../../services/AuthService";

class SearchCases extends Component {

    componentDidMount() {
        this.props.initSearch();
    }

    selectDateRange(date) {
        if (date.key === '0') {
            this.props.toggleDrawer();
        } else {
            let today = applyDateFormat(new Date(), displayDateFormat);
            let someDaysAgo = new Date();

            if (date.key === '30') {
                someDaysAgo = moment(someDaysAgo).subtract(30, 'day');
            } else if (date.key === '15') {
                someDaysAgo = moment(someDaysAgo).subtract(15, 'day');
            } else {
                this.props.toggleDrawer();
            }
            someDaysAgo = applyDateFormat(someDaysAgo, displayDateFormat);
            this.props.setDateRange(someDaysAgo, today);
        }
    }

    renderMenu() {
        return (
            <Menu onClick={e => this.selectDateRange(e)}>
                <Menu.Item key="30">30 {fields.days}</Menu.Item>
                <Menu.Item key="15">15 {fields.days}</Menu.Item>
                <Menu.Item key="0">{fields.select_range}</Menu.Item>
            </Menu>
        );
    };

    setSearchGroup(activeCollapse) {
        this.props.setActiveSearchGroup(activeCollapse);
    }

    toggleDrawerVisibility() {
        if (!this.props.dateFrom || !this.props.dateTo) {
            showNotification(messages.over_day_limit_error_message, messages.invalid_date_range, 'error');
        }
        let interval = getTimeInterval(this.props.dateFrom, this.props.dateTo);
        if (interval <= 30 && interval >= 0) {
            this.props.toggleDrawer();
        } else if (interval < 0) {
            showNotification(messages.future_date_selected_error_message, messages.invalid_date, 'error');
        } else {
            showNotification(messages.over_day_limit_error_message, messages.invalid_date_range, 'error');
        }
    };

    renderDateSelection() {
        return (
            <Col lg={{ span: 4, offset: 0 }} md={{ span: 4, offset: 0 }} sm={{ span: 24, offset: -2 }}>
                <Tooltip placement="right" title={this.renderMenu()}>
                    <StyledDateButton>
                        {fields.date_range}
                    </StyledDateButton>
                </Tooltip>
            </Col>
        )
    }

    handleCaseIdChange = (e) => {
        this.props.useDateRange(e);
        const { form } = this.props;
        if (e.target.value) {
            form.setFieldsValue({'cif': "" });
        }
    };

    handleCifChange = (e) => {
        this.props.useDateRange(e);
        const { form } = this.props;
        if (e.target.value) {
            form.setFieldsValue({'caseId': "" });
        }
    };


    render() {


        const {
            getFieldDecorator,
        } = this.props.form;

        let simpleCaseSearchLeft = {
            layout: this.props.layout, inputs: [
                { fieldName: 'caseId', onChange: e => this.handleCaseIdChange(e), type: "input", label: fields.case_id, rules: [{ required: false, validator: validateOnlyDigits }], value: this.props.data ? this.props.data.caseId : null, iconType: "user" },
            ]
        };

        let simpleCaseSearchRight = {
            layout: this.props.layout, inputs: [
                { fieldName: 'cif', onChange: e => this.handleCifChange(e), type: "input", label: fields.cif, rules: [{ required: false, validator: validateOnlyDigits }], disabled: (this.props.data && this.props.data.caseId), value: this.props.data ? this.props.data.cif : null, iconType: "user" },
            ]
        };

        let nameOptionsLeft = {
            layout: this.props.layout, inputs: [
                { fieldName: 'name', type: "input", label: fields.name, rules: [{ required: false, validator: validateLettersDotsSlashesDashes }], value: this.props.data ? this.props.data.name : null, iconType: "user" },
            ]
        };

        let nameOptionsMiddle = {
            layout: this.props.layout, inputs: [
                { fieldName: 'surname', type: "input", label: fields.surname, rules: [{ required: false, validator: validateLettersDotsSlashesDashes }], value: this.props.data ? this.props.data.surname : null, iconType: "user" },

            ]
        };

        let nameOptionsRight = {
            layout: this.props.layout, inputs: [
                { fieldName: 'fatherName', type: "input", label: fields.father_name, rules: [{ required: false, validator: validateLettersDotsSlashesDashes }], value: this.props.data ? this.props.data.fatherName : null, iconType: "user" },
            ]
        };


        let statusSearch = {
            layout: this.props.layout, inputs: [
                { fieldName: 'requestStatus', type: "select", label: fields.request_state, rules: [{ required: false }], selectOptions: mapEnumerationsToSelectItems(this.props.requestStates, null), showSearch: true },
            ]
        };

        let idOptionsRight = {
            layout: this.props.layout, inputs: [
                { fieldName: 'idNumber', type: "input", label: fields.personal_document_number, rules: [{ required: false, validator: validateLettersNumbersDotsSlashesDashes }], value: this.props.data ? this.props.data.idNumber : null, iconType: "user" },
            ]
        };

        let simpleCaseSearchLiaison = {
            layout: this.props.layout, inputs: [
                { fieldName: 'agencyCode', type: "select", label: fields.office, rules: [{ required: false }], selectOptions: this.props.userInfo.offices ? mapOfficesToSelectItems(this.props.userInfo.offices) : null, showSearch: true },
            ]
        };

        let idOptionsLeft = {
            layout: this.props.layout, inputs: [
                { fieldName: 'idCode', type: "select", label: fields.personal_document_type, rules: [{ required: false }], selectOptions: this.props.idCodes ? mapStaticDataToSelectItems(this.props.idCodes) : null, showSearch: true },
            ]
        };

        let genericColumnStyleLeft = { sm: 24, md: { span: 12, offset: 0 }, lg: { span: 11, offset: 0 } };
        let genericColumnStyleRight = { sm: 24, md: { span: 12, offset: 0 }, lg: { span: 11, offset: 0 } };
        let threeColumnsStyle = { sm: 24, md: { span: 12, offset: 0 }, lg: { span: 8, offset: 0 } };

        let formInputCriteria =
        {
            sections: [
                {
                    orientation: 'left',
                    subsection: true,
                    columns: [
                        {
                            styles: genericColumnStyleLeft,
                            inputs: simpleCaseSearchLeft
                        },
                        {
                            styles: genericColumnStyleRight,
                            inputs: simpleCaseSearchRight
                        }
                    ]
                }
            ]
        };

        let formInputCriteriaLiaison =
        {
            sections: [
                {
                    orientation: 'left',
                    subsection: true,
                    columns: [
                        {
                            styles: genericColumnStyleLeft,
                            inputs: simpleCaseSearchLiaison
                        }
                    ]
                }
            ]
        };


        let searchNameFields =
        {
            sections: [
                {
                    orientation: 'left',
                    subsection: true,
                    columns: [
                        {
                            styles: threeColumnsStyle,
                            inputs: nameOptionsLeft
                        },
                        {
                            styles: threeColumnsStyle,
                            inputs: nameOptionsMiddle
                        },
                        {
                            styles: threeColumnsStyle,
                            inputs: nameOptionsRight
                        }
                    ]
                }
            ]
        };

        let idSearchOptions =
        {
            sections: [
                {
                    orientation: 'left',
                    subsection: true,
                    columns: [
                        {
                            styles: genericColumnStyleLeft,
                            inputs: idOptionsLeft
                        },
                        {
                            styles: genericColumnStyleRight,
                            inputs: idOptionsRight
                        }
                    ]
                }
            ]
        };

        let requestStatusOptions =
            {
                sections: [
                    {
                        orientation: 'left',
                        subsection: true,
                        columns: [
                            {
                                styles: genericColumnStyleLeft,
                                inputs: statusSearch
                            }
                        ]
                    }
                ]
            };

        return (
            <div>
                <StyledPartialForm onSubmit={e => this.props.update(e, this.props.form)} layout='horizontal'>

                    <StyledSideDrawer
                        title={fields.select_range}
                        placement="right"
                        closable={false}
                        onClose={(e) => this.toggleDrawerVisibility()}
                        visible={this.props.drawerVisible}
                        getContainer={false}
                        width={"25%"}
                    >
                        <label>{fields.from}</label>
                        <DatePicker value={this.props.dateFrom ? moment(this.props.dateFrom, displayDateFormat) : null} format={displayDateFormat} onChange={dateFrom => this.props.setDateRange(dateFrom, this.props.dateTo)} style={{ marginTop: '5%' }} /><br/>
                        <label>{fields.to}</label>
                        <DatePicker value={this.props.dateTo ? moment(this.props.dateTo, displayDateFormat) : null} format={displayDateFormat} onChange={dateTo => this.props.setDateRange(this.props.dateFrom, dateTo)} style={{ marginTop: '5%' }} />
                        <StyledFormItem>
                            <br/>
                            <StyledButton type="primary" onClick={(e) => this.toggleDrawerVisibility()}>{fields.selection}</StyledButton>
                        </StyledFormItem>
                    </StyledSideDrawer>

                    <MediaQuery maxDeviceWidth={767}>
                        {this.renderDateSelection()}
                    </MediaQuery>
                    <Col lg={{ span: 24, offset: 2 }} md={{ span: 24, offset: 1 }}>
                        <Col lg={{ span: 20, offset: 0 }} md={{ span: 20, offset: 0 }} sm={24}>
                            {appAuth.hasRole(this.props.userInfo, 'ROLE_USER') ? (
                                    <Collapse accordion={true} bordered={false} style={{ boxShadow: '1px 1px 6px 2px #ccc', marginTop: '3%' }}
                                              defaultActiveKey="basicSearch" onChange={e => this.setSearchGroup(e)}>
                                        <StyledPanel header={fields.basic_criteria} key="basicSearch" >
                                            {renderFormComponents(formInputCriteria.sections, getFieldDecorator)}
                                        </StyledPanel>

                                        <StyledPanel header={fields.name} key="nameSearch">
                                            {renderFormComponents(searchNameFields.sections, getFieldDecorator)}
                                        </StyledPanel>

                                        <StyledPanel header={fields.personal_identification_doc} key="personalIdSearch">
                                            {renderFormComponents(idSearchOptions.sections, getFieldDecorator)}
                                        </StyledPanel>

                                        <StyledPanel header={fields.request_state} key="requestStatusSearch">
                                            {renderFormComponents(requestStatusOptions.sections, getFieldDecorator)}
                                        </StyledPanel>
                                    </Collapse>
                                ) : (
                                <Collapse accordion={true} bordered={false} style={{ boxShadow: '1px 1px 6px 2px #ccc', marginTop: '3%' }}
                                          defaultActiveKey="selectAgencyCode" >
                                    <StyledPanel header={fields.agent} key="selectAgencyCode"  >
                                        {renderFormComponents(formInputCriteriaLiaison.sections, getFieldDecorator)}

                                            <StyledCollapse accordion={true} bordered={false}
                                                      onChange={e => this.setSearchGroup(e)} >

                                                <StyledPanel header={fields.basic_criteria} key="basicSearch" >
                                                    {renderFormComponents(formInputCriteria.sections, getFieldDecorator)}
                                                </StyledPanel>

                                                <StyledPanel header={fields.name} key="nameSearch">
                                                    {renderFormComponents(searchNameFields.sections, getFieldDecorator)}
                                                </StyledPanel>

                                                <StyledPanel header={fields.personal_identification_doc} key="personalIdSearch">
                                                    {renderFormComponents(idSearchOptions.sections, getFieldDecorator)}
                                                </StyledPanel>

                                                <StyledPanel header={fields.request_state} key="requestStatusSearch">
                                                    {renderFormComponents(requestStatusOptions.sections, getFieldDecorator)}
                                                </StyledPanel>

                                            </StyledCollapse>
                                    </StyledPanel>
                                </Collapse>
                            )}
                        </Col>
                        <MediaQuery minDeviceWidth={768}>
                            {this.renderDateSelection()}
                        </MediaQuery>
                    </Col>
                    <Col span={24}>
                        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '30px', marginTop: '30px' }}>
                            <StyledSearchButton shape="circle" icon="search" htmlType="submit" />
                        </div>
                    </Col>

                </StyledPartialForm>
            </div>
        )
    }
}

const SearchCasesForm = Form.create({ name: 'Search Cases' })(SearchCases);
export default SearchCasesForm;