import React, { Component } from 'react';
import { ConfigProvider, Modal } from 'antd';
import { Router, Route, Switch } from 'react-router-dom';
import NotFound from './pages/common/NotFound';
import SecuredRoute from './components/SecuredRoute';
import History from './pages/History';
import Landing from './pages/common/Landing';
import Logout from './pages/common/Logout';
import CaseWizard from './pages/CaseWizard';
import { locale } from './config/Properties';
import IdleTimer from 'react-idle-timer';
import { history } from './helpers/BrowserHistory';
import { messages } from './config/messages_el';
import { fields } from './config/fields_el';
import './App.css';
import Forbidden from './pages/common/Forbidden';
import Error from './pages/common/Error';

class App extends Component {

  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.modal = null;
  }

  onIdle = () => {
    let minutesToGo = process.env.REACT_APP_EXTENSION_TIMEOUT;

    const refreshTimer = () => {
      this.idleTimer.reset();
      clearTimeout(timeout);
      clearInterval(timer);
      modal.destroy();
    }

    const modal = Modal.warning({
      title: messages.inactivity_notification(process.env.REACT_APP_INACTIVITY_TIMEOUT),
      content: messages.extension_timeout_notification(process.env.REACT_APP_EXTENSION_TIMEOUT),
      onOk() { refreshTimer(timer) },
      okText: fields.session_refresh
    })

    const timer = setInterval(() => {
      minutesToGo -= 1;
      modal.update({
        content: messages.extension_timeout_notification(minutesToGo),
      });
    }, 1000 * 60);

    const timeout = setTimeout(() => {
      clearInterval(timer);
      history.push("/logout");
      this.idleTimer.pause();
      modal.destroy();

    }, minutesToGo * 1000 * 60);
  }

  onAction = () => {
    this.idleTimer.reset();
  }

  render() {
    return (
      <Router history={history}>
        <div>
          <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            element={document}
            onIdle={this.onIdle}
            onAction={this.onAction}
            events={['keydown', 'mousedown']}
            debounce={250}
            timeout={process.env.REACT_APP_INACTIVITY_TIMEOUT * 1000 * 60} />
          <ConfigProvider locale={locale()}>
            <Switch>
              <Route exact={true} path={/.*\/error/} component={Error}/>
              <Route exact={true} path="/logout" component={Logout} />
              <SecuredRoute history={history} exact={true} path="/cases/:results?/:details?/:docs?" componentName={'History'} component={History} key={1} />
              <SecuredRoute exact={true} path="/create-case" componentName={'CaseWizard'} component={CaseWizard} key={2} />
              <SecuredRoute exact={true} path="/" componentName={'Landing'} component={Landing} key={3} />
              <Route exact={true} path="/forbidden" component={Forbidden}/>
              <Route component={NotFound} />
            </Switch> 
          </ConfigProvider>
        </div>
      </Router>
    );
  }
}

export default App;