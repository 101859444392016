import React, { Component } from 'react';
import LeftMenu from './LeftMenu';
import RightMenu from './RightMenu';
import { Link } from 'react-router-dom';
import { MenuBar, MenuContent, MenuBarLogo, StyledLeftMenuContainer, StyledRightMenuContainer, BarsMenu, BarsButton, StyledDrawer } from '../styled-components/layout/StyledNavBar';
import logo from '../../images/Eurobank.svg';
import { fields } from '../../config/fields_el';

class Navbar extends Component {

	constructor(props) {
		super(props);
		this.state = {
			current: props.location.pathname === '/' ? '' : props.location.pathname.substr(1),
			visible: false,
			creditInstitution: null
		}
	}

	componentDidMount() {
		if (this.props.creditInstitution){
			this.setState({creditInstitution: this.props.creditInstitution})
		}
	}

	handleClick = (e) => {
		this.setState({
			current: e.key ? e.key : '',
		});
	}

	showDrawer = () => {
		this.setState({
			visible: true,
		});
	};

	onClose = () => {
		this.setState({
			visible: false,
		});
	};

	render() {
		return (
			<MenuBar>
				<div onClick={this.handleClick}>
					<Link to="/">
						<MenuBarLogo src={logo} alt="logo" />
					</Link>
				</div>
				<MenuContent>
					<StyledLeftMenuContainer>
						<LeftMenu userInfo={this.props.userInfo} current={this.state.current} creditInstitution={this.state.creditInstitution} mode={'horizontal'} />
					</StyledLeftMenuContainer>
					<StyledRightMenuContainer>
						<RightMenu userInfo={this.props.userInfo} mode={'horizontal'} />
					</StyledRightMenuContainer>
					<BarsMenu type="primary" onClick={this.showDrawer}>
						<BarsButton />
					</BarsMenu>
					<StyledDrawer
						title={fields.app_name}
						placement="right"
						closable={false}
						onClose={this.onClose}
						visible={this.state.visible}
					>
						<RightMenu userInfo={this.props.userInfo} mode={'vertical'} />
						<LeftMenu userInfo={this.props.userInfo} creditInstitution={this.state.creditInstitution} current={this.state.current} mode={'vertical'} />
					</StyledDrawer>
				</MenuContent>
			</MenuBar>
		);
	}
}

export default Navbar;