import React from 'react';
import { StyledLayout, StyledContent } from '../styled-components/layout/StyledMainLayout';
import AppFooter from './AppFooter';
import Navbar from './NavBar';

const AppPage = (props) => {
  return (
    <StyledLayout>
      <Navbar {...props}/>
        <StyledContent>{props.children}</StyledContent>
      <AppFooter/>
    </StyledLayout>
    )
}

export default AppPage
