import styled from 'styled-components';
import { Button } from 'antd';


export const StyledSearchButton = styled(Button)`
    {
        color: white;
        background: #e52600;
        font-weight: bold;
        width: 50px;
        height: 50px;
        &:hover {
            cursor: pointer;
        };
    }
`