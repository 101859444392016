import React from 'react';
import StyledBox from '../styled-components/layout/StyledBox';
import { Row, Col, Statistic, Icon } from 'antd';

const DisplayBox = (props) => {
  return (
    <StyledBox>
      <h1>{props.header}</h1>
      <Row gutter={16}>
        {props.statisticTitle ? <Col span={24}>
          <Statistic
            title={props.statisticTitle}
            value={props.statisticResults}
            prefix={<Icon type={props.iconType} theme="twoTone" twoToneColor="#E52701" />}
            suffix={props.suffix} />
        </Col> : null}
        <Col span={24}>
          <Col span={24}>
            <h4 style={{ color: 'rgba(0, 0, 0, 0.45)' }}>{props.detailInfo}</h4>
          </Col>
          <Col span={24}>
            {props.children}
          </Col>
        </Col>
      </Row>
    </StyledBox>
  )
};
export default DisplayBox;