import styled from 'styled-components';
import { Form } from 'antd';

const StyledFormItem = styled(Form.Item)`
    && {
        margin-left: 1%;
        width: 98%;
    }
    && .ant-input[disabled] {
        color: rgba(0, 0, 0, 0.5);
        background-color: #f5f5f5;
        cursor: not-allowed;
        opacity: 1;
    }

    && .ant-select-disabled {
        color: rgba(0, 0, 0, 0.5);
    }
`;

export default StyledFormItem;