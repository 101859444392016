import React from 'react';
import { Menu, Icon } from 'antd';
import authService from '../../services/AuthService';
import { fields } from '../../config/fields_el';

const SubMenu = Menu.SubMenu;

const RightMenu = (props) => {
  return (
    <Menu mode={props.mode}>
      <SubMenu title={<span><Icon type="user" />{props.userInfo.name}</span>}>
        <Menu.Item key="setting:1" onClick={authService.logout}>{fields.logout}</Menu.Item>
      </SubMenu>
    </Menu>
  );
}

export default RightMenu;