import React from 'react';
import PropTypes from 'prop-types';
import {Input, Icon, DatePicker, Select, Checkbox, Radio } from 'antd';
import StyledFormItem from './styled-components/form/StyledFormItem';
import { displayDateFormat } from '../config/Properties';
import 'moment/locale/el';

const Option = Select.Option;
const TextArea = Input.TextArea;
const renderSelectOptions = (selectOptions) => {
    if (selectOptions) {
        return selectOptions.map((so, ind) => {
            return (<Option style={{whiteSpace: 'normal'}} key={ind} value={so.value} disabled={so.disabled} title={so.display}>{so.display}</Option>)
        });
    }
};

const renderRadioItems = (radioOptions) => {
    if (radioOptions) {
        return radioOptions.map((ro, ind) => {
            return (<Radio key={ind} value={ro.value} disabled={ro.disabled}>{ro.display}</Radio>)
        });
    }
};

const FormInput = (props) => {
    let input = null;
    let placeHolder = props.label;
    switch (props.inputType) {
        case 'input': input = (
            <Input placeholder={placeHolder} name={props.fieldName} disabled={props.disabled} onChange={props.onChange} onSelect={props.onSelect}
                prefix={<Icon type={props.iconType} className="iconPlaceholder"/>}  addonBefore={props.addonBefore} />);
            break;
        case 'date': input = (
            <DatePicker name={props.fieldName} onChange={props.onChange} onSelect={props.onSelect}
                size={props.datePickerSize ? props.datePickerSize : 'default'}
                format={displayDateFormat} />);
            break;
        case 'select': input = (
            <Select name={props.fieldName}
                    onChange={props.onChange}
                    onSelect={props.onSelect}
                    placeholder={placeHolder}
                    allowClear={true}
                    showSearch={props.showSearch}
                    optionFilterProp="children"
                    disabled={props.disabled}
                    filterOption={(inputOfUser, option) => option.props.children.toLowerCase().indexOf(inputOfUser.toLowerCase()) >= 0}
            >{renderSelectOptions(props.selectOptions)}</Select>);
            break;
        case 'check': input = (
                <Checkbox name={props.fieldName} onChange={props.onChange} onSelect={props.onSelect}>{props.checkboxmessage}</Checkbox>);
                break;
        case 'radio': input = (
            <Radio.Group name={props.fieldName} buttonStyle="solid" onChange={props.onChange} onSelect={props.onSelect}> {renderRadioItems(props.radioOptions)} </Radio.Group>);
            break;
        case 'textArea': input = (
            <TextArea placeholder={placeHolder} disabled={props.disabled} name={props.fieldName} onChange={props.onchange}/>);
            break;
        default: input = null;
    }
    return (
        <StyledFormItem label={props.label} labelCol={props.labelCol ? props.labelCol : (props.formItemLayout ?  props.formItemLayout.labelCol : null)} 
        wrapperCol={props.wrapperCol ? props.wrapperCol : (props.formItemLayout.wrapperCol ? props.formItemLayout.wrapperCol : null)}>
            { props.inputType === 'check' ? props.fieldDecorator(props.fieldName, {
                rules: props.rules,
                initialValue: props.value, 
                valuePropName: 'checked'
            })(input) : 
            props.fieldDecorator(props.fieldName, {
                rules: props.rules,
                initialValue: props.value
            })(input)}
        </StyledFormItem>
    );
};

FormInput.propTypes = {
    inputType: PropTypes.string.isRequired,
    fieldDecorator: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    rules: PropTypes.array,
    value: PropTypes.any,
    iconType: PropTypes.string,
    fieldName: PropTypes.string.isRequired,
    selectOptions: PropTypes.array,
    textArea: PropTypes.string,
    radioOptions: PropTypes.array,
    datePickerSize: PropTypes.string,
    formItemLayout: PropTypes.object,
    labelCol: PropTypes.object,
    wrapperCol: PropTypes.object,
    showSearch: PropTypes.bool,
    checkboxmessage: PropTypes.string,
    disabled: PropTypes.bool,
    addonBefore: PropTypes.string,
    onChange: PropTypes.func,
    onSelect: PropTypes.func
};

export default FormInput;