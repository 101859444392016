export const fields = {
    search: "Αναζήτηση",
    search_by_cif: "Αναζήτηση με CIF",
    search_box_title: "Αναζήτηση Πελάτη με αριθμό CIF",
    personal_info_fill:"Συμπλήρωση Ατομικών Στοιχείων",
    personal_info_customer: "Στοιχεία Πελάτη",
    surname: "Επώνυμο",
    solution_type: "Τύπος Λύσης",
    solution: "Προϊόν/Λύση",
    name: "Όνομα",
    father_name: "Όνομα Πατέρα",
    mother_name: "Όνομα Μητέρας",
    gender: "Φύλο",
    male: "Άνδρας",
    female: "Γυναίκα",
    select_value: "Παρακαλώ επιλέξτε τιμή",
    marital_status: "Οικογενειακή Κατάσταση",
    personal_document_type: "Τύπος Πιστοποιητικού",
    personal_document_number: "Αριθμός Πιστοποιητικού",
    office: "Δικηγορικό Γραφείο",
    personal_identification_doc: "Πιστοποιητικό",
    request_state: "Κατάσταση Αιτήματος",
    tax_office: "ΔΟΥ",
    mailing_address_info: "Στοιχεία Αλληλογραφίας",
    citizenship_nationality:"Υπηκοότητα/Εθνικότητα",
    birth_date: "Ημερομηνία Γέννησης",
    tax_number: "Α.Φ.Μ.",
    birth_place: "Τόπος Γέννησης",
    home_address_street: "Οδός Κατοικίας",
    home_address_number: "Αριθμός Κατοικίας",
    city: "Πόλη",
    postal_code: "Τ.Κ.",
    phone_or_mobile_number: "Τηλέφωνο",
    phone_number: "Σταθερό Τηλέφωνο",
    mobile_number: "Κινητό Τηλέφωνο",
    residence_status: "Κατάσταση Διαμονής",
    years_of_residence: "Χρόνια Διαμονής",
    full_mailing_address: "Πλήρης Διεύθυνση Αλληλογραφίας",
    aa_home_address: "Α/Α Διεύθυνση Κατοικίας",
    aa_mailing_address: "Α/Α Διεύθυνση Αλληλογραφίας",
    postal_code_mailing_address: "Τ.Κ. Αλληλογραφίας",
    county_mailing_address: "Νομός Αλληλογραφίας",
    county_mailing_address_description: "Περιγραφή Νομού Αλληλογραφίας (ALTAMIRA)",
    mailing_address_number: "Αριθμός Διεύθυνσης Αλληλογραφίας",
    mailing_address_city: "Πόλη Αλληλογραφίας",
    aa_work_address: "Α/Α Διεύθυνσης Εργασίας",
    save: "Αποθήκευση",
    submit: "Υποβολή",
    contact_info: "Στοιχεία Επικοινωνίας",
    business_info: "Επαγγελματικά Στοιχεία",
    profession: "Επάγγελμα",
    years: "Χρόνια",
    business_name: "Όνομα Επιχείρησης",
    business_tax_number: "Α.Φ.Μ. Επιχείρησης",
    position: "Θέση",
    address: "Διεύθυνση",
    number: "Αριθμός",
    fax: "FAX",
    freelancer: "Ελεύθερος Επαγγελματίας",
    type: "Τύπος",
    document_relation: "Συσχέτιση",
    document_state: "Κατάσταση Εγγράφου",
    actual_folder_delivery: "Παραλαβή Φυσικού Φακέλου",
    comments: "Σχόλια",
    salaried_employee: "Μισθωτός",
    financial_data: "Οικονομικά Στοιχεία",
    financial_plan: "Οικ/κός Προγραμματισμός",
    propertyOwner: "Ύπαρξη Ακίνητης Περιουσίας",
    proceed_action: "Εκτέλεση",
    cancel: "Ακύρωση",
    financial_plan_results: "Αποτέλεσμα Οικ/κου Προγραμματισμού",
    results_import: "Import Αποτελεσμάτων",
    result: "Αποτέλεσμα",
    annual_individual_income: "Ετήσιο Προσωπικό Εισόδημα",
    annual_family_income: "Ετήσιο Οικογενειακό Εισόδημα",
    declared_monthly_individual_income: "Δηλωθέν Μηνιαίο Ατομικό Εισόδημα",
    declared_monthly_family_income: "Δηλωθέν Μηνιαίο Οικογενειακό Εισόδημα",
    tax_statement_number: "Αριθμός Φορολογικής Δήλωσης",
    taxation_year: "Έτος Φορολογικής Δήλωσης",
    dept_other_bank_monthly: "Οφειλές σε άλλη Τράπεζα (Μηνιαίο)",
    rent_monthly: "Ενοίκιο (Μηνιαίο)",
    additional_monthly_expenses: "Πρόσθετα Μηνιαία Έξοδα",
    additional_expenses_reason: "Αιτιολογία Πρόσθετων Εξόδων",
    inability_to_pay_reason: "Λόγος Αδυναμίας Πληρωμής",
    inability_to_pay_reasoning: "Αιτιολογία Αδυναμίας Πληρωμής",
    account_number: "Αριθμός Λογαριασμού",
    financial_difficulties: "Οικονομικές Δυσκολίες",
    prev_prime_period_loan_installment_amount: "Δόση Προνομιακής Περιόδου Προηγούμενης Αίτησης",
    financial_plan_id: "ID Οικ. Προγραμματισμού",
    available_income: "Διαθέσιμο Εισόδημα",
    financial_problems_type: "Κατηγοριοποίηση Προβλήματος",
    financial_problems_not_available: "Μη διαθέσιμη πληροφορία",
    financial_problems_final_resolution: "Οριστική Διευθέτηση",
    financial_problems_short: "Βραχυπρόθεσμο",
    financial_problems_long: "Μακροπρόθεσμο",
    no_available_info: "Μη διαθέσιμη πληροφορία",
    income_tool: "Income Tool",
    loan_info: "Στοιχεία Δανείου",
    loan_purpose_general_category: "Σκοπός Δανείου Γενική Κατηγορία",
    loan_installment_payment_frequency: "Συχνότητα Καταβολής Δόσης",
    loan_installment_payment_date: "Ημερομηνία Καταβολής Δόσης",
    spouse_info: "Στοιχεία Συζύγου",
    spouse_surname: "Επώνυμο Συζύγου",
    spouse_name: "Όνομα Συζύγου",
    spouse_family_name: "Γένος Συζύγου",
    spouse_personal_document_type: "Τύπος Πιστοποιητικού Συζύγου",
    spouse_personal_document_number: "Αριθμός Πιστοποιητικού Συζύγου",
    spouse_profession: "Επάγγελμα Συζύγου",
    spouse_tax_number: "Α.Φ.Μ. Συζύγου",
    spouse_work_address: "Διεύθυνση Εργασίας Συζύγου",
    spouse_work_phone: "Τηλέφωνο Εργασίας Συζύγου",
    attorney_info: "Στοιχεία Αντίκλητου",
    branch_of_disbursement: "Κατάστημα Εκταμίευσης/Εξυπηρέτησης",
    branch_info: "Κατάστημα",
    sbb_evaluation: "Αξιολόγηση από SBB",
    mortgage_evaluation: "Αξιολόγηση από Στεγαστική",
    next_button_lbl: "Επόμενο",
    new_search_lbl: "Νεα Αναζήτηση",
    prev_button_lbl: "Προηγούμενο",
    personal_data: "Ατομικά Στοιχεία",
    customer_data: "Στοιχεία Πελάτη",
    financial_info: "Οικονομικά Στοιχεία",
    financial_plan_info: "Οικονομικός Προγραμματισμός",
    loan: "Δάνειο",
    other_info:"Λοιπά Στοιχεία",
    history: "Ιστορικό Υποθέσεων",
    wizard: "Δημιουργία Υπόθεσης",
    landing: "Επιστροφή στην αρχική",
    logout: "Έξοδος",
    app_name: "go2drive",
    app_descr_upper_line: "Δημιούργησε τώρα ένα νέο Αίτημα",
    app_descr_lower_line: "ή δες το Ιστορικό των προηγούμενων Αιτημάτων που έχουν ήδη δημιουργηθεί",
    app_descr_liaison: "Δες το Ιστορικό των προηγούμενων Αιτημάτων που έχουν ήδη δημιουργηθεί",
    app_forbidden: "Δεν έχετε πρόσβαση στην εφαρμογή, παρακαλώ επικοινωνήστε με το διαχειριστή",
    app_not_found: "Η συγκεκριμένη σελίδα δε βρέθηκε",
    app_descr_credit_insitution: "Παρακαλώ επιλέξτε πιστωτικό ίδρυμα:",
    app_logout_upper_line: "Ο χρόνος παραμονής στην εφαρμογή έληξε!",
    app_logout_lower_line: "Παρακαλώ μεταβείτε στην αρχική σελίδα για login",
    redirect_to_login: "Είσοδος στην Εφαρμογή",
    session_refresh: "Παραμονή",
    search_criteria: "Κριτήρια Αναζήτησης",
    search_results: "Αποτελέσματα",
    search_details: "Λεπτομέρειες Αίτησης",
    case_id: "Αριθμός Αιτήματος",
    case_id_short: "Αρ. Αιτήματος",
    cif: "CIF Πελάτη",
    from_range: "Από",
    to_range: "Έως",
    status: "Status",
    criteria: "Κριτήρια",
    interval: "Διάστημα",
    actions: "Ενέργειες",
    complex_criteria: "Σύνθετα Κριτήρια",
    more_options: "Περισσότερες Επιλογές",
    input_date: "Ημερομηνία Καταχώρησης",
    product: "Προϊόν",
    initialPaymentAmount: "Αιτηθέν ποσό καταβολής",
    approvedPaymentAmount: "Εγκριθέν ποσό καταβολής",
    restructuringAmount: "Ποσό ρύθμισης",
    normalInstallmentAmount: "Κανονική Δόση",
    normalInterest: "Κανονικό Επιτόκιο",
    normalLoanDuration: "Συνολική Διάρκεια",
    shadowLoanDuration: "Διάρκεια",
    withInterestPayment: "Με τοκοπληρωμή",
    valueTrue: "ΝΑΙ",
    valueFalse: "ΟΧΙ",
    interestPayment: "Τοκοπληρωμή",
    loanAmount: "Ποσό Δανείου προς εξόφληση",
    shadowLoanAmount: "Ποσό Δανείου Δυνητικού Χαρίσματος",
    masterAccount: "Master",
    deptAnalysis: "Σταθερά",
    shadowAccount: "Δυνητικό Χάρισμα",
    frozenInterest: "Παγωμένοι τόκοι προς διαγραφή",
    privilegedInstallmentAmount: "Προνομιακή δόση",
    monthsOfPriviledgedDuration: "Προνομιακή Διάρκεια",
    priviledgedInterest: "Προνομιακό Επιτόκιο",
    progr: "Πρόγραμμα",
    subprogr: "Υποπρόγραμμα",
    service_branch: "Κατάστημα Εξυπηρέτησης",
    contract_number: "Αριθμός Σύμβασης Δανείου",
    opening_account: "Άνοιγμα Λογ. Εξυπηρέτησης/Σύνδεση νέου CIF",
    rejection_letter: "Επιστολή Απόρριψης",
    rejection_reason: "Λόγος Απόρριψης",
    income: "Εισόδημα",
    document_demands: "Εκκρεμότητες Δικαιολογητικών",
    monitor_doc_demands: "Παρακολούθηση Εκκρεμοτήτων",
    information: "Πληροφορίες",
    documents: "Δικαιολογητικά Αιτήματος",
    service_account_number: "Λογ. Εξυπηρέτησης",
    credit_institution: "Πιστωτικό Ίδρυμα",
    agent: "Δικηγορικό Γραφείο",
    case_details: "Στοιχεία Αιτήματος",
    return_lbl: "Επιστροφή",
    id_number: "Αριθμός Ταυτότητας",
    name_and_surname: "Όνομα/Επίθετο",
    results_of_search: "Αποτελέσματα Αναζήτησης",
    found_plural: "Βρέθηκαν",
    found_singular: "Βρέθηκε",
    case_singular: "Αίτημα",
    case_plural: "Αιτήματα",
    days: "Μέρες",
    select_range: "Επιλογή Διαστήματος",
    search_range: "Διάστημα Αναζήτησης",
    case_search: "Αναζήτηση Αιτημάτων",
    date_range: "Χρονική Περίοδος",
    selection: "Επιλογή",
    basic_criteria: "Βασικά Κριτήρια",
    in_progress: "Σε Εξέλιξη",
    rejection: "Απόρριψη",
    completed: "Ολοκληρωμένη",
    approved: "Εγκεκριμένη",
    withdrawn: "Ακυρωμένη",
    preparation_for_submission: "Προετοιμασία - Αναμονή Υποβολής",
    monitor: "Παρακολούθηση",
    final_postponement: "Οριστική Αναβολή",
    digital_document: "Ψηφιοποιημένο Έγγραφο",
    pending: "Εκκρεμότητα",
    caseDetails: "Λεπτομέρειες Αιτήματος",
    cancelCase: "Ακύρωση Αιτήματος",
    from:"Από: ",
    to:"Εώς: "
};
