import React, { Component } from 'react';
import { Form, Col } from 'antd';
import { fields } from '../../config/fields_el';
import { messages } from '../../config/messages_el';
import StyledPartialForm from '../../components/styled-components/form/StyledPartialForm'
import StyledButton from '../../components/styled-components/controls/StyledButton';
import {  isValidBirthDate, isValidPhoneOrMobileNumber, validateHomeAddressNumber, validateRangeOnlyDigits, validateLettersDotsSlashesDashes, validateLettersNumbersDotsSlashesDashes, isValidZipCode, isValidMobileNumber, isValidEmail, validateTIN, validateOnlyLetters, isValidHomePhoneNumber,isValidFaxNumber} from '../../validators/InputValidators';
import moment from 'moment';
import { mapStaticDataToSelectItems, renderFormComponents, mapEnumerationsToSelectItems, filterOutSingleType } from '../../helpers/InputHelper';
import StyledFormItem from '../../components/styled-components/form/StyledFormItem';
import {addonValue, idTypeExcluded} from "../../config/Properties";



const requiredFieldsBasedOnOccupation = [
        'occupation',
        'yearsInProfession',
        'companyAddressStreet','companyAddressStreetNumber',
        'companyCity',
        'companyPhone',
        'companyPostalCode'
    ];


class PersonalInfoStep extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mobileRequired: false,
            hasOccupation: false
        }
    };

    handleChangeRequired = (e) => {
        this.setState({hasOccupation:  e === 'AGRICULTURE' || e === 'LEGAL' || e === 'TRADER' || e === 'PUBLIC' || e === 'FREELANCER' || e === 'PRIVATE'},
            () => {
        this.props.form.validateFieldsAndScroll(requiredFieldsBasedOnOccupation, {force: true});
             }
        )
    };

    handleConsensusSelected = (e) => {
        this.setState({mobileRequired: e.target.checked},
            () => {
                this.props.form.validateFieldsAndScroll(['contactMobile'], { force: true });
            }
        )
    };

    validateConsensus = (rule, value, callback) => {
        const { form } = this.props;
        if (true !== value && form.getFieldValue('contactMobile')) {
            callback();
        } else {
            let options = {rules: [{required: true}]};
            form.getFieldDecorator('contactMobile', options);
            callback();
        }
    };

    mapAddressesToSelectItems = (addresses) => {
        let addressesSelectItems = [{ value: null, display: fields.select_value, disabled: true }];
        addresses.forEach(addr => {
            addr.key = addr.aaNo;
            let addressSelect = { value: addr.aaNo, display: addr.aaNo + " - " + addr.street + " " + addr.streetNo
                    + ", " + addr.postalCode + " " + addr.city + ", " + addr.area + " " + addr.regionDescription};
            addressesSelectItems.push(addressSelect);
        });
        return addressesSelectItems;
    };

    render() {
        const {
            getFieldDecorator,
        } = this.props.form;

        let personalInfo1 = {layout: this.props.layout, inputs: [
            { fieldName: 'surname', type: "input", label: fields.surname, rules: [{ required: true, validator: validateLettersDotsSlashesDashes }], value: this.props.data ? this.props.data.surname : null, iconType: "user" },
            { fieldName: 'firstName', type: "input", label: fields.name, rules: [{ required: true, validator: validateLettersDotsSlashesDashes }], value: this.props.data ? this.props.data.firstName : null, iconType: "user" },
            { fieldName: 'fatherName', type: "input", label: fields.father_name, rules: [{ required: true, validator: validateLettersDotsSlashesDashes }], value: this.props.data ? this.props.data.fatherName : null, iconType: "user" },
            { fieldName: 'motherName', type: "input", label: fields.mother_name, rules: [{ validator: validateLettersDotsSlashesDashes }], value: this.props.data ? this.props.data.motherName : null, iconType: "user" },
            { fieldName: 'birthdate', type: "date", label: fields.birth_date, rules: [{ required: true, type: "object", validator: isValidBirthDate }], value: this.props.data && this.props.data.birthdate ? moment(this.props.data.birthdate).utc() : null, iconType: "user" },
            { fieldName: 'gender',  type: "select", label: fields.gender, rules: [{ required: true, message: messages.required }], value: this.props.data ? this.props.data.gender : null, selectOptions: this.props.data ? mapEnumerationsToSelectItems(this.props.enumerations['GENDER_TYPE'], null) : null}
        ]};

        let personalInfo2 = {layout: this.props.layout, inputs: [
            { fieldName: 'nationality', type: "select", label: fields.citizenship_nationality, rules: [{ required: true, validator: validateOnlyLetters }], value: this.props.data ? this.props.data.nationality : null, selectOptions: this.props.data ? mapStaticDataToSelectItems(this.props.staticData['NATIONALITY'], true) : null, iconType: "bars", showSearch: true  },
            { fieldName: 'maritalStatus', type: "select", label: fields.marital_status, rules: [{ required: true, message: messages.required }], value: this.props.data ? this.props.data.maritalStatus : null, selectOptions: this.props.data ? mapEnumerationsToSelectItems(this.props.enumerations['CUSTOMER_MARITAL_STATUS_TYPE'], null) : null, showSearch: true },
            { fieldName: 'idType', type: "select", label: fields.personal_document_type, rules: [{ required: true, message: messages.required }], value: this.props.data ? this.props.data.idType : null, selectOptions: this.props.data ? filterOutSingleType(mapStaticDataToSelectItems(this.props.staticData['IDENTIFICATION_TYPE']), idTypeExcluded) : null, showSearch: true},
            { fieldName: 'idNumber', type: "input", label: fields.personal_document_number, rules: [{ required: true, message: messages.required, idType: this.props.form.getFieldValue('idType') }], value: this.props.data ? this.props.data.idNumber : null, iconType: "bars" },
            { fieldName: 'taxNumber', type: "input", label: fields.tax_number, rules: [{ validator: validateTIN }], value: this.props.data ? this.props.data.taxNumber : null, iconType: "bars" },
            { fieldName: 'taxAuthority', type: "select", label: fields.tax_office, value: this.props.data ? this.props.data.taxAuthority : null, selectOptions: this.props.data ? mapStaticDataToSelectItems(this.props.staticData['TAX_OFFICE_CODE'], true) : null, showSearch: true },
        ]};

        let bottomLeftInputs1 = {layout: this.props.layout, inputs: [
            { fieldName: 'contactAddressStreet', type: "input", label: fields.home_address_street, rules: [{ required: true, validator: validateLettersNumbersDotsSlashesDashes }], value: this.props.data ? this.props.data.contactAddressStreet : null, iconType: "home" },
            { fieldName: 'contactAddressstreetNumber', type: "input", label: fields.home_address_number, rules: [{ required: true, validator: validateHomeAddressNumber }], value: this.props.data ? this.props.data.contactAddressstreetNumber : null, iconType: "home" },
            { fieldName: 'contactAddressCity', type: "input", label: fields.city, rules: [{ required: true, validator: validateLettersDotsSlashesDashes }], value: this.props.data ? this.props.data.contactAddressCity : null, iconType: "home" },
            { fieldName: 'contactAddressZipCode', type: "input", label: fields.postal_code, rules: [{ required: true, validator: isValidZipCode }], value: this.props.data ? this.props.data.contactAddressZipCode : null, iconType: "home" },
            { fieldName: 'contactPhone', type: "input", label: fields.phone_number, rules: [{ required: true, validator: isValidHomePhoneNumber }], value: this.props.data ? this.props.data.contactPhone : null, iconType: "phone", addonBefore: addonValue },
        ]};

        let bottomLeftInputs2 = {layout: this.props.layout, inputs: [
            { fieldName: 'contactMobile', type: "input", label: fields.mobile_number, rules: [{ required: this.state.mobileRequired, validator: isValidMobileNumber }], value: this.props.data ? this.props.data.contactMobile : null, iconType: "mobile", addonBefore: addonValue },
            { fieldName: 'email', type: "input", label: "email", rules: [{ validator: isValidEmail }], value: this.props.data ? this.props.data.email : null, iconType: "mail" },
            { fieldName: 'residenceStatus', type: "select", label: fields.residence_status, rules: [{ required: true, message: messages.required }], value: this.props.data ? this.props.data.residenceStatus : null, selectOptions: this.props.data ? mapStaticDataToSelectItems(this.props.staticData['STAY_STATUS_CODE'], false) : null, showSearch: true },
            { fieldName: 'contactInfoYearsOfResidence', type: "input", label: fields.years_of_residence, rules: [{ required: true, validator: validateRangeOnlyDigits }], value: this.props.data ? this.props.data.contactInfoYearsOfResidence : null, iconType: "clock-circle" },
        ]};

        let businessInputs1 = {layout: this.props.layout, inputs: [
            { fieldName: 'occupation', type: "select", label: fields.profession, rules: [{ required: this.state.hasOccupation, message: messages.required}], value: this.props.data ? this.props.data.occupation : null, selectOptions: this.props.data ? mapStaticDataToSelectItems(this.props.staticData['JOB_TYPE']) : null, showSearch: true },
            { fieldName: 'occupationType', type: "select", label: fields.type, rules: [{ required: true, message: messages.required}], value: this.props.data ? this.props.data.occupationType : null, selectOptions: this.props.data ? mapEnumerationsToSelectItems(this.props.enumerations['CURRENT_OCCUPATION_TYPE'], null) : null, showSearch: true, onSelect: (e) => this.handleChangeRequired(e)  },
            { fieldName: 'yearsInProfession', type: "input", label: fields.years, rules: [{ required: this.state.hasOccupation, validator: validateRangeOnlyDigits}], value: this.props.data ? this.props.data.yearsInProfession : null, iconType: "home"},
            { fieldName: 'companyName', type: "input", label: fields.business_name, rules: [{ required: false, validator: validateLettersNumbersDotsSlashesDashes }], value: this.props.data ? this.props.data.companyName : null, iconType: "home" },
            { fieldName: 'companyTaxNumber', type: "input", label: fields.business_tax_number, rules: [{ required: false, validator: validateTIN }], value: this.props.data ? this.props.data.companyTaxNumber : null, iconType: "bars" },
            { fieldName: 'position', type: "input", label: fields.position, rules: [{ required: false, validator: validateLettersNumbersDotsSlashesDashes }], value: this.props.data ? this.props.data.position : null, iconType: "bars" }
        ]};

        let businessInputs2 = {layout: this.props.layout, inputs: [
            { fieldName: 'companyAddressStreet', type: "input", label: fields.address, rules: [{ required: this.state.hasOccupation, validator: validateLettersNumbersDotsSlashesDashes}], value: this.props.data ? this.props.data.companyAddressStreet : null, iconType: "home" },
            { fieldName: 'companyAddressStreetNumber', type: "input", label: fields.number, rules: [{ required: this.state.hasOccupation, validator: validateHomeAddressNumber }], value: this.props.data ? this.props.data.companyAddressStreetNumber : null, iconType: "home" },
            { fieldName: 'companyCity', type: "input", label: fields.city, rules: [{ required: this.state.hasOccupation, validator: validateLettersDotsSlashesDashes,  }], value: this.props.data ? this.props.data.companyCity : null, iconType: "home" },
            { fieldName: 'companyPhone', type: "input", label: fields.phone_or_mobile_number, rules: [{ required: this.state.hasOccupation, validator: isValidPhoneOrMobileNumber, contactPhone: this.props.form.getFieldValue('contactPhone') }], value: this.props.data ? this.props.data.companyPhone : null, iconType: "phone", addonBefore: addonValue},
            { fieldName: 'companyPostalCode', type: "input", label: fields.postal_code, rules: [{ required: this.state.hasOccupation, validator: isValidZipCode}], value: this.props.data ? this.props.data.companyPostalCode : null, iconType: "home" },
            { fieldName: 'companyFax', type: "input", label: fields.fax, rules: [{ validator: isValidFaxNumber }], value: this.props.data ? this.props.data.companyFax : null, iconType: "phone" }
        ]};

        let mailingAddressColumn = {layout: this.props.layout, inputs: [
                { fieldName: 'mailingAddressCode', type: "select", label: fields.address, rules: [{ required: true, validator: validateLettersNumbersDotsSlashesDashes }], value: this.props.data ? this.props.data.mailingAddressCode : null, selectOptions: this.props.data ? this.mapAddressesToSelectItems(this.props.data['customerAddresses']) : null, showSearch: true }
            ]};

        let consentWrapperCol = {
                lg: {
                    span: 24, offset: 2
                },
                md: {
                    span: 22, offset: 1
                },
                sm: {
                    span: 24, offset: 0
                },
                xs: {
                    span: 24, offset: 0
                }
            };

        let consent = { inputs: [
            { checkBoxMessage: messages.consent_phone_number_contact, label: '', fieldName: 'contactInfoConsent', rules: [{validator: this.validateConsensus}], type: 'check', value: false, wrapperCol: consentWrapperCol, labelCol: {span: 19}, onChange: this.handleConsensusSelected}
        ]};

        let genericColumnStyle = {sm: 24, md: 12, lg:12};
        let consentColumnStyle = {lg: 24, md: 24, sm: 24, xs: 24};

        let formInputs =
        {
            sections: [
                {
                    title: fields.personal_data,
                    orientation: 'left',
                    type: 'user',
                    columns: [
                        {
                            styles: genericColumnStyle,
                            inputs: personalInfo1
                        },
                        {
                            styles: genericColumnStyle,
                            inputs: personalInfo2
                        }
                    ]
                },
                {
                    title: fields.contact_info,
                    orientation: 'left',
                    type: 'contact',
                    columns: [
                        {
                            styles: genericColumnStyle,
                            inputs: bottomLeftInputs1
                        },
                        {
                            styles: genericColumnStyle,
                            inputs: bottomLeftInputs2
                        }
                    ]
                },
                {
                    title: fields.mailing_address_info,
                    orientation: 'left',
                    type: 'contact',
                    subsection: true,
                    columns: [
                        {
                            styles: genericColumnStyle,
                            inputs: mailingAddressColumn
                        }
                    ]
                },
                {
                    title: fields.business_info,
                    orientation: 'left',
                    type: 'list',
                    columns: [
                        {
                            styles: genericColumnStyle,
                            inputs: businessInputs1
                        },
                        {
                            styles: genericColumnStyle,
                            inputs: businessInputs2
                        }
                    ]
                },
                {
                    orientation: 'left',
                    type: 'list',
                    columns: [
                        {
                            styles: consentColumnStyle,
                            inputs: consent
                        }
                    ]
                }
            ]
        };

        return (
            <StyledPartialForm onSubmit={e => this.props.update(e, this.props.form, true)} layout='horizontal'>
                {renderFormComponents(formInputs.sections, getFieldDecorator)}
                <Col lg={{span: 3, offset:8}} md={{span: 5, offset:7}} sm={24} >
                        <StyledFormItem>
                        <StyledButton id="new-search-button" type="default" onClick={e => {
                            this.props.back(e)
                        }}>{fields.new_search_lbl}</StyledButton>
                    </StyledFormItem>
                </Col>

                <Col lg={12} md={{span: 5, offset:1}} sm={24} >
                    <StyledFormItem>
                        <StyledButton type="primary" htmlType="submit">{fields.next_button_lbl}</StyledButton>
                    </StyledFormItem>
                </Col>
            </StyledPartialForm>
        )
    }
}

const PersonalInfoStepForm = Form.create({ name: 'Personal Info' })(PersonalInfoStep);

export default PersonalInfoStepForm