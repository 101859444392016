import React from 'react';
import {Divider, Icon} from 'antd';
import styled from 'styled-components';

const CustomDivider = ({className, orientation, title, type, subsection}) => {
    return (
        <Divider orientation={orientation} className={className}>
            <StyledDividerIcon subsection={subsection} type={iconPrefix({type})}/>
            {title}
        </Divider>
    )
};

const DividerIcon = ({type, className}) => {
    return (        
        <Icon type={iconPrefix({type})} className={className}/>
    )
};

const StyledDivider = styled(CustomDivider)`
    & > .ant-divider-inner-text {
        font-size: ${props => props.subsection ? '0.9rem' : '1.2rem'};
        font-weight: ${props => props.subsection ? 600 : 700};
        color:#4A4A4A;
        margin-bottom: 10px;
    }
`;

const StyledDividerIcon = styled(DividerIcon)`
        margin-top:10px;
        padding: 10px;
        font-size: ${props => props.subsection ? '1.2rem' : '1.2rem'};
        background-color: ${props => props.subsection ? '' : '#e52600'}; 
        border-radius: 50%;
        color: ${props => props.subsection ? '#1e4485' : 'white'};
        font-weight: lighter;
        margin-right:10px;
`;

const iconPrefix = ({type}) => {
    switch(type) {
        case 'user':
            return "user";
        case 'contact':
            return "mail";
        case 'list':
            return "profile";
        case 'financial':
            return "project";
        case 'creditcard':
            return "credit-card";
        case 'board':
            return "contacts";
        case 'userinfo':
            return "solution";
        case 'bank':
            return "bank";
        case 'swapRight':
            return "swap-right";
        case 'caretRight':
            return "caret-right";
        case 'sliders':
            return "sliders";
        case 'calendar':
            return "calendar";
        case 'file-search':
            return "file-search";
        case 'build':
            return "build";
        default:
            return "form";
    }
};

export default StyledDivider;







