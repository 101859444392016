import Cookies from 'js-cookie';
import { getHttpClient } from '../config/HttpClientConfig'
import apiConfig from '../config/ApiConfig';
import { appAuth } from '../config/rolesToComponentsMap';

/**
 * Initial authentication setup
 */
const setupAuth = () => {
  let token = Cookies.get("AUTH");
  if (token !== undefined) {
    sessionStorage.setItem("bearer", token);
    Cookies.remove('AUTH');
  }
}

/**
 * Check if user is logged in (if local storage contains a bearer token)
 * @returns {boolean}
 */
const isLoggedIn = () => {
  if (process.env.REACT_APP_STAGE === 'mock') {
    return true;
  }
  let token = sessionStorage.getItem("bearer");
  return (token !== null);
}

/**
 * Check if access token is expired based on jwt expiration date
 * @returns {boolean}
 */
const isExpired = () => {
  let token = sessionStorage.getItem("bearer");
  if (token) {
    var parsedToken = parseJwt(token);
    var now = new Date().getTime();
    if (now < (parsedToken.exp * 1000)) {
      return false;
    }
    return true;
  }
  return null;
}

/**
 * Delete all auth related persisted info
 */
const logout = () => {
  Cookies.remove('AUTH');
  sessionStorage.clear();
  if (process.env.REACT_APP_STAGE !== 'mock') {
    window.location = apiConfig.getLogoutEndpointUrl();
  }
}

/**
 * Get user info of logged in user from his jwt
 * @returns {*}
 */
const getUserInfo = () => {
  if (process.env.REACT_APP_STAGE === 'mock') {
    return { sub: "testuser@email.com", name: "Test User", exp: 1556012719, iat: 1556012419, roles:['ROLE_LIAISON'] };
  }
  return parseJwt(sessionStorage.getItem("bearer"));
}

/**
 * Parse given jwt
 * @param {string} token
 * @returns {*} the object described in jwt
 */
const parseJwt = (token) => {
  if (token !== 'undefined' && token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    return JSON.parse(b64DecodeUnicode(base64));
  }
  return null;
}

/**
 *Decodes properly a UTF-8 base64 string
 * https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings
 * @param str the string to decode
 */

const b64DecodeUnicode = (str) => {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(atob(str).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
}

/**
 * Decides wether a user can access a component
 * @param {*} userInfo 
 * @param {string} component 
 * @returns {boolean}
 */
const canAccess = (userInfo, component) => {
  for (let i = 0; i < userInfo.roles.length; i++) {
    if (appAuth[userInfo.roles[i]] && appAuth[userInfo.roles[i]].includes(component)) {
      return true;
    }
  }
  return false;
}

/**
 * Returns true if user has the specified role
 * @param {*} userInfo 
 * @param {string} role 
 */
const hasRole = (userInfo, role) => {
  return userInfo.roles.filter(r => r === role).length === 1;
}

/**
 * Refreshes an access token. If cancelSource is provided, the request can be cancelled.
 * @param {*} cancelSource 
 * @returns {*} the xhr promise
 */
const refreshToken = (cancelSource) => {
  return cancelSource ? getHttpClient().get(apiConfig.getRefreshTokenUrl(), { cancelToken: cancelSource.token }) :
    getHttpClient().get(apiConfig.getRefreshTokenUrl());
}

let authService = {
  setupAuth,
  isLoggedIn,
  isExpired,
  logout,
  getUserInfo,
  canAccess,
  refreshToken,
  hasRole
}

export default authService;
