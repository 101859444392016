import React, {Component} from 'react';
import {Form, Col} from 'antd';
import {fields} from '../../config/fields_el';
import {messages} from '../../config/messages_el';
import StyledPartialForm from '../../components/styled-components/form/StyledPartialForm';
import dataProvider from '../../services/DataProvider';
import apiConfig from '../../config/ApiConfig';
import {showNotification} from '../../helpers/NotificationHelper';
import {
    mapEnumerationsToSelectItems, mapInputItemsToRadioOptions,
    mapStaticDataToSelectItems,
    renderFormComponents
} from "../../helpers/InputHelper";
import StyledFormItem from "../../components/styled-components/form/StyledFormItem";
import StyledButton from "../../components/styled-components/controls/StyledButton";
import { defaultLoanReason } from '../../config/Properties';


class BusinessInfoStep extends Component {

    redirectToIncomeTool = () => {
        dataProvider.getIncomeToolToken(this.props.data.customerId, this.props.creditInstitution, this.props.pools).then(result => {
            var token = result.data.tokenResponse.token;
            this.openIncomeTool(apiConfig.getIncomeToolRedirectUrl(), "IncomeTool", token, []);
        })
        .catch(error => {
            showNotification(messages.incometool_error_message, messages.incometool_title, 'error');
        });
    };

    getIncomeToolResult = (e) => {
        this.props.toggleSpinner();
        dataProvider.getIncomeToolResult(this.props.data.customerId, this.props.creditInstitution)
            .then(result => {
                console.log("income tool")
                this.props.toggleSpinner();
                console.log(result.data)
                if (result.data.incomeDriveHHLResponse.status !== 'COMPLETE'){
                    showNotification(messages.incometool_not_found, messages.incometool_title, 'warning');
                } else {
                    this.props.form.setFieldsValue({financialInfoFinancialPlanId: result.data.incomeDriveHHLResponse.incomeToolCaseId.value});
                    this.props.form.setFieldsValue({financialInfoAvailableIncome: result.data.incomeDriveHHLResponse.availableIncome.value});
                    this.props.form.setFieldsValue({financialProblemsType:  result.data.incomeDriveHHLResponse.financialProblemsType.value});
                    this.props.form.setFieldsValue({solutionProduct:  this.decideProductGroup(result.data.incomeDriveHHLResponse.financialProblemsType.value)});
                    showNotification(messages.incometool_success_message, messages.incometool_title, 'info');
                }
            }).catch(error => {
                if (this.props.loading) {
                    this.props.toggleSpinner();
                }
                showNotification(messages.incometool_error_message, messages.incometool_title, 'error');
            });
    };

    decideProductGroup = (productSolutionType) => {
        // this.props.setSolutionType(productSolutionType);
        if(productSolutionType === 'FINAL_RESOLUTION') {
            return 'DPO';
        } else {
            return 'Rescheduling';
        }
    };

    openIncomeTool = (url, title, token, params) => {
        window.open("", title, params);

        var form = document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("action", url);
        form.setAttribute("target", title);

        var input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'token';
        input.value = token;
        form.appendChild(input);

        document.body.appendChild(form);

        form.submit();
                  
        document.body.removeChild(form);

    };

    render() {
        const {
            getFieldDecorator,
        } = this.props.form;

        let financialInfo = {layout: this.props.layout, inputs: [
            { fieldName: 'financialInfoFinancialPlanId', type: "input", label: fields.financial_plan_id,  value: this.props.data ? this.props.data.financialInfoFinancialPlanId : null, rules: [{ required: true, message: messages.required }], iconType: "bars", disabled: true},
            { fieldName: 'financialInfoAvailableIncome', type: "input", label: fields.available_income, value: this.props.data ? this.props.data.financialInfoAvailableIncome : null, rules: [{ required: true, message: messages.required }], iconType: "wallet", disabled: true},
            { fieldName: 'financialProblemsType',  type: "select", label: fields.financial_problems_type, rules: [{ required: true, message: messages.required }], disabled:true, value: this.props.data ? this.props.data.financialProblemsType : null, selectOptions: this.props.enumerations ? mapEnumerationsToSelectItems(this.props.enumerations['FINANCIAL_PROBLEM_TYPES'], fields.no_available_info) : null},
            { fieldName: 'solutionProduct', type: "radio", label: fields.solution_type, value: this.props.data ? this.props.data && this.props.data.solutionProduct : null, radioOptions: mapInputItemsToRadioOptions(this.props.solutiontypes, true), iconType: "user", disabled: true},
            { fieldName: 'propertyOwner', type: "check", label: fields.propertyOwner, rules: [{ required: false }]}
            ]};

        let loanInfo = {layout: this.props.layout, inputs: [
            { fieldName: 'loanInfoPurposeGeneralCategory', type: "select", label: fields.loan_purpose_general_category, rules: [{ required: true, message: messages.required }], iconType: "audit", value: this.props.data ? defaultLoanReason : null, selectOptions: this.props.data ? mapStaticDataToSelectItems(this.props.staticData['LOAN_REASON_CL'], false) : null, disabled: true },
            // { fieldName: 'loanInfoInstallmentPaymentFreq', type: "input", label: fields.loan_installment_payment_frequency, rules: [{ required: false}], iconType: "wallet" },
            // { fieldName: 'loanInfoInstallmentPaymentDate', type: "date", label: fields.loan_installment_payment_date, rules: [{ type: "object", required: false}]}
            { fieldName: 'serviceBranchCode', type: "select", label: fields.branch_of_disbursement, rules: [{ required: true, message: messages.required }], value: this.props.data ? this.props.data.serviceBranchCode : null, selectOptions: this.props.data ? mapStaticDataToSelectItems(this.props.branches, true) : null, showSearch: true },

            ]};


        let genericColumnStyle = {sm: 24, md: {span: 18, offset:1}, lg: {span: 18, offset:1}};
        //let singleColumnStyle = {sm: 24, md: {span: 13, offset:0}, lg:{span: 18, offset:1}};

        let formInputs =
        {
            sections: [
                {
                    title: fields.financial_plan_info,
                    orientation: 'left',
                    type: 'financial',
                    columns: [
                        {
                            styles: genericColumnStyle,
                            inputs: financialInfo
                        }
                    ]
                }
            ]
        };

        let formInputsLoan =
        {
            sections: [
                {
                    title: fields.loan_info,
                    orientation: 'left',
                    type: 'creditcard',
                    columns: [
                        {
                            styles: genericColumnStyle,
                            inputs: loanInfo
                        }
                    ]
                }
            ]
        };

        return (
            <StyledPartialForm onSubmit={e => this.props.update(e, this.props.form, true)} layout='horizontal'>
                {renderFormComponents(formInputs.sections, getFieldDecorator)}

                <Col lg={{span: 3, offset:8}} md={{span: 5, offset:7}} sm={24} >
                    <StyledFormItem>
                            <StyledButton type="primary" onClick={(e)=>this.redirectToIncomeTool()}>{fields.proceed_action}</StyledButton>
                        </StyledFormItem>
                </Col>
                <Col lg={12} md={{span: 5, offset:1}} sm={24} >
                    <StyledFormItem>
                        <StyledButton disabled={this.props.loading} type="primary" onClick={(e)=>this.getIncomeToolResult()}>{fields.results_import}</StyledButton>
                    </StyledFormItem>
                </Col>

                {renderFormComponents(formInputsLoan.sections, getFieldDecorator)}

                <Col lg={{span: 3, offset:8}} md={{span: 5, offset:7}} sm={24} >
                    <StyledFormItem>
                        <StyledButton id="back-button" type="default" onClick={e => {
                            this.props.update(e, this.props.form, false)
                        }}>{fields.prev_button_lbl}</StyledButton>
                    </StyledFormItem>
                </Col>

                <Col lg={12} md={{span: 5, offset:1}} sm={24} >
                    <StyledFormItem>
                        <StyledButton type="primary" htmlType="submit">{fields.next_button_lbl}</StyledButton>
                    </StyledFormItem>
                </Col>
            </StyledPartialForm>
        );
    }
}

const BusinessInfoStepForm = Form.create({name: 'Business Info'})(BusinessInfoStep);

export default BusinessInfoStepForm;