import styled from "styled-components";
import {Drawer} from "antd";

const StyledSideDrawer = styled(Drawer)`
    
    && .ant-drawer-header {
        border-bottom: 5px solid #e52600;
        background: #ECECEC;        
    }
    
    && .ant-drawer-wrapper-body {
        background: #fbfbfb
    }
`;


export default StyledSideDrawer;