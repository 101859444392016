export const messages = {
    consent_phone_number_contact: "Αποδοχή χρήσης του παραπάνω αριθμού κινητού τηλεφώνου για ενημέρωση έκβασης του παρόντος αιτήματος",
    wait_message: "Παρακαλώ Περιμένετε..",
    final_submit_wait_message: "Η υποβολή του αιτήματος βρίσκεται σε εξέλιξη. Παρακαλώ περιμένετε..",
    generic_error_title: "Σφάλμα",
    cannot_withdraw_case: "Μη επιτρεπτή ενέργεια",
    generic_error_message: "Συνέβη κάποιο σφάλμα. Παρακαλώ δοκιμάστε αργότερα κι αν το πρόβλημα επιμείνει επικοινωνήστε με το διαχειριστή της εφαρμογής",
    cif_empty_or_null_error_title: "Λάθος CIF Πελάτη",
    cif_empty_or_null_error_message: "Το CIF του Πελάτη δεν μπορεί να είναι κενό! Παρακαλώ δοκιμάστε ξανά!",
    cif_digits_only_error_title: "Μη έγκυρο CIF",
    cif_digits_only_error_message: "Το CIF του Πελάτη δεν μπορεί να περιέχει χαρακτήρες! Παρακαλώ δοκιμάστε ξανά!",
    cif_length_error_message: "Το CIF του Πελάτη δεν μπορεί να είναι μεγαλύτερο από 8 χαρακτήρες! Παρακαλώ δοκιμάστε ξανά!",
    no_results_found_error_title: "Αποτελέσματα Αναζήτησης",
    case_already_in_progress_error: "Υπάρχει μη οριστικοποιημένη υπόθεση για ρύθμιση καταναλωτικών προϊόντων από τον πελάτη",
    collector_code_error: "Δεν είναι επιτρεπτή η καταχώρηση αίτησης για τον πελάτη",
    cannot_case_create_error: "Η υποβολή του αιτήματος δεν είναι εφικτή",
    cannot_case_create_related_buckets_null_error: "Έχετε εισάγει κωδικό πελάτη που δεν ανήκει στο χαρτοφυλάκιό σας",
    invalid_service_branch_error:"Το κατάστημα εξυπηρέτησης δεν βρέθηκε",
    request_already_exists_error:"Υπάρχει μη οριστικοποιημένη υπόθεση για ρύθμιση καταναλωτικών προϊόντων από τον πελάτη",
    no_request_created_error:"Δεν βρέθηκαν προϊόντα προς ρύθμιση για τον πελάτη",
    agent_not_found_error:"Πρόβλημα με την παραμετροποίηση του καταχωρητή",
    customer_does_not_exist_error:"Πρόβλημα κατά την ταυτοποίηση του πελάτη",
    invalid_bank_contract_request_detail_error:"Η δημιουργία απέτυχε. Μη συμβατά στοιχεία αιτήματος",
    loan_obligation_does_not_exist_error:"Δεν βρέθηκαν προϊόντα προς ρύθμιση για τον πελάτη",
    rules_error:"Δεν βρέθηκαν προϊόντα προς ρύθμιση για τον πελάτη",
    business_model_action_error:"Προσωρινή μη διαθεσιμότητα του συστήματος",
    validation_error:"Η δημιουργία απέτυχε. Μη συμβατά στοιχεία αιτήματος",
    other_uncaught_error:"Τεχνικό πρόβλημα, παρακαλώ επικοινωνήστε με τον διαχειριστή του συστήματος.",
    no_results_found_error_message: "Δε βρέθηκαν αποτελέσματα για τη συγκεκριμένη αναζήτηση..",
    cannot_access_error_message: "Δεν είστε εξουσιοδοτημένος για τη συγκεκριμένη αναζήτηση..",
    case_creation_failed_error_message: "H δημιουργία υπόθεσης στο DRIVE απέτυχε..",
    cannot_withdraw_case_error_message: "H ακύρωση υπόθεσης στο DRIVE απέτυχε..",
    static_data_fetch_error: "Αδυναμία ενημέρωσης του συστήματος",
    required: "Παρακαλώ εισάγετε τιμή",
    catch_same_home_phone_number: "Ο αριθμός τηλεφώνου εργασίας δεν μπορεί να είναι ο ίδιος με τον αριθμό τηλεφώνου οικίας",
    catch_same_home_occupation_phone_number: "Ο αριθμός τηλεφώνου δεν μπορεί να είναι ο ίδιος με τον αριθμό τηλεφώνου οικίας ή τον αριθμό τηλεφώνου εργασίας",
    consent_message: "Παρακαλώ συμπληρώστε το πεδίο 'Κινητό Τηλέφωνο'",
    incometool_title: "Οικονομικός Προγραμματισμός",
    incometool_not_found: "Δε βρέθηκε Οικονομικός Προγραμματισμός για τον πελάτη",
    incometool_error_message: "Αδυναμία επικοινωνίας με τον Οικονομικό Προγραμματισμό",
    incometool_success_message: "Επιτυχία στη λήψη των αποτελεσμάτων του Οικονομικού Προγραμματισμού για τον πελάτη.",
    only_letters_message: "Το πεδίο θα πρέπει να περιέχει μόνο χαρακτήρες",
    only_ten_numbers_message: "To πεδίο πρέπει να περιέχει αποκλειστικά 10 αριθμούς",
    lettersDotsSlashesDashes: "Επιτρέπονται γράμματα της ίδιας γλώσσας, '.', '\\', '/', '-'",
    only_numbers_message: "Επιτρέπονται μόνο αριθμοί",
    lettersNumbersDotsSlashesDashes: "Επιτρέπονται γράμματα της ίδιας γλώσσας, αριθμοί, '.', '\\', '/', '-'",
    invalid_address_number: "Μη έγκυρος αριθμός διεύθυνσης",
    invalidTin: "Μη έγκυρος αριθμός ΑΦΜ",
    invalid_value_length: "Το μέγεθος του πεδίου υπερβαίνει το επιτρεπτό όριο",
    invalid_id: "Μη έγκυρος αριθμός αστυνομικής ταυτότητας",
    invalid_mobile_value_length: "Το πεδίο περιέχει λανθασμένες τιμές",
    invalid_value: "Το πεδίο περιέχει λανθασμένες τιμές",
    create_case_pattern_message: (p, c) => { return `Η υπόθεση δημιουργήθηκε από τον χρήστη: ${p}, με σχόλια: ${c}`},
    invalid_pattern_message: p => { return p ? `Το πεδίο πρέπει να είναι της μορφής: ${p}` : `Το πεδίο περιέχει λανθασμένες τιμές`},
    invalid_pattern_message_email: p => { return p ? `Το πεδίο πρέπει να είναι της μορφής: ${p} και να περιέχει λατινικούς χαρακτήρες` : `Το πεδίο περιέχει λανθασμένες τιμές`},
    request_successfully_submitted: "Η υποβολή του αιτήματος έγινε με επιτυχία!",
    request_successfully_submitted_title: "Υποβολή Αιτήματος",
    no_filter_option_found: "Δεν έχετε συμπληρώσει κανένα κριτήριο αναζήτησης. Παρακαλώ δοκιμάστε ξανά!",
    no_status_option_found: "Δεν έχετε επιλέξει κατάσταση υπόθεσης. Παρακαλώ δοκιμάστε ξανά!",
    no_filter_option_found_title: "Αποτυχία Αναζήτησης",
    missing_name_criteria_values: "Πρέπει να συμπληρώσετε όλα τα απαιτούμενα κριτήρια αναζήτησης [Όνομα, Επώνυμο, Όνομα Πατέρα]",
    missing_id_criteria_values: "Πρέπει να συμπληρώσετε όλα τα απαιτούμενα κριτήρια αναζήτησης [Τύπος και Αριθμός Πιστοποιητικού]",
    future_date_selected_error_message: "H ημερομηνία που επιλέγετε δεν μπορεί να είναι μελλοντική",
    over_day_limit_error_message: "Το διάστημα που επιλέγετε δεν πρέπει να υπερβαίνει τις 30 μέρες",
    invalid_date_range: "Μη έγκυρο διάστημα",
    invalid_date: "Μη έγκυρη ημερομηνία",
    withdraw_case: "Το αίτημα ακυρώθηκε",
    withdraw_case_title: "Ακύρωση Αιτήματος",
    withdraw_case_by_user: "Ακύρωση Υπόθεσης από τον Εξωτερικό Συνεργάτη",
    can_not_withdraw_case_error_message: "Η ακύρωση αιτήματος απέτυχε",
    withdrawal_forbidden_error_message: "Δεν είστε εξουσιοδοτημένος για τη συγκεκριμένη ενέργεια",
    withdrawal_not_found_error_message: "Δε βρέθηκε αποτέλεσμα για τη συγκεκριμένη υπόθεση",
    case_already_withdrawn_error_message: "Δεν είστε εξουσιοδοτημένος για τη συγκεκριμένη ενέργεια καθώς το αίτημα έχει ήδη ακυρωθεί",
    no_results_withdrawal_error_message: "Δεν είναι δυνατή η συγκεκριμένη ενέργεια",
    already_decided_case_error_message: "Δεν είναι δυνατή η συγκεκριμένη ενέργεια καθώς το αίτημα έχει ήδη εγκριθεί ",
    case_status_withdrawal_not_allowed: "Δεν είναι δυνατή η συγκεκριμένη ενέργεια πλέον καθώς το αίτημα βρίσκεται ήδη σε επόμενο στάδιο επεξεργασίας/έγκρισης",
    case_in_edit_mode_error_message: "Δεν είναι δυνατή η συγκεκριμένη ενέργεια καθώς το αίτημα βρίσκεται σε επεξεργασία",
    inactivity_notification: t => {return `Μείνατε ανενεργός για ${t} λεπτά`},
    extension_timeout_notification: t => {return `Πρόκειται να αποσυνδεθείτε σε ${t} λεπτά.`
    }
};
