import styled from 'styled-components';
import { Button, Drawer } from 'antd';

export const MenuBar = styled.nav`
    {
        height: 70px;
        padding-left: 15px;
        border-bottom: solid 1px #e8e8e8;
        box-shadow: 0 0 30px #f3f1f1;
    }
`;

export const MenuBarLogo = styled.img`
    {
        padding-top: 25px;
        width: 130px;
        float: left;  
    }
`

export const MenuContent = styled.div`
    {
        height: 68px;
        width: calc(100% - 150px);
        float: left;
    }
    .ant-menu-item {
        height: 66px;
        padding: 10px 15px;
    }
    .ant-menu-submenu-title {
        padding: 10px 20px;
    }
    .ant-menu-item a {
        padding: 10px 15px;
    }
    .ant-menu-submenu-title a {
        padding: 10px 15px;
    }
    .ant-menu-horizontal {
        border-bottom: none;
    }
    @media(max-width: 767px) {
        .ant-menu-item {
            padding: 1px 20px;
        }
        .ant-menu-submenu-title {
            padding: 1px 20px;
        }
    }
`;

export const StyledLeftMenuContainer = styled.div`
    {
        height: 66px;
        float: left;
    }

    @media(max-width: 767px) {
        display: none;
    }
    
`;

export const StyledRightMenuContainer = styled.div`
    {
        height: 66px;
        float: right;
    }
    
    @media(max-width: 767px) {
        display: none;
    }
`;

export const BarsMenu = styled(Button)`
    && {
        float: right;
        height: 32px;
        padding: 6px;
        margin-top: 20px;
        display: none;
        background: none;
        border-color: #4a4a4a;
    }
    && { 
        @media(max-width: 767px) {
            display: inline-block;
        }
    }
`;

let barsBtnAfterBefore = 'content: attr(x); width: 20px; position: absolute; top: -10px; left: 0; height: 2px; background: #4a4a4a;'

export const BarsButton = styled.span`
    {
        display: block;
        width: 20px;
        height: 2px;
        background: #4a4a4a;
        position: relative;
    }
    
    &:after {
        ${barsBtnAfterBefore}
    }
    &:before {
        ${barsBtnAfterBefore}
    }
    &:after{
        top: -5px;
        bottom: -6px;
    }
`;

export const StyledDrawer = styled(Drawer)`
    {
        .ant-drawer-body {
            padding: 0;
        }
        .ant-drawer-body .ant-menu-horizontal > .ant-menu-item, .ant-drawer-body .ant-menu-horizontal > .ant-menu-submenu{
            display: inline-block;
            width: 100%;
        }
        .ant-drawer-body .ant-menu-horizontal{
            border-bottom: none;
        }
        .ant-drawer-body .ant-menu-horizontal > .ant-menu-item:hover{
            border-bottom-color: transparent;
        }
    }
`

