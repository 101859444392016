import styled from 'styled-components';
import backgroundImage from '../../../images/landing-image1.jpeg';

export const PageWithBackgroundImage = styled.div`
    {
        background: url(${backgroundImage}) no-repeat center center;
        height: calc(100vh);
        width: 100%;
        object-fit: cover;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }
`;