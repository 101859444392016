import React from 'react';
import { Menu, Icon } from 'antd';
import { Link } from 'react-router-dom';
import authService from '../../services/AuthService';
import { fields } from '../../config/fields_el';

const LeftMenu = (props) => {

  return (
    <Menu mode={props.mode} selectedKeys={[props.current]}>
      {authService.canAccess(props.userInfo, 'History') && (props.creditInstitution || authService.hasRole(props.userInfo, 'ROLE_LIAISON')) ? (<Menu.Item key="History">
        <Icon type="folder" />{fields.history}
        <Link to="/cases" />
      </Menu.Item>) : (null)}
      {authService.canAccess(props.userInfo, 'CaseWizard') && props.creditInstitution ? (<Menu.Item key="CaseWizard">
        <Icon type="folder" />{fields.wizard}
        <Link to="/create-case" />
      </Menu.Item>) : (null)}
    </Menu>
  );
}

export default LeftMenu;