import styled from 'styled-components';

export const StyledPageWithMargin = styled.div`
    @media(min-width: 768px) {
        && {
            margin: 1% 5% 3% 5%;
        }
    }
    @media(max-width: 767px) {
        && {
            margin: 1% 1% 1% 1%;
        }
    }
`;