import styled from 'styled-components';
import {Collapse} from "antd";
const { Panel } = Collapse;

export const StyledPanel = styled(Panel)`
    {
      font-size: 0.9rem;
      font-weight: 400;
      color: '#4A4A4A';
      background-color: #ececec;
      border-radius: 4;
      margin-bottom: 24;
            border: 0;
      overflow: hidden;  
      // margin-top: 3%  
    }
`;

export default StyledPanel;