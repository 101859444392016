import React, { Component } from 'react';
import { Col, Card } from 'antd';
import {
    getDisplayValue,
    renderDescriptions,
    applyDateFormat,
    mapDriveState,
    createTag, getDisplayValueOfApprovedAmounts,
    convertToCurrency, percentageFormat,
    displayBooleanValues, mapStaticDataToSelectItems
} from "../../helpers/InputHelper";
import StyledButton from "../../components/styled-components/controls/StyledButton";
import { fields } from "../../config/fields_el";
import MediaQuery from "react-responsive";
import StyledDivider from '../../components/styled-components/layout/StyledDivider';
import {
    displayDateTimeFormat,
    CL_RESCHEDULING_PRIVILEGED_PERIOD_CODE,
    CL_RESCHEDULING_INDIVIDUAL_SOLUTION_CODE,
    CL_RESCHEDULING_SINGLE_INSTALLMENT_INTEREST_CODE,
    CL_RESCHEDULING_WITH_INTEREST_PAYMENT_CODE,
    CL_RESCHEDULING_CRO_CODE,
    CL_RESCHEDULING_CRO_WITH_DF_CODE,
    CL_RESCHEDULING_DEBT_RELIEF_WITHOUT_POTENTIAL_GIVE_AWAY_CODE
} from "../../config/Properties";
import { Divider } from 'antd';

class CaseDetailsStep extends Component {

    renderSolution() {
        let simpleSolution = [];
        let master = [];
        let shadow = [];

        if(this.props.data) {
            if(this.props.data.product) {
                simpleSolution.push({ titleOfField: fields.product, fieldValue: this.props.data ? getDisplayValue(this.props.data.productName) : null});
                switch (this.props.data.product) {
                    case 'DPO': {
                        simpleSolution.push({ titleOfField: fields.initialPaymentAmount, fieldValue: this.props.data ? convertToCurrency(getDisplayValue(this.props.data.initialPaymentAmount)) : null});
                        simpleSolution.push({ titleOfField: fields.approvedPaymentAmount, fieldValue: this.props.data ? convertToCurrency(getDisplayValueOfApprovedAmounts(this.props.data.approvedPaymentAmount)) : null});
                        break;
                    }
                    case 'NO_INTEREST_DF':
                    case 'DF': {
                        simpleSolution.push({ titleOfField: fields.restructuringAmount, fieldValue: this.props.data ? convertToCurrency(getDisplayValue(this.props.data.restructuringAmount)) : null});
                        simpleSolution.push({ titleOfField: fields.frozenInterest, fieldValue: this.props.data ? convertToCurrency(getDisplayValue(this.props.data.frozenInterest)) : null});

                        //master
                        if(this.props.data.loanPartsWebDTOList && this.props.data.loanPartsWebDTOList.length > 0) {
                            master.push({ titleOfField: fields.loanAmount, fieldValue: this.props.data ? convertToCurrency(getDisplayValue(this.props.data.loanPartsWebDTOList[0].totalAmount)) : null});
                            master.push({ titleOfField: fields.normalInstallmentAmount, fieldValue: this.props.data ? convertToCurrency(getDisplayValue(this.props.data.loanPartsWebDTOList[0].normalInstallmentAmount)) : null});
                            master.push({ titleOfField: fields.normalInterest, fieldValue: this.props.data ? percentageFormat(getDisplayValue(this.props.data.loanPartsWebDTOList[0].normalInterest)) : null});
                            master.push({ titleOfField: fields.normalLoanDuration, fieldValue: this.props.data ? getDisplayValue(this.props.data.loanPartsWebDTOList[0].normalLoanDuration) : null});
                        }

                        //shadow
                        if(this.props.data.loanPartsWebDTOList && this.props.data.loanPartsWebDTOList.length > 1) {
                            shadow.push({ titleOfField: fields.shadowLoanAmount, fieldValue: this.props.data ? convertToCurrency(getDisplayValue(this.props.data.loanPartsWebDTOList[1].totalAmount)) : null});
                            shadow.push({ titleOfField: fields.shadowLoanDuration, fieldValue: this.props.data ? getDisplayValue(this.props.data.loanPartsWebDTOList[1].normalLoanDuration) : null});
                        }
                        break;
                    }
                    case 'CRO_DF': {
                        simpleSolution.push({ titleOfField: fields.restructuringAmount, fieldValue: this.props.data ? convertToCurrency(getDisplayValue(this.props.data.restructuringAmount)) : null});
                        simpleSolution.push({ titleOfField: fields.frozenInterest, fieldValue: this.props.data ? convertToCurrency(getDisplayValue(this.props.data.frozenInterest)) : null});

                        //master
                        if(this.props.data.loanPartsWebDTOList && this.props.data.loanPartsWebDTOList.length > 0) {
                            master.push({ titleOfField: fields.loanAmount, fieldValue: this.props.data ? convertToCurrency(getDisplayValue(this.props.data.loanPartsWebDTOList[0].totalAmount)) : null});
                            master.push({ titleOfField: fields.normalLoanDuration, fieldValue: this.props.data ? getDisplayValue(this.props.data.loanPartsWebDTOList[0].normalLoanDuration) : null});
                            master.push({ titleOfField: fields.privilegedInstallmentAmount, fieldValue: this.props.data ? convertToCurrency(getDisplayValue(this.props.data.loanPartsWebDTOList[0].privilegedInstallmentAmount)) : null});
                            master.push({ titleOfField: fields.normalInstallmentAmount, fieldValue: this.props.data ? convertToCurrency(getDisplayValue(this.props.data.loanPartsWebDTOList[0].normalInstallmentAmount)) : null});
                            master.push({ titleOfField: fields.priviledgedInterest, fieldValue: this.props.data ? percentageFormat(getDisplayValue(this.props.data.loanPartsWebDTOList[0].priviledgedInterest)) : null});
                            master.push({ titleOfField: fields.monthsOfPriviledgedDuration, fieldValue: this.props.data ? getDisplayValue(this.props.data.loanPartsWebDTOList[0].fixedRatePeriod) : null});
                            master.push({ titleOfField: fields.normalInterest, fieldValue: this.props.data ? percentageFormat(getDisplayValue(this.props.data.loanPartsWebDTOList[0].normalInterest)) : null});
                        }

                        //shadow
                        if(this.props.data.loanPartsWebDTOList && this.props.data.loanPartsWebDTOList.length > 1) {
                            shadow.push({ titleOfField: fields.shadowLoanAmount, fieldValue: this.props.data ? convertToCurrency(getDisplayValue(this.props.data.loanPartsWebDTOList[1].totalAmount)) : null});
                            shadow.push({ titleOfField: fields.shadowLoanDuration, fieldValue: this.props.data ? getDisplayValue(this.props.data.loanPartsWebDTOList[1].normalLoanDuration) : null});
                        }
                        break;
                    }
                    case 'RESCHEDULING': {
                        simpleSolution.push({ titleOfField: fields.restructuringAmount, fieldValue: this.props.data ? convertToCurrency(getDisplayValue(this.props.data.restructuringAmount)) : null});

                        if(this.props.data.loanPartsWebDTOList && this.props.data.loanPartsWebDTOList.length > 0) {
                            //Product 122 - Άτοκη Ρύθμιση (Rescheduling)
                             if (this.props.data.loanPartsWebDTOList[0].solutionProduct === CL_RESCHEDULING_INDIVIDUAL_SOLUTION_CODE) {
                                 simpleSolution.push({ titleOfField: fields.normalInstallmentAmount, fieldValue: this.props.data ? convertToCurrency(getDisplayValue(this.props.data.loanPartsWebDTOList[0].normalInstallmentAmount)) : null});
                                 simpleSolution.push({ titleOfField: fields.normalInterest, fieldValue: this.props.data ? percentageFormat(getDisplayValue(this.props.data.loanPartsWebDTOList[0].normalInterest)) : null});
                                 simpleSolution.push({ titleOfField: fields.normalLoanDuration, fieldValue: this.props.data ? getDisplayValue(this.props.data.loanPartsWebDTOList[0].normalLoanDuration) : null});
                            }
                            //Product 123 - Ρύθμιση με προνομιακή περίοδο (Rescheduling)
                            else if (this.props.data.loanPartsWebDTOList[0].solutionProduct === CL_RESCHEDULING_PRIVILEGED_PERIOD_CODE) {
                                 simpleSolution.push({ titleOfField: fields.privilegedInstallmentAmount, fieldValue: this.props.data ? convertToCurrency(getDisplayValue(this.props.data.loanPartsWebDTOList[0].privilegedInstallmentAmount)) : null});
                                 simpleSolution.push({ titleOfField: fields.priviledgedInterest, fieldValue: this.props.data ? percentageFormat(getDisplayValue(this.props.data.loanPartsWebDTOList[0].priviledgedInterest)) : null});
                                 simpleSolution.push({ titleOfField: fields.monthsOfPriviledgedDuration, fieldValue: this.props.data ? getDisplayValue(this.props.data.loanPartsWebDTOList[0].fixedRatePeriod) : null});
                                 simpleSolution.push({ titleOfField: fields.normalInstallmentAmount, fieldValue: this.props.data ? convertToCurrency(getDisplayValue(this.props.data.loanPartsWebDTOList[0].normalInstallmentAmount)): null});
                                 simpleSolution.push({ titleOfField: fields.normalInterest, fieldValue: this.props.data ? percentageFormat(getDisplayValue(this.props.data.loanPartsWebDTOList[0].normalInterest)) : null});
                                 simpleSolution.push({ titleOfField: fields.normalLoanDuration, fieldValue: this.props.data ? getDisplayValue(this.props.data.loanPartsWebDTOList[0].normalLoanDuration) : null});
                             }
                             //Product 124 - Ρύθμιση με ενιαία TX δόση (Rescheduling)
                             else if (this.props.data.loanPartsWebDTOList[0].solutionProduct === CL_RESCHEDULING_SINGLE_INSTALLMENT_INTEREST_CODE) {
                                 simpleSolution.push({ titleOfField: fields.normalInstallmentAmount, fieldValue: this.props.data ? convertToCurrency(getDisplayValue(this.props.data.loanPartsWebDTOList[0].normalInstallmentAmount)) : null});
                                 simpleSolution.push({ titleOfField: fields.normalInterest, fieldValue: this.props.data ? percentageFormat(getDisplayValue(this.props.data.loanPartsWebDTOList[0].normalInterest)) : null});
                                 simpleSolution.push({ titleOfField: fields.normalLoanDuration, fieldValue: this.props.data ? getDisplayValue(this.props.data.loanPartsWebDTOList[0].normalLoanDuration) : null});
                             }
                             //Product 125 - Ρύθμιση με Τοκοπληρωμή
                            else if (this.props.data.loanPartsWebDTOList[0].solutionProduct === CL_RESCHEDULING_WITH_INTEREST_PAYMENT_CODE) {
                                 simpleSolution.push({ titleOfField: fields.normalLoanDuration, fieldValue: this.props.data ? getDisplayValue(this.props.data.loanPartsWebDTOList[0].normalLoanDuration) : null});
                                 simpleSolution.push({ titleOfField: fields.interestPayment, fieldValue: this.props.data ? displayBooleanValues(getDisplayValue(this.props.data.loanPartsWebDTOList[0].interestPayment)) : null});
                                 simpleSolution.push({ titleOfField: fields.privilegedInstallmentAmount, fieldValue: this.props.data ? convertToCurrency(getDisplayValue(this.props.data.loanPartsWebDTOList[0].privilegedInstallmentAmount)) : null});
                                 simpleSolution.push({ titleOfField: fields.normalInstallmentAmount, fieldValue: this.props.data ? convertToCurrency(getDisplayValue(this.props.data.loanPartsWebDTOList[0].normalInstallmentAmount)): null});
                                 simpleSolution.push({ titleOfField: fields.priviledgedInterest, fieldValue: this.props.data ? percentageFormat(getDisplayValue(this.props.data.loanPartsWebDTOList[0].priviledgedInterest)) : null});
                                 simpleSolution.push({ titleOfField: fields.monthsOfPriviledgedDuration, fieldValue: this.props.data ? getDisplayValue(this.props.data.loanPartsWebDTOList[0].fixedRatePeriod) : null});
                                 simpleSolution.push({ titleOfField: fields.normalInterest, fieldValue: this.props.data ? percentageFormat(getDisplayValue(this.props.data.loanPartsWebDTOList[0].normalInterest)) : null});
                             }
                             //Product 126 - Ρύθμιση με CRO code
                            else if (this.props.data.loanPartsWebDTOList[0].solutionProduct === CL_RESCHEDULING_CRO_CODE) {
                                 simpleSolution.push({ titleOfField: fields.normalLoanDuration, fieldValue: this.props.data ? getDisplayValue(this.props.data.loanPartsWebDTOList[0].normalLoanDuration) : null});
                                 simpleSolution.push({ titleOfField: fields.privilegedInstallmentAmount, fieldValue: this.props.data ? convertToCurrency(getDisplayValue(this.props.data.loanPartsWebDTOList[0].privilegedInstallmentAmount)) : null});
                                 simpleSolution.push({ titleOfField: fields.normalInstallmentAmount, fieldValue: this.props.data ? convertToCurrency(getDisplayValue(this.props.data.loanPartsWebDTOList[0].normalInstallmentAmount)): null});
                                 simpleSolution.push({ titleOfField: fields.priviledgedInterest, fieldValue: this.props.data ? percentageFormat(getDisplayValue(this.props.data.loanPartsWebDTOList[0].priviledgedInterest)) : null});
                                 simpleSolution.push({ titleOfField: fields.monthsOfPriviledgedDuration, fieldValue: this.props.data ? getDisplayValue(this.props.data.loanPartsWebDTOList[0].fixedRatePeriod) : null});
                                 simpleSolution.push({ titleOfField: fields.normalInterest, fieldValue: this.props.data ? percentageFormat(getDisplayValue(this.props.data.loanPartsWebDTOList[0].normalInterest)) : null});
                             }
                             //Product 128 - Ρύθμιση DF χωρίς δυνητικό χάρισμα
                            else if (this.props.data.loanPartsWebDTOList[0].solutionProduct === CL_RESCHEDULING_DEBT_RELIEF_WITHOUT_POTENTIAL_GIVE_AWAY_CODE) {
                                 simpleSolution.push({ titleOfField: fields.frozenInterest, fieldValue: this.props.data ? convertToCurrency(getDisplayValue(this.props.data.frozenInterest)) : null});
                                 simpleSolution.push({ titleOfField: fields.normalLoanDuration, fieldValue: this.props.data ? getDisplayValue(this.props.data.loanPartsWebDTOList[0].normalLoanDuration) : null});
                                 simpleSolution.push({ titleOfField: fields.normalInstallmentAmount, fieldValue: this.props.data ? convertToCurrency(getDisplayValue(this.props.data.loanPartsWebDTOList[0].normalInstallmentAmount)): null});
                                 simpleSolution.push({ titleOfField: fields.normalInterest, fieldValue: this.props.data ? percentageFormat(getDisplayValue(this.props.data.loanPartsWebDTOList[0].normalInterest)) : null});
                             }
                        }

                        break;
                    }
                    default: {
                        //TODO:add functionality
                        break;
                    }
                }
            }
        }

        return (
            <div>
                { simpleSolution.length > 0 ? <div>
                        <Divider style={{color: '#1e4485'}} orientation="left">
                            {createTag(0, '#1e4485', fields.deptAnalysis, '', true)}
                        </Divider>
                        {renderDescriptions(simpleSolution, "vertical", 3)}
                    </div> : null
                }
                { master.length > 0 ? <div>
                        <Divider style={{color: '#1e4485'}} orientation="left">
                           {createTag(0,  '#1e4485', 'Master', '', true)}
                        </Divider>
                        {renderDescriptions(master, "vertical", 2)}
                    </div> : null
                }
                { shadow.length > 0 ? <div>
                        <Divider style={{color: '#1e4485'}} orientation="left">
                            {createTag(0,  '#1e4485', fields.shadowAccount, '', true)}
                        </Divider>
                        {renderDescriptions(shadow, "vertical", 2)}
                    </div> : null
                }
            </div>
        );
    }

    render() {
        let descriptionItems = [
            { titleOfField: fields.case_id, fieldValue: this.props.data ? getDisplayValue(this.props.data.id) : null },
            { titleOfField: fields.status, fieldValue: this.props.data ? mapDriveState(this.props.data.state) : null },
            { titleOfField: fields.cif, fieldValue: this.props.data ? getDisplayValue(this.props.data.primaryCustomerCid) : null },
            { titleOfField: fields.name_and_surname, fieldValue: this.props.data ? getDisplayValue(this.props.data.customerName) + ' ' + getDisplayValue(this.props.data.customerSurname) : null },
            { titleOfField: fields.service_branch, fieldValue: this.props.data ? getDisplayValue(this.props.data.serviceBranchCode + ' - ' + this.props.data.serviceBranch) : null},
            { titleOfField: fields.service_account_number, fieldValue: this.props.data ? getDisplayValue(this.props.data.account) : null },
            { titleOfField: fields.input_date, fieldValue: this.props.data ? getDisplayValue(applyDateFormat(this.props.data.creationDate, displayDateTimeFormat)) : null },
            { titleOfField: fields.credit_institution, fieldValue: this.props.data ? getDisplayValue(this.props.data.creditInstitution) : null },
            { titleOfField: fields.agent, fieldValue: this.props.data ? getDisplayValue(this.props.data.agent) : null }
        ];

        if(this.props.data.state === 'REJECTED') {
            descriptionItems.push({
                titleOfField: fields.rejection_reason,
                fieldValue: this.props.data ? getDisplayValue(this.props.data.rejectionReason) : null
            });
        }

        return (
            <div>
                <MediaQuery maxDeviceWidth={766}>
                    <StyledDivider title={fields.case_details} orientation='left' type='board' />
                    <Col span={24}>
                        <Card bordered={false}>
                            {renderDescriptions(descriptionItems, "vertical", 1)}
                        </Card>
                    </Col>
                    {/*render card for generalInfo in small view*/}

                    <StyledDivider title={fields.information} orientation='left' type='board' />
                    <Card bordered={false}>
                        {this.renderSolution()}
                    </Card>
                </MediaQuery>

                {/*--medium/large starting below-- */}

                <MediaQuery minDeviceWidth={767}>
                    <Col span={24}>
                        <StyledDivider title={fields.case_details} orientation='left' type='board' />
                        <Card bordered={false}>
                            <Card.Grid style={{ width: '100%' }}>
                                {renderDescriptions(descriptionItems, "vertical", 2)}
                            </Card.Grid>
                        </Card>
                    </Col>
                    {/*render card for generalInfo in large/medium view*/}
                    <Col span={24}>
                        <StyledDivider title={fields.information} orientation='left' type='board' />
                        <Card bordered={false}>
                            <Card.Grid style={{ width: '100%' }}>
                                {this.renderSolution()}
                            </Card.Grid>
                        </Card>
                    </Col>
                </MediaQuery>

                <Col span={24}>
                    <StyledDivider title={fields.documents} orientation='left' type='file-search'/>
                    <Col style={{ marginBottom: "30px" }} sm={24}>
                        <StyledButton icon="right" type="primary" onClick={e => {
                                this.props.action(this.props.data.id, e)
                            }}>{fields.monitor} </StyledButton>
                    </Col>
                </Col>
            </div>
        )
    }
}

export default CaseDetailsStep;