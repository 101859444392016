import React from 'react';
import { StyledAppTitle, StyledAppWelcome } from '../../components/styled-components/layout/StyledLandingPage'
import { fields } from '../../config/fields_el'
import { Row, Col } from 'antd'
import { PageWithBackgroundImage } from '../../components/styled-components/layout/PageWithBackgroundImage'
import { Link } from 'react-router-dom'
import { LandingWizardButton } from '../../components/styled-components/layout/StyledLandingPage'

const NotFound = (props) => {
  return (
    <div>
      <PageWithBackgroundImage>
        <Row type="flex" justify="center">
          <Col span={24}>
            <StyledAppTitle>{fields.app_name}</StyledAppTitle>
          </Col>
        </Row>
        <div>
          <StyledAppWelcome>{fields.app_not_found}</StyledAppWelcome>
        </div>
        <Row type="flex" justify="center">
          <Col xs={24} lg={{ span: 4 }} md={{ span: 6 }} sm={24} style={{ textAlign: 'center' }}>
            <Link to={'/'}>
              <LandingWizardButton type={'button'}>{fields.landing}</LandingWizardButton>
            </Link>
          </Col>
        </Row>
      </PageWithBackgroundImage>
    </div>
  );
}

export default NotFound;
