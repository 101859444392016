/**
 * Created by kape on 29/03/2019.
 */
import React, { Component } from 'react';
import AppPage from '../components/layout/AppPage';
import dataProvider from '../services/DataProvider';
import PersonalInfoStep from './case_wizard_steps/PersonalInfoStep';
import BranchInfoStep from './case_wizard_steps/BranchInfoStep';
import BusinessInfoStep from './case_wizard_steps/BusinessInfoStep';
import { StyledSteps } from '../components/styled-components/form/StyledStep';
import { messages } from '../config/messages_el';
import { fields } from '../config/fields_el';
import LoadingSpinner from '../components/LoadingSpinner';
import { isWithinLengthLimits, onlyDigits } from '../validators/InputValidators';
import { showNotification } from '../helpers/NotificationHelper';
import { isMoment } from 'moment';
import { serverDateFormat } from '../config/Properties';
import { CaseWizardSearchBox } from '../components/styled-components/CaseWizardSearchBox';
import { StyledPageWithMargin } from '../components/styled-components/layout/StyledPageWithMargin';
import DisplayBox from '../components/layout/DisplayBox';
import { createTag, pad, prepareRequestData } from '../helpers/InputHelper';
import { Steps } from 'antd';
import { createCaseAction} from "../config/Properties";


const formItemLayout = {
    labelCol: {
        lg: {//desktop-laptop
            span: 10, offset: 0
        },
        md: {//tablet
            span: 24, offset: 0
        },
        sm: {//mobile
            span: 24, offset: 0
        },
        xs: {//mobile
            span: 24, offset: 0
        }
    },
    wrapperCol: {
        lg: {
            span: 12, offset: 0
        },
        md: {
            span: 24, offset: 0
        },
        sm: {
            span: 24, offset: 0
        },
        xs: {
            span: 24, offset: 0
        }
    },

};

class CaseWizard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            current: props.location.pathname === '/' ? '' : props.location.pathname.substr(1),
            data: null,
            staticData: null,
            enumerations: null,
            branches: null,
            serviceBranchCode: null,
            propertyOwner: false,
            step: 0,
            cid: '',
            activeTab: "1",
            loading: false,
            solutiontypes: ['Rescheduling', 'DPO'],
            results: [],
            attorneyIsRequired: true,
            isSpouseRequired: false,
            pools: []
        };
        this.scrollRef = React.createRef();
    }

    update = (e, form, proceed) => {
        e.preventDefault();
        if (proceed !== false) {
            form.validateFieldsAndScroll((err, values) => {
                console.log(values);
                if (!err) {
                    this.doUpdate(values, proceed);
                }
            })
        } else {
            this.doUpdate(form.getFieldsValue(), proceed);
        }
    };

    doUpdate = (values, proceed) => {
        for (var key in values) {
            var value = values[key];
            if (isMoment(value)) {
                values[key] = value.format(serverDateFormat);
            }
        }
        var current = this.state.data;
        var updated = { ...current, ...values };
        console.log("Updated state is: " + JSON.stringify(updated));
        if (proceed === true) {
            this.setState({ step: this.state.step + 1, data: updated, attorneyIsRequired: (updated.solutionProduct === 'Rescheduling'), isSpouseRequired: (updated.maritalStatus === 'MARRIED') });
            this.scrollRef.current.scrollIntoView({ behavior: 'smooth' });
        } else if (proceed === false) {
            this.setState({ step: this.state.step - 1, data: updated, attorneyIsRequired: (updated.solutionProduct === 'Rescheduling'), isSpouseRequired: (updated.maritalStatus === 'MARRIED') });
            this.scrollRef.current.scrollIntoView({ behavior: 'smooth' });
        } else {
            this.setState({ data: updated });
            this.submit(updated);
        }
    };

    prepareData = (data) => {
        let comment = messages.create_case_pattern_message(this.props.userInfo.email, data.comment);
        return prepareRequestData(createCaseAction, null, comment, this.props.creditInstitution, data, this.props.userInfo.email);
    };

    submit = (data) => {
        console.log(data);
        this.toggleSpinner();
        this.scrollRef.current.scrollIntoView({ behavior: 'smooth' });
        //console.log("Submitting: " + JSON.stringify(data));

        let payload = this.prepareData(data);
        console.log("payload " + JSON.stringify(payload));

        dataProvider.createDriveCase(JSON.stringify(payload)).then(result => {
            if (result.data.error) {
                this.toggleSpinner();
                showNotification(messages.case_creation_failed_error_message, messages.generic_error_title, 'warning');
            } else {
                console.log(result.data);
                this.toggleSpinner();
                showNotification(messages.request_successfully_submitted + ' Αριθμός Αιτήματος: ' + result.data.caseId, messages.request_successfully_submitted_title, 'success');
                this.resetStateValues();
                this.scrollRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        }).catch(error => {
            console.log(error);
            this.toggleSpinner();
             if (error.response.status === 403) {
                 this.prepareAndShowNotifications(error);
             } else {
                showNotification(messages.case_creation_failed_error_message, messages.generic_error_title, 'error');
             }
        });
    };

    prepareAndShowNotifications = (error) => {
        if (messages[error.response.data.error.code.toLowerCase()+'_error']) {
            showNotification(messages[error.response.data.error.code.toLowerCase()+'_error'],  messages.generic_error_title, 'warning');
        } else {
            showNotification(messages.case_creation_failed_error_message, messages.generic_error_title, 'error');
        }
    };

    resetStateValues = () => {
        this.setState({ data: null, step: 0, cid: '', activeTab: "1", loading: false, results: [] });
    };

    previousStep = (e, props) => {
        e.preventDefault();
        this.setState({ step: this.state.step - 1 });
        this.scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    updateStaticData = (enums, branches, staticData) => {
        this.setState({ enumerations: enums, branches: branches, staticData: staticData });
    };

    searchByCid = (cid) => {
        if (!cid) {
            showNotification(messages.cif_empty_or_null_error_message, messages.cif_empty_or_null_error_title, 'error');
        } else if (!onlyDigits(cid)) {
            showNotification(messages.cif_digits_only_error_message, messages.cif_digits_only_error_title, 'error');
        } else if (!isWithinLengthLimits(cid, 0, 8)) {
            showNotification(messages.cif_length_error_message, messages.cif_digits_only_error_title, 'error');
        } else {
            this.toggleSpinner();
            Promise.all([dataProvider.getEnumerations("GENDER_TYPE,CUSTOMER_MARITAL_STATUS_TYPE,CURRENT_OCCUPATION_TYPE,FINANCIAL_PROBLEM_TYPES"),
            dataProvider.getAvailableBranches(),
            dataProvider.getStaticData("TAX_OFFICE_CODE,JOB_TYPE,IDENTIFICATION_TYPE,STAY_STATUS_CODE,SBB_ASSESSMENT,HLB_ASSESSMENT,LOAN_REASON_CL,NATIONALITY", "asc")]).then(results => {
                const enums = results[0].data;
                const branches = results[1].data;
                const staticData = results[2].data;
                this.updateStaticData(enums, branches, staticData);
                dataProvider.getCustomerByCid(cid, this.props.creditInstitution).then(result => {
                    if (result.data.error) {
                        console.log(result.data.error);
                        this.toggleSpinner();
                        showNotification(messages.no_results_found_error_message, messages.no_results_found_error_title, 'warning');
                    } else {
                        this.setState({ data: result.data, cid: cid, step: this.state.step + 1, loading: false,
                            isSpouseRequired: (result.data.maritalStatus === 'MARRIED'),
                            spouseInfoName: result.data.customerRelations ? result.data.customerRelations[0].name : null,
                            pools: result.data.relatedBuckets});
                        this.scrollRef.current.scrollIntoView({ behavior: 'smooth' });
                    }
                }).catch(error => {
                    this.toggleSpinner();
                    if (error.response.status === 404) {
                        showNotification(messages.no_results_found_error_message, messages.no_results_found_error_title, 'warning');
                    } else if (error.response.status === 403 ) {
                        this.prepareAndShowNotifications(error);
                    } else {
                        showNotification(messages.generic_error_message, messages.generic_error_title, 'error');
                    }
                });
            }).catch(error => {
                console.log('static data error')
                console.log(error);
                this.toggleSpinner();
                showNotification(messages.static_data_fetch_error, messages.no_results_found_error_title, 'error');
            });
        }

    };

    toggleSpinner = () => {
        this.state.loading ? this.setState({ loading: false }) : this.setState({ loading: true });
    };

    renderSteps() {
        switch (this.state.step) {
            case 0:
                return (

                    <CaseWizardSearchBox layout={formItemLayout} title={fields.search_box_title} action={this.searchByCid} />

                );
            case 1:
                return (
                    <PersonalInfoStep layout={formItemLayout} update={this.update} data={this.state.data} back={this.previousStep}
                        staticData={this.state.staticData} enumerations={this.state.enumerations} />
                );
            case 2:
                return (
                    <BusinessInfoStep layout={formItemLayout} update={this.update} data={this.state.data} back={this.previousStep}
                        creditInstitution={this.props.creditInstitution}
                        userInfo={this.props.userInfo} enumerations={this.state.enumerations}
                        toggleSpinner={this.toggleSpinner} loading={this.state.loading} branches={this.state.branches}
                        staticData={this.state.staticData} solutiontypes={this.state.solutiontypes} pools={this.state.pools}/>
                );
            case 3:
                return (
                    <BranchInfoStep layout={formItemLayout} update={this.update} data={this.state.data} back={this.previousStep}
                        staticData={this.state.staticData} enumerations={this.state.enumerations} attorneyIsRequired={this.state.attorneyIsRequired}
                        isSpouseRequired={this.state.isSpouseRequired} />
                )
            default:
                return null;
        }
    }

    render() {
        return (
            <AppPage {...this.props}>
                <StyledPageWithMargin {...this.props}>
                    <div ref={this.scrollRef}/>
                    <DisplayBox>
                        <StyledSteps current={this.state.step} >
                            <Steps.Step size={'small'} title={fields.search} expandview={this.state.step >= 0 ? 1 : 0} />
                            <Steps.Step size={'small'} title={fields.customer_data} expandview={this.state.step >= 1 ? 1 : 0} />
                            <Steps.Step size={'small'} title={fields.financial_info} expandview={this.state.step >= 2 ? 1 : 0} />
                            <Steps.Step size={'small'} title={fields.other_info} expandview={this.state.step >= 3 ? 1 : 0} />
                        </StyledSteps>
                        {createTag(0, '#1e4485', fields.credit_institution, sessionStorage.getItem('creditInstitution'))}
                        {this.state.cid ? createTag(1, '#1e4485', fields.cif, pad(this.state.cid, 8), false) : null}
                        {this.state.data ? createTag(2, '#1e4485', fields.customer_data, this.state.data.surname + " " + this.state.data.firstName, false) : null}
                    </DisplayBox>
                    <LoadingSpinner iconSpinType='' size='large' spinning={this.state.loading} waitMessage={messages.wait_message}>
                        {this.renderSteps()}
                    </LoadingSpinner>
                </StyledPageWithMargin>
            </AppPage>
        )
    }
}

export default CaseWizard;
