import styled, { keyframes } from 'styled-components';
import { Spin } from 'antd';

const changeSpinColor = keyframes`
    0% {
        background-color: #f44e00;
        opacity: 1;
    }
    100% {
        opacity: 1;
        background-color: #0c6db3;
    }
`;

const StyledSpinner = styled(Spin)`
    {
        && .ant-spin-dot i {
            font-size: 40px;
            opacity: 0.5;
            background-color: white;
            animation: ${changeSpinColor} 1s infinite;
            width: 12px;
            height: 12px;
        },
      
        div {
            font-size: 15px;
            margin-top: 4%;
        }
    }
`;

export default StyledSpinner;