import {notification} from 'antd';

/**
 * Displays an antd notification
 * 
 * @param {string} errorMessage 
 * @param {string} errorTitle 
 * @param {('warning'|'info'|'success'|'error')} type 
 */
export const showNotification = (message, title, type) => {
    const args = {
        className: 'notification-' + type,
        message: title,
        description: message,
        duration: 6,
        placement: 'bottomRight',
    };

    notification[type](args);
};
