import styled from 'styled-components';
import {PageWithBackgroundImage} from "./PageWithBackgroundImage";

 export const StyledLandingPage = styled(PageWithBackgroundImage)`
    {
        height: calc(100vh - 134px);
    }
`;

export const LandingHistoryButton = styled.button`
{
    color: white;
    text-align: center;
    margin-top: 5%;
    border: solid 2px white;
    background: none;
    background-repeat: no-repeat;
    overflow: hidden;
    outline: none;
    border-radius: 8px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    height: 40px;
    transition: all 0.5s;

    &:hover {
        background: #e52600;
    }
    
    @media (max-width: 767px) {
        width: 90%;
        margin-right: 5%;
        margin-left: 5%;
    }
}
`;

export const LandingWizardButton = styled.button`
{
    color: white;
    border: none;
    text-align: center;
    margin-top: 5%;
    background: #e52600;
    border-radius: 8px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    height: 40px;
    
    @media (max-width: 767px) {
        width: 90%;
        margin-right: 5%;
        margin-left: 5%;
    }
}
`;

export const StyledAppTitle = styled.p`
    { 
        font-weight: 600; 
        color: white;
        font-size: 300%; 
        text-align: center;
        padding-top: 10%;
        white-space: wrap 
    }
`;

export const StyledAppWelcome = styled.p`
    { 
        font-weight: 100; 
        color: white;
        font-size: 110%; 
        text-align: center;
        padding-top: 1%;
        white-space: wrap; 
    }
`;