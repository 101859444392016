import styled, {keyframes} from "styled-components";

const fadeIn  = keyframes`
    0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
`;

const StyledBox = styled.div`
    {
         background: #ECECEC;
         padding: 10px;
         margin-bottom: 2%;
         border-bottom: 5px solid #e52600;
         opacity:0;
         animation: ${fadeIn} 0.5s ease-in 0.2s forwards;
      
    }

    @media(min-width: 768px) {
      && {
        border-radius: 6px;
      }
  }
`;
export default StyledBox;