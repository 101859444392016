import styled from 'styled-components';
import {Collapse} from "antd";


export const StyledCollapse = styled(Collapse)`
  && {
         {  
            margin-top: 8%;
            box-shadow: 1px 1px 6px 2px #ccc;
         }
       
         @media (min-width: 1000px) and (max-width: 1263px) {
             margin-top: 9%;
             box-shadow: 1px 1px 6px 2px #ccc;
         }
         
         @media (min-width: 801px) and (max-width: 999px) {
             margin-top: 17%;
             box-shadow: 1px 1px 6px 2px #ccc;
         }              
         
         @media (min-width: 576px) and (max-width: 800px) {
             margin-top: 18%;
             box-shadow: 1px 1px 6px 2px #ccc;
         }              
    }
`;

export default StyledCollapse;