import apiConfig from '../config/ApiConfig';
import { getHttpClient } from '../config/HttpClientConfig'
import authService from "./AuthService";

/**
 * Fetches the given customer
 * @param {string} cid the customers cid 
 * @param creditInstitution the selected credit institution
 * @returns {*} the xhr promise
 */
const getCustomerByCid = (cid, creditInstitution) => {
  return getHttpClient().get(apiConfig.getCustomersResourceUrl(cid),{
    params: {
      creditInstitution: creditInstitution,
    }
  });
}

/**
 * Fetches the requested type static data
 * @param {string} dataType
 * @param {string} order
 * @returns {*} the xhr promise
 */
const getStaticData = (dataType, order) => {
  let staticData = JSON.parse(sessionStorage.getItem("staticData"));
  if (!staticData) {
    return getHttpClient().get(apiConfig.getStaticDataUrl(dataType), {
      params: {
        order: order
      }
    }).then(result => {
      if (!result.data.error) {
        sessionStorage.setItem("staticData", JSON.stringify(result));
        return new Promise(function(resolve, _reject) {
          resolve(result);
        });
      }
    });
  } else {
    return new Promise(function(resolve, _reject) {
      resolve(staticData);
    });
  }
}

/**
 * Fetches the requested type static data
 * @returns {*} the xhr promise
 * @param enumType
 */
const getEnumerations = (enumType) => {
  let enums = JSON.parse(sessionStorage.getItem("enums"));
  if (!enums) {
  return getHttpClient().get(apiConfig.getEnumerationsUrl(enumType)).then(result => {
    sessionStorage.setItem("enums", JSON.stringify(result));
    return new Promise(function(resolve, _reject) {
      resolve(result);
    });
  });
} else {
  return new Promise(function(resolve, _reject) {
    resolve(enums);
  });
}
}

/**
 * Fetches all available branches
 * @returns {*} the xhr promise
 */
const getAvailableBranches = () => {
  let branches = JSON.parse(sessionStorage.getItem("branches"));
  if (!branches) {
    return getHttpClient().get(apiConfig.getAvailableBranchesUrl()).then(result => {
      sessionStorage.setItem("branches", JSON.stringify(result));
      return new Promise(function(resolve, _reject) {
        resolve(result);
      });
    });
  } else {
    return new Promise(function(resolve, _reject) {
      resolve(branches);
    });
  }
}

/**
 * Fetches an access token for income tool
 * @returns {*} the xhr promise
 */
const getIncomeToolToken = (cid, creditInstitution, pools) => {
  return getHttpClient().get(apiConfig.getIncomeToolTokenUrl(cid, creditInstitution, pools));
}

/**
 * Given a customers cid, fetch the result of income tool
 * @param {string} cid
 * @returns {*} the xhr promise
 */
const getIncomeToolResult = (cid, creditInstitution) => {
  return getHttpClient().get(apiConfig.getIncomeToolResultUrl(cid, creditInstitution));
}

/**
 * Create Drive Case
 */
const createDriveCase = (data) => {
  return getHttpClient().post(apiConfig.postCreateCaseUrl(), data);

}

/**
 * Fetches details for a specific Case
 * @returns {*} the xhr promise
 */
const getCaseDetails = (caseId, creditInstitution) => {
    return getHttpClient().get(apiConfig.getCaseDetailsUrl(caseId), {
      params: {
        creditInstitution: creditInstitution
      }
    });
}

/**
 * Withdraw Drive Case
 */

const withdrawCase = (data, creditInstitution) => {
  return getHttpClient().put(apiConfig.putWithdrawCaseUrl(), data, {
    params: {
      creditInstitution: creditInstitution
    }
  });
}

/**
 * Fetches search results for a specific case ID
 * @returns {*} the xhr promise
 */

const getCases = (caseId, cif, idCode, idNumber, dateFrom, dateTo, status, name, surname, fatherName, agencyCode, creditInstitution) => {
  return getHttpClient().get(apiConfig.getCasesUrl(), {
    params: {
    ...(caseId ? {caseId: caseId} : {}),
    ...(cif ? {cif: cif} : {}),
    ...(idCode ? {idCode: idCode} : {}),
    ...(idNumber ? {idNumber: idNumber} : {}),
    ...(dateFrom ? {dateFrom: dateFrom} : {}),
    ...(dateTo ? {dateTo: dateTo} : {}),
    ...(status ? {status: status} : {}),
    ...(name ? {name: name} : {}),
    ...(surname ? {surname: surname} : {}),
    ...(fatherName ? {fatherName: fatherName} : {}),
    ...(agencyCode ? {agencyCode: agencyCode} : {}),
      creditInstitution: creditInstitution
    }
  });
};

const getCaseByCaseId = (caseId, creditInstitution) => {
  return getCases(caseId, null, null, null, null, null, null, null, null, null, null, creditInstitution);
}

/**
 * Fetches the Credit Institutions of the user
 * @returns {*}
 */
const getUserInfo = () => {
  let userInfo = authService.getUserInfo();
  let creditInstitutions = userInfo.creditInstitutions;//JSON.parse(sessionStorage.getItem("creditInstitutions"));
  if (!creditInstitutions) {
    return getHttpClient().get(apiConfig.getUserInfoUrl()).then(result => {
      sessionStorage.setItem("creditInstitutions", JSON.stringify(result));
      return new Promise(function(resolve, _reject) {
        resolve(result);
      });
    });
  } else {
    return new Promise(function(resolve, _reject) {
      resolve(creditInstitutions);
    });
  }
};

/**
 * Fetches search results for a specific case ID
 * @returns {*} the xhr promise
 */
const getDocuments = (caseId, creditInstitution) => {
  return getHttpClient().get(apiConfig.getDocumentsUrl(caseId), {
    params: {
      ...(caseId ? {caseId: caseId} : {}),
      creditInstitution: creditInstitution
    }
  });
}

let dataProvider = {
  getCustomerByCid,
  getStaticData,
  getEnumerations,
  getAvailableBranches,
  getIncomeToolToken,
  getIncomeToolResult,
  createDriveCase,
  getCaseDetails,
  getCases,
  getCaseByCaseId,
  getDocuments,
  getUserInfo,
  withdrawCase
}

export default dataProvider;
