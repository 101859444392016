import SearchBox from '../SearchBox';
import styled from 'styled-components';

export const CaseWizardSearchBox = styled(SearchBox)`
  
    padding-top: 10%;
    text-align: center;
    position: relative;
    
    & > p {
        font-size: 1.2rem;
        line-height: 1.30769;
        font-weight: 700;
        margin-bottom: 20px;
        color:#4A4A4A;
    } 

    & > Input {
            width: 110px;
            height: 50px;
            margin-left: 5%;
            margin-right: 5%;
            border-radius: 40px;
            text-align: left;
            opacity: 1;
            background: white;
            color: rgba(0, 0, 0, 0.65);
            transition: all 1s;
            &:hover {
                cursor: pointer;
            };
            &:focus {
                @media (min-width: 1024px) {
                    width: 30%;
                }
                width: 50%;
                opacity: 1;
            }
    }
}
`;