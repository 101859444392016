import styled from 'styled-components';
import { Layout } from 'antd';

const {
    Content
  } = Layout;

export const StyledLayout = styled(Layout)`
&& {
  min-height: 100vh;
  background: #fff;
}
`;

export const StyledContent = styled(Content)`
&& {
  background: #fff; 
  width: 100%;
}
`;