import styled, {keyframes} from "styled-components";
import {Table} from "antd";

const fadeIn  = keyframes`
    0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
`;

const StyledTable = styled(Table) `
    {
         opacity:0;
         margin: 6px;
         animation: ${fadeIn} 0.5s ease-in 0.2s forwards;
         
         & thread {
        backgroundColor: darkblue;
        color:#4A4A4A;
        margin-bottom:10px;
        }
    }
`;

export default StyledTable;