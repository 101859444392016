import elGR from 'antd/lib/locale-provider/el_GR';
import enUS from 'antd/lib/locale-provider/en_US';

export const locale = () => {
    switch (getBrowserLocale()) {
        case 'el':
            return elGR;
        default:
            return enUS;
    }
};

export const getBrowserLocale = () => {
    return (navigator.languages && navigator.languages[0]) || navigator.language ||navigator.userLanguage;
}

export const displayDateTimeFormat = 'DD/MM/YYYY HH:mm:ss';
export const displayDateFormat = 'DD/MM/YYYY';
export const serverDateFormat = "YYYY-MM-DDT00:00:00.000[Z]";
export const serverDateTimeFormat = "YYYY-MM-DDTHH:mm:ss.SSS[Z]";

export const defaultLoanReason = "2";
export const addonValue = "+30";
export const createCaseAction = "CREATE_CASE_FROM_EXTERNAL_APP";
export const withdrawCaseAction = "WITHDRAW_CASE_FROM_EXTERNAL_APP";
export const categoryType = "RESTRUCTURING";
export const assetGroup = "CL";
export const originBranchCodeOfExternalFPS = "V053";
export const originCodeOfFPS = "0600";
export const subCategoryOfCase = "DEFAULT";

export const CL_RESCHEDULING_INDIVIDUAL_SOLUTION_CODE = "122";
export const CL_RESCHEDULING_PRIVILEGED_PERIOD_CODE = "123";
export const CL_RESCHEDULING_SINGLE_INSTALLMENT_INTEREST_CODE = "124";
export const CL_RESCHEDULING_WITH_INTEREST_PAYMENT_CODE = "125";
export const CL_RESCHEDULING_CRO_CODE = "126";
export const CL_RESCHEDULING_CRO_WITH_DF_CODE = "127";
export const CL_RESCHEDULING_DEBT_RELIEF_WITHOUT_POTENTIAL_GIVE_AWAY_CODE = "128";

export const idTypeExcluded = "T";
