import React from 'react';
import StyledSpinner from '../components/styled-components/StyledSpinner';
import PropTypes from 'prop-types';
import {Icon} from 'antd';

const LoadingSpinner = (props) => {

    return (
        <StyledSpinner size={props.size} spinning={props.spinning} tip={props.waitMessage}>
            {props.children}
        </StyledSpinner>
    )
};

const spinIcon = (iconSpinType) => {
    switch (iconSpinType) {
        case "reload":
            return (<Icon type={"reload"} style={{fontSize: 80}} spin/>);
        case "sync":
            return (<Icon type={"sync"} style={{fontSize: 80}} spin/>);
        default:
            return (<Icon type={"loading"} style={{fontSize: 80}} spin/>);
    }
};

LoadingSpinner.propTypes = {
    size: PropTypes.string.isRequired,
    waitMessage: PropTypes.string.isRequired,
    children: PropTypes.element.isRequired,
    iconSpinType: PropTypes.string.isRequired
};

export default LoadingSpinner;