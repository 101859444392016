import React, { Component } from 'react';
import { Col, Card, Tooltip, Button } from 'antd';
import { getDisplayValue, applyDateFormat, mapDriveState } from "../../helpers/InputHelper";
import StyledButton from "../../components/styled-components/controls/StyledButton";
import { fields } from "../../config/fields_el";
import MediaQuery from "react-responsive";
import StyledTable from '../../components/styled-components/StyledTable';
import {renderDescriptions} from '../../helpers/InputHelper';
import { displayDateTimeFormat} from "../../config/Properties";
import appAuth from "../../services/AuthService";

class CaseListStep extends Component {

    createDescriptionItems(caseDetails) {
        let descriptionItems = [
            { titleOfField: fields.case_id, fieldValue: getDisplayValue(caseDetails.caseId) },
            { titleOfField: fields.status, fieldValue: mapDriveState(caseDetails.state)},
            { titleOfField: fields.cif, fieldValue: getDisplayValue(caseDetails.customerId) },
            { titleOfField: fields.name_and_surname, fieldValue: getDisplayValue(caseDetails.firstName) + ' ' + getDisplayValue(caseDetails.surname) },
            { titleOfField: fields.father_name, fieldValue: getDisplayValue(caseDetails.fatherName) },
            { titleOfField: fields.input_date, fieldValue: getDisplayValue(caseDetails.creationDate) },

        ];
        return descriptionItems;
    }

    renderCardContents(aCase) {
        return (
            <div>
                <MediaQuery maxDeviceWidth={765}>
                    {renderDescriptions(this.createDescriptionItems(aCase), "vertical", 1)}
                    <Col span={12}>
                        <StyledButton type="primary" icon="eye" onClick={e => { this.props.action(aCase.caseId) }} />
                    </Col>
                    <Col span={12}>
                        <StyledButton type="danger" icon="close" onClick={e => { this.props.prepareModal(aCase) }} />
                    </Col>
                </MediaQuery>
                <MediaQuery minDeviceWidth={766}>
                    <Col span={20}>
                        {renderDescriptions(this.createDescriptionItems(aCase), "vertical", 3)}
                    </Col>
                    <Col span={4}>
                        <StyledButton type="primary" icon="eye" onClick={e => { this.props.action(aCase.caseId) }} />
                        <StyledButton type="danger" icon="close" onClick={e => { this.props.prepareModal(aCase) }} />
                    </Col>
                </MediaQuery>
            </div>
        )
    };

    renderCards(cases) {
        return cases.map((aCase) => {
            return (
                <Card key={aCase.caseId} bordered={true} style={{
                    backgroundColor: "#fbfbfb",
                    marginTop: "7px",
                }}>
                    {this.renderCardContents(aCase)}
                </Card>
            )
        })
    }

    casesColumns = () => {

        return [
            {
                title: fields.case_id, dataIndex: 'caseId', key: 'caseId',
                sorter: (a, b) => a.caseId - b.caseId
            },
            {
                title: fields.status, key: 'status',
                render: (row) => (
                    <span>
                        {mapDriveState(row.state)}
                    </span>
                ),
                sorter: (a, b) => { return (a.state).localeCompare(b.state) }
            },
            {
                title: fields.input_date, dataIndex: 'creationDate', key: 'creationDate',
                render: creationDate => applyDateFormat(creationDate, displayDateTimeFormat),
                sorter: (a, b) => {return new Date(a.creationDate).getTime() - new Date(b.creationDate).getTime()},
            },
            {
                title: fields.cif, dataIndex: 'customerId', key: 'customerId',
                sorter: (a, b) => {return a.customerId.localeCompare(b.customerId)}
            },
            {
                title: fields.surname, dataIndex: 'surname', key: 'surname',
                sorter: (a, b) => {return a.surname.localeCompare(b.surname)}
            },
            {
                title: fields.name, dataIndex: 'firstName', key: 'firstName',
                sorter: (a, b) => {return a.firstName.localeCompare(b.firstName)}
            },
            {
                title: fields.father_name, dataIndex: 'fatherName', key: 'fatherName',
                sorter: (a, b) => { return a.fatherName.localeCompare(b.fatherName)}
            },
            {
                title: fields.actions,
                key: 'action',
                render: (row) => (

                    <span>
                        <Tooltip placement="top" title={fields.caseDetails}>
                            <Button type="primary" icon="eye" onClick={e => {
                                this.props.action(row.caseId)
                            }} />
                        </Tooltip>
                        {appAuth.hasRole(this.props.userInfo, 'ROLE_USER') ? (
                            <Tooltip placement="top" title={fields.cancelCase}>
                                <Button type="danger" icon="close" disabled={row.withdrawalDate || row.rejectionDate}
                                        style={{ position: 'absolute', display:'disabled', marginLeft: '10px'}}
                                        onClick={e => { this.props.prepareModal(row) }} />
                            </Tooltip>
                        ) : ""}
                    </span>
                ),
            },
        ]
    };

    render() {
        return (
            <div>
                <MediaQuery minDeviceWidth={1161}>
                    <StyledTable bordered columns={this.casesColumns()} dataSource={this.props.data} pagination={{ pageSize: 5 }} />
                </MediaQuery>
                <MediaQuery maxDeviceWidth={1160}>
                    {this.renderCards(this.props.data)}
                </MediaQuery>
            </div>

        )
    }
}


export default CaseListStep;

