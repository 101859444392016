import styled from 'styled-components';
import { Form } from 'antd';

const StyledPartialForm = styled(Form)`
   background: rgb(249, 249, 249, 0.63);
   border-radius: 6px;
   width: auto;
   overflow:hidden;
   
   @media(min-width: 769px) {
        border: 1px solid rgb(230, 230, 230);
        box-shadow: 1px 3px 6px 2px #ccc;
           }                    
`;

export default StyledPartialForm;