import React from 'react';
import StyledFooter from '../styled-components/layout/StyledFooter';

const AppFooter = (props) => {
  return (
      <StyledFooter>
        ©2019 Created by Eurobank
      </StyledFooter>
    )
};

export default AppFooter;
