import React from 'react';
import {Route} from 'react-router-dom';
import authService from '../services/AuthService';
import {getCancelTokenSource} from '../config/HttpClientConfig'
import { Redirect } from 'react-router-dom';
import Forbidden from '../pages/common/Forbidden';
import apiConfig from '../config/ApiConfig';
import Landing from '../pages/common/Landing';
import { StyledPageWithMargin } from './styled-components/layout/StyledPageWithMargin';
import LoadingSpinner from './LoadingSpinner';
import { messages } from '../config/messages_el';


class SecuredRoute extends React.Component {
  constructor (props) {
    super(props);
    authService.setupAuth();
    this.cancelTokenSource = null;
    this.state = {
      loggedIn: authService.isLoggedIn(),
      expired: authService.isExpired(),
      userInfo: authService.getUserInfo(),
      refreshExpired: false,
      creditInstitution : sessionStorage.getItem('creditInstitution') 
    }
  }

  updateCreditInstitution = (creditInstitution) => {
    sessionStorage.setItem('creditInstitution', creditInstitution);
    this.setState({creditInstitution: creditInstitution});
  }

  componentDidMount() {
    this.cancelTokenSource = getCancelTokenSource();
    if (this.state.loggedIn && this.state.expired) {
      authService.refreshToken(this.cancelTokenSource).then(() => {
        this.setState({ expired: false });
      }).catch(error => {
        console.log(error);
        if (error.response && 400 === error.response.status) {
          console.log("Refresh token invalid")
          this.setState({refreshExpired: true});
        }
      });
      console.log("Expired access token");
    } else if (this.state.loggedIn === false) {
      console.log("Not logged in");
      window.location = apiConfig.getAuthEndpointUrl(window.location);
    }
  }

  componentWillUnmount() {
    this.cancelTokenSource.cancel('Component unmounted');
  }

  render() {
    const { component: Component, ...rest } = this.props
    if (this.state.refreshExpired) 
      return <Redirect to={'/logout'}/>
    if (this.state.loggedIn) {
      if (this.state.expired) {
        return null;
      }
      if (authService.canAccess(this.state.userInfo, this.props.componentName)) {
        return (<Route {...rest} render={() => {
          return (
            this.state.creditInstitution ? <Component {...rest}
                                                      creditInstitution = {this.state.creditInstitution ? this.state.creditInstitution.toUpperCase() : null}
                                                      userInfo={this.state.userInfo} isExpired={this.state.expired}/> :
            <Landing {...rest} updateCreditInstitution={this.updateCreditInstitution} userInfo={this.state.userInfo} isExpired={this.state.expired}/>
          )
        }}
        />)
      } else {
        return (
          <Forbidden />
        )
      }
    } else {
      return (
      <LoadingSpinner iconSpinType='' size='large' spinning={this.state.loading} waitMessage={messages.wait_message}>
          <div style={{height: 'calc(100vh)', width: '100%', display: 'flex', justifyContent: 'center',  alignItems: 'center'}}/>
      </LoadingSpinner>
      )
    }
  }

}

export default SecuredRoute;