import React from 'react';
import { StyledAppTitle, StyledAppWelcome } from '../../components/styled-components/layout/StyledLandingPage'
import {fields} from '../../config/fields_el'
import { Row, Col } from 'antd'
import { PageWithBackgroundImage } from '../../components/styled-components/layout/PageWithBackgroundImage';
import { messages } from '../../config/messages_el';


const Error = (props) => {
  return (
    <PageWithBackgroundImage>
      <Row type="flex" justify="center">
        <Col span={24}>
          <StyledAppTitle>{fields.app_name}</StyledAppTitle>
        </Col>
      </Row>
      <div>
        <StyledAppWelcome>{messages.generic_error_message}</StyledAppWelcome>
      </div>
    </PageWithBackgroundImage>
  );
}

export default Error;
