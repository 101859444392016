import React, { Component } from 'react';
import { Tag, Icon, Card } from 'antd';
import { fields } from "../../config/fields_el";
import StyledTable from "../../components/styled-components/StyledTable"
import MediaQuery from 'react-responsive';
import { renderDescriptions } from "../../helpers/InputHelper"

class Documents extends Component {

    createDescriptionItems = (document) => {
        let documentItems = [
            { titleOfField: fields.case_id_short, fieldValue: document ? this.mapDriveCaseAndRequestId(document) : null },
            { titleOfField: fields.type, fieldValue: document ? document.documentType : null },
            { titleOfField: fields.document_relation, fieldValue: document ? document.documentRelations : null },
            { titleOfField: fields.document_state, fieldValue: document ? this.mapDocumentState(document) : null },
            { titleOfField: fields.comments, fieldValue: document ? document.comments : null },

        ];
        return documentItems;
    }

    documentColumns = () => {
        return [
            {
                title: fields.case_id_short,
                key: (row) => row.id,
                render: (row) => (<span> {this.mapDriveCaseAndRequestId(row)} </span>)
            },
            { title: fields.type, dataIndex: 'documentType', key: 'documentType' },
            { title: fields.document_relation, dataIndex: 'documentRelations', key: 'documentRelations' },
            {
                title: fields.document_state,
                key: 'hasDocumentumId',
                render: (row) => (<span> {this.mapDocumentState(row)} </span>)
            },
            { title: fields.comments, dataIndex: 'comments', key: 'comments' },
        ]
    };

    mapIconForBooleanValue(row) {
        if (row.actualFolderDelivery) {
            return <Icon type='check' />
        } else if (row.finalPostponement) {
            return <Icon type='line' />
        }
    }

    mapDriveCaseAndRequestId(row) {
        if (row.requestId) {
            return row.caseId + '/' + row.requestId;
        } else {
            return row.caseId;
        }
    }

    mapDocumentState(row) {
        let color = '#87d068';
        let indication = fields.pending;

        if (row.finalPostponement) {
            color = 'blue';
            indication = fields.final_postponement;
        } else if (row.hasDocumentumId) {
            color = 'green';
            indication = fields.digital_document;
        } else {
            color = 'red';
            indication = fields.pending;
        }

        return (
            <Tag color={color} key={row.id}>
                {indication}
            </Tag>
        );
    }

    renderCards(documents) {
        return documents.map((document) => {
            return (
                <Card key={document.key} bordered={false} style={{
                    backgroundColor: "#fbfbfb",
                    marginTop: "7px",
                }}>
                    {renderDescriptions(this.createDescriptionItems(document), "vertical", 1)}
                </Card>
            )
        })
    }

    render() {
        return (
            <div>
                <MediaQuery minDeviceWidth={1025}>
                    <StyledTable bordered columns={this.documentColumns()} dataSource={this.props.data} pagination={{ pageSize: 15 }} />
                </MediaQuery>
                <MediaQuery maxDeviceWidth={1024}>
                    {this.renderCards(this.props.data)}
                </MediaQuery>
            </div>
        )
    }
}

export default Documents;