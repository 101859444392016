import React, {useState} from 'react';
import { fields } from '../config/fields_el';
import { Input } from 'antd';
import PropTypes from 'prop-types';
import { StyledSearchButton } from './styled-components/controls/StyledSearchButton';


const SearchBox = (props) => {
    const [input, setInput] = useState('');
    return (
        <div className={props.className}>
            <p>{props.title}</p>
            <Input onChange={(e) => {setInput(e.target.value); props.handleChange && props.handleChange(e)}}
                placeholder={fields.search + '..'}
                onPressEnter={() => props.action(input)}/>
            <StyledSearchButton disabled={props.disabled ? props.disabled : false} shape="circle" icon="search" onClick={() => props.action(input)} />
        </div>
    )
}
SearchBox.propTypes = {
    title: PropTypes.string.isRequired,
    handleChange: PropTypes.func,
    action: PropTypes.func.isRequired
}

export default SearchBox;