import React, { Component } from 'react';
import { Redirect } from 'react-router';
import AppPage from '../components/layout/AppPage';
import { StyledPageWithMargin } from '../components/styled-components/layout/StyledPageWithMargin';
import SearchCases from "./history_steps/SearchCases";
import CaseDetailsStep from "./history_steps/CaseDetailsStep";
import Documents from "./history_steps/Documents"
import { Modal, Input } from 'antd';
import dataProvider from "../services/DataProvider";
import { displayDateFormat, serverDateFormat } from "../config/Properties";
import { messages } from "../config/messages_el";
import LoadingSpinner from "../components/LoadingSpinner";
import { showNotification } from "../helpers/NotificationHelper";
import moment from 'moment';
import { isMoment } from 'moment';
import { fields } from "../config/fields_el";
import CaseListStep from './history_steps/CaseListStep';
import {applyDateFormat, createTag, mapStates, pad, getIdStaticDataNameFromCode, getNameFromCode, prepareRequestData} from "../helpers/InputHelper";
import DisplayBox from "../components/layout/DisplayBox";
import { withdrawCaseAction} from "../config/Properties";
import appAuth from "../services/AuthService";

const { TextArea } = Input;

const formItemLayout = {
    labelCol: {
        lg: {//desktop-laptop
            span: 10, offset: 0
        },
        md: {//tablet
            span: 24, offset: 0
        },
        sm: {//mobile
            span: 24, offset: 0
        },
        xs: {//mobile
            span: 24, offset: 0
        }
    },
    wrapperCol: {
        lg: {
            span: 12, offset: 0
        },
        md: {
            span: 24, offset: 0
        },
        sm: {
            span: 24, offset: 0
        },
        xs: {
            span: 24, offset: 0
        }
    },

};

class History extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current: props.location.pathname === '/' ? '' : props.location.pathname.substr(1),
            breadcrumb: 0,
            withdrawReason: '',
            caseIdToWithdraw: null,
            cases: null,
            case: null,
            loading: false,
            criteria: this.initDefaultCriteria(),
            documents: null,
            resultCounter: 0,
            activeGroup: "basicSearch",
            drawerVisible: false,
            idCodes: null,
            requestStatuses: this.initRequestStatuses(),
            modalVisible: false,
            dateRangeUsed: true
        };
        this.scrollRef = React.createRef();
    };

    initSearchState = () => {
        this.setState({criteria: this.initDefaultCriteria(), requestStatuses: this.initRequestStatuses(), activeGroup: "basicSearch", dateRangeUsed: true});
    }

    initRequestStatuses = () => {
        let requestStatuses = {};
        requestStatuses.REJECTED = fields.rejection;
        requestStatuses.COMPLETED = fields.completed;
        requestStatuses.APPROVED = fields.approved;
        requestStatuses.IN_PROGRESS = fields.in_progress;
        requestStatuses.WITHDRAWN = fields.withdrawn;
        return requestStatuses;
    }

    initDefaultCriteria = () => {
        let criteria = {};
        criteria.dateTo = moment(new Date());
        criteria.dateFrom = moment(new Date()).subtract(30, 'day');
        return criteria;
    };

    setActiveSearchGroup = (key) => {
        if (key !== "basicSearch") this.setState({dateRangeUsed: true});
        this.setState({ activeGroup: key });
        if (key === "personalIdSearch" && !this.state.idCodes) {
            dataProvider.getStaticData("IDENTIFICATION_TYPE", "asc").then(results => {
                this.setState({ idCodes: results.data.IDENTIFICATION_TYPE });
            }).catch(error => {
                //TODO: handle
            });
        }

    };

    setDateRange = (dateFrom, dateTo) => {
        let dTo = dateTo ? moment(dateTo, displayDateFormat) : null;
        let dFrom = dateFrom ? moment(dateFrom, displayDateFormat) : null;
        let criteria = this.state.criteria;
        criteria.dateFrom = dFrom;
        criteria.dateTo = dTo;
        console.log(criteria);
        this.setState({ criteria: criteria });
    };


    updateWithdrawReason = (value) => {
        this.setState({ withdrawReason: value });
    };

    prepareModal = (row) => {
        this.setState({ caseIdToWithdraw: row.caseId, modalVisible: true })
    };

    cancelPrepareModal = () => {
        this.setState({ caseIdToWithdraw: null, modalVisible: false, withdrawReason: null })
    };

    toggleSpinner = () => {
        this.state.loading ? this.setState({ loading: false }) : this.setState({ loading: true });
    };

    toggleDrawer = () => {
        this.state.drawerVisible ? this.setState({ drawerVisible: false }) : this.setState({ drawerVisible: true });
    };

    showModal = () => {
        this.state.modalVisible ? this.setState({ modalVisible: false }) : this.setState({ modalVisible: true });
    };

    getDocumentsByCase = (caseId, e) => {
        //  TODO: add functionality here 
        this.toggleSpinner();
        this.scrollRef.current.scrollIntoView({ behavior: 'smooth' });
        dataProvider.getDocuments(caseId, this.props.creditInstitution).then(result => {
            let numberOfResults = 0;
            result.data.forEach(d => { d.key = numberOfResults; numberOfResults++; });
            this.setState({ documents: result.data });
            this.scrollRef.current.scrollIntoView({ behavior: 'smooth' });
            this.toggleSpinner();
            this.props.history.push('/cases/results/details/documents');
        }).catch(error => {
            showNotification(messages.generic_error_message, messages.generic_error_title, 'error');
            this.toggleSpinner();
        });
    };

    searchCases = (e, form) => {
        e.preventDefault();
        let showError = true;
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                let criteria = {};
                criteria.dateFrom = applyDateFormat(this.state.criteria.dateFrom, serverDateFormat);
                criteria.dateTo = applyDateFormat(moment(this.state.criteria.dateTo), serverDateFormat);
                for (let key in values) {
                    let value = values[key];
                    if (value && showError) {
                        showError = false;
                    }
                    if (isMoment(value)) {
                        values[key] = value.format(displayDateFormat);
                    }
                }
                if (appAuth.hasRole(this.props.userInfo, 'ROLE_USER')) {
                    if (this.state.activeGroup === "basicSearch") {
                        criteria.caseId = values["caseId"];
                        if (!criteria.caseId) {
                            criteria.cif = values["cif"];
                        }
                    } else if (this.state.activeGroup === "nameSearch") {
                        criteria.name = values["name"];
                        criteria.surname = values["surname"];
                        criteria.fatherName = values["fatherName"];
                        if (!(criteria.name && criteria.surname && criteria.fatherName)) {
                            showNotification(messages.missing_name_criteria_values, messages.generic_error_title, 'error');
                            return;
                        }
                    } else if (this.state.activeGroup === "personalIdSearch") {

                        criteria.idCode = values["idCode"];
                        criteria.idNumber = values["idNumber"];

                        if (!(criteria.idCode && criteria.idNumber)) {
                            showNotification(messages.missing_id_criteria_values, messages.generic_error_title, 'error');
                            return;
                        }
                    } else if (this.state.activeGroup === "requestStatusSearch") {
                        criteria.status = values["requestStatus"];
                        if (!(criteria.status)) {
                            showNotification(messages.no_status_option_found, messages.generic_error_title, 'error');
                            return;
                        }
                    }
                } else {
                    criteria.agencyCode = values["agencyCode"] ? values["agencyCode"] : null;
                    if (this.state.activeGroup === "basicSearch") {
                        criteria.caseId = values["caseId"];
                        if (!criteria.caseId) {
                            criteria.cif = values["cif"];
                        }
                    } else if (this.state.activeGroup === "nameSearch") {
                        criteria.name = values["name"];
                        criteria.surname = values["surname"];
                        criteria.fatherName = values["fatherName"];
                        if (!(criteria.name && criteria.surname && criteria.fatherName)) {
                            showNotification(messages.missing_name_criteria_values, messages.generic_error_title, 'error');
                            return;
                        }
                    } else if (this.state.activeGroup === "personalIdSearch") {

                        criteria.idCode = values["idCode"];
                        criteria.idNumber = values["idNumber"];

                        if (!(criteria.idCode && criteria.idNumber)) {
                            showNotification(messages.missing_id_criteria_values, messages.generic_error_title, 'error');
                            return;
                        }
                    } else if (this.state.activeGroup === "requestStatusSearch") {
                        criteria.status = values["requestStatus"];
                        if (!(criteria.status)) {
                            showNotification(messages.no_status_option_found, messages.generic_error_title, 'error');
                            return;
                        }
                    }
                }

                this.toggleSpinner()
                if (criteria.caseId) {
                    criteria.dateFrom = null;
                    criteria.dateTo = null;
                } else {
                    criteria.dateTo = applyDateFormat(moment(criteria.dateTo, serverDateFormat).add(1, 'Days'), serverDateFormat);
                }
                dataProvider.getCases(
                    criteria.caseId, criteria.cif, criteria.idCode, criteria.idNumber, criteria.dateFrom, criteria.dateTo
                    , criteria.status, criteria.name, criteria.surname, criteria.fatherName, criteria.agencyCode,
                    this.props.creditInstitution ? this.props.creditInstitution : this.state.criteria.creditInstitution
                ).then(result => {
                    if (result.data.error) {
                        console.log("ERROR result " + result.data.error);
                        showNotification(messages.generic_error_message, messages.generic_error_title, 'error');
                        this.toggleSpinner();
                    } else {
                        let numberOfResults = 0;
                        result.data.forEach((d, i) => { d.key = i; numberOfResults++; });
                        this.setState({ cases: result.data, criteria: criteria, resultCounter: numberOfResults }); //loading: false + not toggleSpinner
                        this.scrollRef.current.scrollIntoView({ behavior: 'smooth' });
                        this.toggleSpinner();
                        this.props.history.push('/cases/results');
                    }
                }).catch(error => {
                    console.log("ERROR catch " + error);
                    if (error.response.status === 404) {
                        showNotification(messages.no_results_found_error_message, messages.no_results_found_error_title, 'warning');
                    } else if (error.response.status === 403) {
                        showNotification(messages.cannot_access_error_message, messages.no_results_found_error_title, 'warning');
                    } else {
                        showNotification(messages.generic_error_message, messages.generic_error_title, 'error');
                    }
                    this.toggleSpinner();
                });
            }
        });
    };

    renderCriteriaSection() {
        let tags = [];
        tags.push(createTag(0, '#1e4485', fields.credit_institution, this.props.creditInstitution ? this.props.creditInstitution : this.state.criteria.creditInstitution, false));
        if (this.state.dateRangeUsed)
            tags.push(createTag(1, '#1e4485', fields.interval, applyDateFormat(this.state.criteria.dateFrom, displayDateFormat) + ' - ' + applyDateFormat(this.state.criteria.dateTo, displayDateFormat), false));
        tags.push(createTag(2, '#1e4485', fields.case_id_short, this.state.criteria.caseId));
        tags.push(createTag(3, '#1e4485', fields.cif, this.state.criteria.cif ? pad(this.state.criteria.cif, 8) : null));
        tags.push(createTag(4, '#1e4485', fields.name, this.state.criteria.name ? (this.state.criteria.name + ' ' + this.state.criteria.surname + ' ' + this.state.criteria.fatherName) : null, false));
        tags.push(createTag(5, '#1e4485', fields.personal_identification_doc, this.state.criteria.idCode ? (getIdStaticDataNameFromCode(this.state.idCodes, this.state.criteria.idCode) + ' ' + this.state.criteria.idNumber) : null, false));
        tags.push(createTag(6, '#1e4485', fields.request_state, this.state.criteria.status ? mapStates(this.state.criteria.status).indication : null, false));
        tags.push(createTag(7, '#1e4485', fields.office, this.state.criteria.agencyCode ? getNameFromCode(this.props.userInfo.offices, this.state.criteria.agencyCode) : null, false))
        return tags;
    };

    getCaseDetailsByCaseId = (caseId) => {
        this.toggleSpinner();
        dataProvider.getCaseDetails(caseId, this.props.creditInstitution).then(result => {
            if (result.data.error) {
                console.log("DATA ERROR " + result);
                this.toggleSpinner();
            } else {
                this.setState({ case: result.data });
                this.scrollRef.current.scrollIntoView({ behavior: 'smooth' });
                this.toggleSpinner();
                this.props.history.push('/cases/results/details');
            }
        }).catch(error => {
            console.log("ERROR " + error);
            this.toggleSpinner();
        });

        this.scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    prepareData = (caseId, reason) => {
        return prepareRequestData(withdrawCaseAction, caseId, reason, this.props.creditInstitution, null, this.props.userInfo.email);
    };

    withdrawCaseByCaseId = (caseId, reason) => {
        this.toggleSpinner();
        let messageOfWithdrawalReason = messages.withdraw_case_by_user + ' ' + this.props.userInfo.name + ' - ' + fields.comments + ': [' + reason + ']';
        console.log(caseId, messageOfWithdrawalReason);
        this.scrollRef.current.scrollIntoView({ behavior: 'smooth' });
        let payload = this.prepareData(caseId, messageOfWithdrawalReason);
        console.log("Withdrawing: " + JSON.stringify(payload));
        dataProvider.withdrawCase(payload).then(result => {
            this.toggleSpinner();
            showNotification(messages.withdraw_case, messages.withdraw_case_title, 'success');
            this.scrollRef.current.scrollIntoView({ behavior: 'smooth' });
            this.showModal();
            dataProvider.getCaseByCaseId(
                caseId, this.props.creditInstitution ? this.props.creditInstitution : this.state.criteria.creditInstitution
            ).then(result => {
                if (result.data.error) {
                    console.log("ERROR result " + result.data.error);
                    showNotification(messages.generic_error_message, messages.generic_error_title, 'error');
                } else {
                    if (result.data.length === 1) {
                        let acase = result.data[0];
                        let cases = this.state.cases;
                        let ind = cases.findIndex(c => c.caseId === caseId);
                        cases.splice(ind, 1, acase);
                        this.setState({cases: cases});
                    }
                }
            }).catch(error => {
                console.log("ERROR catch " + error);
                if (error.response.status === 404) {
                    this.toggleSpinner();
                    showNotification(messages.no_results_found_error_message, messages.no_results_found_error_title, 'warning');
                } else if (error.response.status === 403) {
                    this.toggleSpinner();
                    showNotification(messages.cannot_access_error_message, messages.no_results_found_error_title, 'warning');
                } else {
                    this.toggleSpinner();
                    showNotification(messages.generic_error_message, messages.generic_error_title, 'error');
                }
                this.toggleSpinner();
            });
            }).catch(error => {
            if (error.response.status === 404) {
                this.toggleSpinner();
                showNotification(messages.withdrawal_not_found_error_message, messages.generic_error_title, 'warning');
            } else if (error.response.status === 403) {
                if (error.response.data.error.code === 'CASE_DECIDED') {
                    this.toggleSpinner();
                    showNotification(messages.already_decided_case_error_message, messages.generic_error_title, 'error');
                } else if (error.response.data.error.code === 'CASE_WITHDRAWN') {
                    this.toggleSpinner();
                    showNotification(messages.case_already_withdrawn_error_message, messages.generic_error_title, 'error');
                } else if (error.response.data.error.code === 'CASE_IN_EDIT_MODE') {
                    this.toggleSpinner();
                    showNotification(messages.case_in_edit_mode_error_message, messages.generic_error_title, 'error');
                } else if (error.response.data.error.code === 'CASE_WITHDRAWAL_NOT_ALLOWED') {
                    this.toggleSpinner();
                    showNotification(messages.case_status_withdrawal_not_allowed, messages.cannot_withdraw_case, 'error');
                } else {
                    this.toggleSpinner();
                    showNotification(messages.withdrawal_forbidden_error_message, messages.generic_error_title, 'error');
                }
            } else {
                this.toggleSpinner();
                showNotification(messages.generic_error_message, messages.generic_error_title, 'error');
            }
            this.showModal();
        });
    };

    useDateRange = (e) => {
        e.target.name === 'caseId' && e.target.value ? this.setState({dateRangeUsed: false}) : this.setState({dateRangeUsed: true});
    };

    renderSteps() {
        if (!this.props.computedMatch.params.results) {
            return (
                <SearchCases layout={formItemLayout} initSearch={this.initSearchState} update={this.searchCases}
                    dateFrom={this.state.criteria.dateFrom} dateTo={this.state.criteria.dateTo} setDateRange={this.setDateRange} idCodes={this.state.idCodes} requestStates={this.state.requestStatuses}
                    toggleDrawer={this.toggleDrawer} drawerVisible={this.state.drawerVisible} setActiveSearchGroup={this.setActiveSearchGroup}
                             userInfo={this.props.userInfo} useDateRange={this.useDateRange}/>
            );
        } else if (this.props.computedMatch.params.results === 'results' && this.props.computedMatch.params.details !== 'details' && this.state.cases) {
            return (
                <CaseListStep data={this.state.cases} resultCounter={this.state.resultCounter} back={this.previousStep} prepareModal={this.prepareModal} cancelPrepareModal={this.cancelPrepareModal}
                    action={this.getCaseDetailsByCaseId} userInfo={this.props.userInfo}/>
            );
        } else if (this.props.computedMatch.params.details === 'details' && this.props.computedMatch.params.results === 'results' && this.props.computedMatch.params.docs !== 'documents' && this.state.case) {
            return (
                <CaseDetailsStep action={this.getDocumentsByCase} data={this.state.case} back={this.previousStep} />
            );
        } else if (this.props.computedMatch.params.docs === 'documents' && this.props.computedMatch.params.results === 'results' && this.props.computedMatch.params.details === 'details' && this.state.documents) {
            return (
                <Documents case={this.state.case} back={this.previousStep} data={this.state.documents} />
            )
        } else {
            return <Redirect to='/cases'/>
        }
    };

render() {
    return (
        <AppPage {...this.props}>
            <StyledPageWithMargin {...this.props}>
                <div ref={this.scrollRef} />
                <DisplayBox header={fields.results_of_search}
                    statisticTitle={this.props.computedMatch.params.results === 'results' && this.props.computedMatch.params.details !== 'details' ? (this.state.resultCounter === 1 ? fields.found_singular : fields.found_plural) : null}
                    statisticResults={this.props.computedMatch.params.results === 'results' && this.props.computedMatch.params.details !== 'details' ? this.state.resultCounter : null}
                    suffix={this.props.computedMatch.params.results === 'results' && this.props.computedMatch.params.details !== 'details' ? (this.state.resultCounter === 1 ? fields.case_singular : fields.case_plural) : null}
                    detailInfo={fields.criteria}
                    iconType={this.state.resultCounter === 0 ? "minus-circle" : "check-circle"}
                >
                    {this.renderCriteriaSection()}
                </DisplayBox>
                <LoadingSpinner iconSpinType='' size='large' spinning={this.state.loading} waitMessage={messages.wait_message}>
                    {this.renderSteps()}
                </LoadingSpinner>
                <Modal
                    mask={true}
                    maskClosable={false}
                    title="Ακύρωση Αιτήματος"
                    visible={this.state.modalVisible}
                    onOk={e => {
                        this.withdrawCaseByCaseId(this.state.caseIdToWithdraw, this.state.withdrawReason)
                    }}
                    onCancel={e => { this.cancelPrepareModal() }}
                    destroyOnClose={true}
                    cancelText="Κλείσιμο">
                    <p>Παρακαλώ καταχωρήστε το λόγο ακύρωσης:</p>
                    <TextArea rows={2} onChange={e => this.updateWithdrawReason(e.target.value)} />
                </Modal>
            </StyledPageWithMargin>
        </AppPage>
    )
}
};

export default History;
