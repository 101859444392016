import styled from "styled-components";
import {Button} from "antd";

const StyledDateButton = styled(Button) `
    {
         transform: rotate(-90deg);
         margin-left: -35px;
         margin-top: 2%;
         background-image: linear-gradient(to right, #0C6DB3 , #e52600);
         color: white;
         font-weight: 500;
         border: 1px solid #ccc;
         box-shadow: 1px 1px 1px 1px #ccc;
         z-index: 999;
         font-size: 0.9rem;
         height: 45px;
         &:hover {
            cursor: pointer;
            background-image: linear-gradient(to right, #0C6DB3 , #e52600);
            color: white
            border: 1px solid #ccc;
         };
         
          &:focus {
            cursor: pointer;
            background-image: linear-gradient(to right, #0C6DB3 , #e52600);
            color: white
            border: 1px solid #ccc;
         };
         
         @media (max-width: 767px) {
            margin-bottom: 2%; 
            width: 100%;
            margin-left: 0px;
            height: 35px;
            transform: rotate(0deg);
            font-size: 0.7rem;
         }
         @media (min-width: 768px) {
            margin-top: 60px;
         }
    }
`;

export default StyledDateButton;