import { messages } from "../config/messages_el"
import moment from 'moment';


/**
 * Used internally in order to hide common checks during input validations
 * 
 * @param {boolean} required 
 * @param {string} input 
 * @param {*} regex 
 * @param {*} callback 
 * @param {string} validationMessage 
 */
const doValidateInput = (required, input, regex, callback, validationMessage) => {
    if (required && (!input || input.trim().length === 0)) {
        callback(messages.required);
    } else {
        if (!input || input.match(regex)) {
            callback();
        } else {
            callback(validationMessage);
        }
    }
}


/**
 * Checks whether an input is between min and max length provided
 * 
 * @param {string} input
 * @param {number} minLength 
 * @param {number} maxLength 
 * @returns {boolean}
 */
export const isWithinLengthLimits = (input, minLength, maxLength) => {
    if(!input || (!minLength && !maxLength)) {
        return false;
    } else {
        if(minLength) {
            if(maxLength) {
                return minLength <= input.length && input.length <= maxLength;
            } else {
                return minLength <= input.length;
            }
        } else {
            return input.length <= maxLength;
        }
    }
};

/**
 * Checks whether input contains only numeric characters or numeric characters plus one string character with '.', ',', '-' in between
 * @param rule
 * @param value
 * @param callback
 */
export const validateHomeAddressNumber = (rule, value, callback) => {
    let number = /(^[1-9]{1}[0-9α-ωΑ-Ω.\-\s]{0,7}$)|(^[-]+$)/;
    doValidateInput(rule.required, value, number, callback, messages.invalid_address_number)
};

/**
 * checks whether input contains one character plus six numeric or two characters plus six numeric
 * @param rule
 * @param value
 * @param callback
 */
export const validateIdNumber = (rule, value, callback) => {

    let idLengthSeven = /^[Α-ΩΑ-Ω][0-9]{6}$/;
    let idLengthEight = /^[Α-ΩΑ-Ω][Α-ΩΑ-Ω][0-9]{6}$/;
    if (rule.idType === 'A' || rule.spouseInfoPersonalDocType === 'A') {
        if(value.length === 7) {
            doValidateInput(rule.required, value, idLengthSeven, callback, messages.invalid_id);
        } else if (value.length === 8 ) {
            doValidateInput(rule.required, value, idLengthEight, callback, messages.invalid_id);
        } else {
            callback(messages.invalid_id);
        }
    } else {
        callback();
    }
};

/**
 * Checks whether input provided contains only numeric characters
 * @param {string} input 
 * @returns {boolean}
 */
export const onlyDigits = (input) => {
    let numbers = /^[0-9]+$/;
    return input.match(numbers);
};

/**
 * Validator wrapper of onlyDigits
 * @param {*} rule 
 * @param {string} value 
 * @param {*} callback 
 */
export const validateOnlyDigits = (rule, value, callback) => {
    let numbers = /^[0-9]+$/;
    doValidateInput(rule.required, value, numbers, callback, messages.only_numbers_message);
};

/**
 * Validator wrapper of onlyDigits in specific range
 * @param rule
 * @param value
 * @param callback
 */
export const validateRangeOnlyDigits = (rule, value, callback) => {
    let numbers = /^[0-9]+$/;
    if (value >= 0 && value <= 100) {
        doValidateInput(rule.required, value, numbers, callback, messages.only_numbers_message);
    } else {
        callback(messages.invalid_value);
    }
};


/**
 * Validates a TIN number 
 * @param {*} rule 
 * @param {string} value 
 * @param {*} callback 
 */
export const validateTIN = (rule, value, callback) => {
    let mod1;
    let mod2;
    let m = 1, sum = 0;
    let customDigits = /^ΣΤ[0-9]{8}$/;
    let digits = /^[0-9]+$/;
    if (value) {
        if (value.match(customDigits)) {
            callback();
        } else if (!value.match(digits) || value.length !== 9 || value === "000000000") {
            callback(messages.invalidTin);
        } else {
            for (let i = 7; i >= 0; i--) {
                m *= 2;
                sum += value.charAt(i) * m;
                mod1 = sum % 11;
                mod2 = mod1 % 10;
            }
            if (mod2.toString() === value.charAt(8)) {
                callback();
            } else {
                callback(messages.invalidTin);
            }
        }
    } else {
        callback();
    }
};

/**
 * check the length and return callback message when value's length bigger than 200 words
 * @param rule
 * @param value
 * @param callback
 */
export const validateCommentLength = (rule, value, callback) => {
    if (value && value.length > 1500) {
        callback(messages.invalid_value_length);
    } else {
        callback();
    }
};

/**
 * Checks if input is a valid phone number checking whether attorney phone number match with home or occupation phone number
 * A valid phone number is a number that has length 10
 * @param {*} rule
 * @param {string} value 
 * @param {*} callback 
 */
export const isValidAttorneyPhoneNumber = (rule, value, callback) => {
    let phoneNumber = /(^\s*$|^\+?2[0-9]{2}-?\s?[0-9]{7}$)|(^\s*$|^\+?2[0-9]{4}-?\s?[0-9]{5}$)/;
    if (rule.contactPhone === value)  {
        callback(messages.catch_same_home_occupation_phone_number);
    } else if (rule.companyPhone && rule.companyPhone === value){
        callback(messages.catch_same_home_occupation_phone_number);
    } else {
        doValidateInput(rule.required, value, phoneNumber, callback, messages.invalid_pattern_message());
    }
};

/**
 * Checks if input is a valid phone number
 * A valid phone number is a number that has length 10
 * @param {*} rule
 * @param {string} value
 * @param {*} callback
 */
export const isValidHomePhoneNumber = (rule, value, callback) => {
    let phoneNumber = /(^\s*$|^\+?2[0-9]{2}-?\s?[0-9]{7}$)|(^\s*$|^\+?2[0-9]{4}-?\s?[0-9]{5}$)/;
    doValidateInput(rule.required, value, phoneNumber, callback, messages.invalid_pattern_message());
};


/**
 * Checks if input is a valid greek mobile number or a valid greek phone number checking match with home phone number
 * @param rule
 * @param value
 * @param callback
 */
export const isValidPhoneOrMobileNumber = (rule, value, callback) => {
    let mobileOrPhone =  /(^\s*$|^\+?2[0-9]{2}-?\s?[0-9]{7}$)|(^\s*$|^\+?2[0-9]{4}-?\s?[0-9]{5}$)|(^$|^69[0-9]-?\s?[0-9]{7}$)|(^$|^68[0-9]-?\s?[0-9]{7}$)/;

        if (rule.contactPhone === value) {
            callback(messages.catch_same_home_phone_number);
        } else {
            doValidateInput(rule.required, value, mobileOrPhone, callback, messages.invalid_pattern_message());
        }

};

/**
 * Checks if input is a valid zip code
 * A valid zip code is a number that has length 5
 * @param {*} rule
 * @param {string} value
 * @param {*} callback
 */
export const isValidZipCode = (rule, value, callback) => {
    let zipCode = /^([A-Z]{2})?-?\s?\d{5}$/;
    doValidateInput(rule.required, value, zipCode, callback, messages.invalid_pattern_message());
};

/**
 * Checks if input is a valid email
 * @param {*} rule
 * @param {string} value
 * @param {*} callback
 */
export const isValidEmail = (rule, value, callback) => {
    let email = /^$|^[_A-Za-z0-9-+]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$/;
    doValidateInput(rule.required, value, email, callback, messages.invalid_pattern_message_email("aaa@bbb.ccc"));
};

/**
 * Checks if input is valid a mobile number
 * @param {*} rule
 * @param {string} value
 * @param {*} callback
 */
export const isValidMobileNumber = (rule, value, callback) => {
    let mobileNumber = /(^$|^69[0-9]-?\s?[0-9]{7}$)|(^$|^68[0-9]-?\s?[0-9]{7}$)/;
    let mobileNumberType = /(^69)|(^68)/;
    if (!value.match(mobileNumberType)) {
        doValidateInput(rule.required, value, mobileNumberType, callback, messages.invalid_pattern_message("69... ή 68.."));
    }
    doValidateInput(rule.required, value, mobileNumber, callback, messages.invalid_mobile_value_length);
};

/**
 * Checks if input is a valid fax number
 * @param {*} rule
 * @param {string} value
 * @param {*} callback
 */
export const isValidFaxNumber = (rule, value, callback) => {
    let faxNumber = /(^\s*$|^\+?2[0-9]{2}-?\s?[0-9]{7}$)|(^\s*$|^\+?2[0-9]{4}-?\s?[0-9]{5}$)/;
    doValidateInput(rule.required, value, faxNumber, callback, messages.invalid_pattern_message());
};

/**
 * Checks if input contains only letters
 * @param {*} rule
 * @param {string} value
 * @param {*} callback
 */
export const validateOnlyLetters = (rule, value, callback) => {
    let letters = /^[A-Za-zΑ-Ωα-ω-άέήίόύώΆΈΉΊΌΎΏ]+$/;
    doValidateInput(rule.required, value, letters, callback, messages.only_letters_message);
};

export const validateConsensus = (_rule, value, callback) => {
    if (true !== value) {
        callback("You need to consent");
    } else {
        callback();
    }
};

/**
 * Checks if names are valid
 * A name cannot contain digits or special characters except from '-', '/' and a whitespace
 *
 * @param {*} rule
 * @param {string} value
 * @param {*} callback
 */
export const validateLettersDotsSlashesDashes = (rule, value, callback) => {
    let namePattern = /(^[\\/\-Α-Ωα-ωάέήίόύώΆΈΉΊΌΎΏ .]+$)|(^[\\/\-A-Za-z .]+$)/;
    doValidateInput(rule.required, value, namePattern, callback, messages.lettersDotsSlashesDashes);
};

/**
 * 
 * @param {*} rule 
 * @param {string} value 
 * @param {*} callback 
 */
export const validateLettersNumbersDotsSlashesDashes = (rule, value, callback) => {
    let pattern = /(^[\\/\-Α-Ωα-ωάέήίόύώΆΈΉΊΌΎΏ .0-9]+$)|(^[\\/\-A-Za-z .0-9]+$)/;
    doValidateInput(rule.required, value, pattern, callback, messages.lettersNumbersDotsSlashesDashes);
}

/**
 *
 * @param {*} rule
 * @param {string} value
 * @param {*} callback
 */
export const isFutureDate = (rule, value, callback) => {
    let current = moment(new Date());
    let interval = getTimeInterval(current, value);
    if(!value) {
        callback(messages.invalid_date);
    } else if (interval >= 0){
        callback(messages.future_date_selected_error_message);
    } else {
        callback();
    }
};

/**
 *
 * @param startTime
 * @param endTime
 * @returns {number}
 */
export const getTimeInterval = (startTime, endTime) => {
    let d1 = moment(new Date(startTime));
    let d2 = moment(new Date(endTime));
    return d2.diff(d1, 'days');
};

export const isValidBirthDate = (rule, value, callback) => {
    let current = moment(new Date());
    let interval = getTimeInterval(current, value);
    if(!value) {
        callback(messages.invalid_date);
    } else if (interval >= 0){
        callback(messages.future_date_selected_error_message);
    } else if (interval < -36500) {
        callback(messages.invalid_date);
    } else {
        callback();
    }
};


