import Cookies from 'js-cookie';
import axios from 'axios';
import apiConfig from './ApiConfig';
import authService from '../services/AuthService';
import { history } from '../helpers/BrowserHistory';


let isRefreshing = false;
let refreshSubscribers = [];

//Create axios client instance
let axiosInstance = axios.create(
  {
    headers: { 'Content-Type': 'application/json', 'X-XSRF-TOKEN': Cookies.get("XSRF-TOKEN") },
    withCredentials: true
  }
);

//Intercept response in order to catch 401 error and refresh token
axiosInstance.interceptors.response.use(response => {
  if (response.request.responseURL === apiConfig.getRefreshTokenUrl()) {
    let token = Cookies.get("AUTH");
    authService.setupAuth();
    console.log("received refresh, fulfilling promises");
    refreshSubscribers.map(cb => cb(token));
    refreshSubscribers = [];
    isRefreshing = false;
  }
  return response;
}, error => {
  console.log(error);
  //If error comes from refresh token URL logout
  if (error.response.request.responseURL === apiConfig.getRefreshTokenUrl()) {
    console.log(error.response.request.responseURL);
    console.log("Refresh token invalid")
    Promise.reject(error);
    history.push("/logout");
  }
  //If 401 comes from other URL intercept and try to refresh access token
  if (error.response && 401 === error.response.status) {
    console.log("401 error intercepted");
    const { config } = error;
    const originalRequest = config;
    if (!isRefreshing) {
      console.log("Trying to refresh token");
      axiosInstance.get(apiConfig.getRefreshTokenUrl())
    }
    const retryOrigReq = new Promise((resolve, reject) => {
      refreshSubscribers.push((token) => {
        //replace the expired token and retry original request
        originalRequest.headers['Authorization'] = 'Bearer ' + token;
        resolve(axios(originalRequest));
      });
    });
    return retryOrigReq;
  //If error is different than 401 or does not come from refresh URL reject promise and handle error in caller
  } else {
    return Promise.reject(error);
  }
});

//Intercept request towards refresh token endpoint in order to set isRefreshing 
//flag to true and avoid multiple refresh requests being fired
axiosInstance.interceptors.request.use(config => {
  if (isRefreshRequest(config)) {
    isRefreshing = true;
  }
  return { ...config };
}, error => {
  console.log(error);
});

/**
 * @returns {*} The created axios instance with its interceptors and auth headers with the current 
 * access token obtained from local storage
 */
export const getHttpClient = () => {
  //Setup common auth header
  axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + sessionStorage.getItem("bearer");
  return axiosInstance;
}
/**
 * Checks if current axios request is towards refresh endpoint
 * @param {*} request
 * @returns {boolean}
 */
const isRefreshRequest = (request) => {
  return "get" === request.method && apiConfig.getRefreshTokenUrl() === request.url;
}

/**
 * Creates a cancel token source that can be used in order to cancel pending xhr requests
 * @see {@link https://github.com/axios/axios#cancellation Axios request cancellation}
 */
export const getCancelTokenSource = () => {
  return axios.CancelToken.source();
}