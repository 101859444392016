import React, {Component} from 'react';
import {Form, Col} from 'antd';
import {fields} from '../../config/fields_el';
import StyledPartialForm from '../../components/styled-components/form/StyledPartialForm'
import { mapStaticDataToSelectItems, renderFormComponents } from "../../helpers/InputHelper";
import StyledFormItem from "../../components/styled-components/form/StyledFormItem";
import StyledButton from "../../components/styled-components/controls/StyledButton";
import {
    validateLettersDotsSlashesDashes,
    validateLettersNumbersDotsSlashesDashes,
    validateTIN,
    isValidZipCode,
    validateHomeAddressNumber, validateIdNumber, validateCommentLength
} from '../../validators/InputValidators';
import { messages } from '../../config/messages_el';

const requiredAttorneyFieldsInDPO = [
    'attorneySurname',
    'attorneyName',
    'attorneyProfession',
    'attorneyHomeAddressStreet',
    'attorneyHomeAddressNumber',
    'attorneyHomeAddressCity',
    'attorneyHomeAddressPostalCode'
];

let requiredAttorneyFieldsInDPOCopy;

class BranchInfoStep extends Component {

    constructor(props) {
        super(props);
        this.state = {
            hasAttorneyDetails: false
        }
    };

    handleAttorneyProfessionSelected = (e) => {
        const { form } = this.props;
        const attorneyInfoExist = this.attorneyInfoExists('attorneyProfession');
        this.setState({ hasAttorneyDetails: e ? true : attorneyInfoExist},
            () => {
                form.validateFieldsAndScroll(requiredAttorneyFieldsInDPO, { force: true })
            })
    };

    handleAttorneyDetailsChange = (e) => {
        const { form } = this.props;
        const attorneyInfoExist = this.attorneyInfoExists(e.target.name);
        this.setState({ hasAttorneyDetails: e.target.value ? true : attorneyInfoExist },
            () => {
                form.validateFieldsAndScroll(requiredAttorneyFieldsInDPO, {force: true})
            })
    };

    attorneyInfoExists = (currentField) => {
        requiredAttorneyFieldsInDPOCopy = [...requiredAttorneyFieldsInDPO];
        const index = requiredAttorneyFieldsInDPOCopy.indexOf(currentField);
        if (index !== -1) requiredAttorneyFieldsInDPOCopy.splice(index, 1);
        const { form } = this.props;
        const formValues = form.getFieldsValue(requiredAttorneyFieldsInDPOCopy);
        return Object.values(formValues).some(x => (x !== null && x !== '' && x !== undefined));
    };

    render() {
        const {
            getFieldDecorator,
        } = this.props.form;

        let spouseInfo1 = {layout: this.props.layout, inputs: [
            { fieldName: 'spouseInfoSurname', type: "input", label: fields.spouse_surname, rules: [{ required: this.props.isSpouseRequired, validator: validateLettersDotsSlashesDashes }], value: this.props.data.customerRelations ? this.props.data.customerRelations[0].surname : null, iconType: "user" },
            { fieldName: 'spouseInfoName', type: "input", label: fields.spouse_name, rules: [{ required: this.props.isSpouseRequired, validator: validateLettersDotsSlashesDashes }], value: this.props.data.customerRelations ? this.props.data.customerRelations[0].firstName : null, iconType: "user" },
            { fieldName: 'spouseInfoFamilyName', type: "input", label: fields.spouse_family_name, rules: [{ required: false, validator: validateLettersDotsSlashesDashes}], value: this.props.data.customerRelations ? this.props.data.customerRelations[0].familyName : null, iconType: "user" }
        ]};

        let spouseInfo2 = {layout: this.props.layout, inputs: [
            { fieldName: 'spouseInfoProfession', type: "select", label: fields.spouse_profession, rules: [{ required: this.props.isSpouseRequired, message: messages.required }], value: this.props.data.customerRelations ? this.props.data.customerRelations[0].occupation : null, selectOptions: this.props.data ? mapStaticDataToSelectItems(this.props.staticData['JOB_TYPE']) : null, showSearch: true },
            { fieldName: 'spouseInfoTaxNumber', type: "input", label: fields.spouse_tax_number, rules: [{ required: false, validator: validateTIN }], value: this.props.data.customerRelations ? this.props.data.customerRelations[0].taxNumber : null, iconType: "bars" },
            { fieldName: 'spouseInfoPersonalDocType', type: "select", label: fields.personal_document_type, rules: [{ required: false}], value: this.props.data.customerRelations ? this.props.data.customerRelations[0].idType : null, selectOptions: this.props.data ? mapStaticDataToSelectItems(this.props.staticData['IDENTIFICATION_TYPE']) : null, showSearch: true },
            { fieldName: 'spouseInfoPersonalDocNumber', type: "input", label: fields.personal_document_number, rules: [{validator: validateIdNumber, spouseInfoPersonalDocType: this.props.form.getFieldValue('spouseInfoPersonalDocType')}], value: this.props.data.customerRelations ? this.props.data.customerRelations[0].idNumber : null, iconType: "idcard" }
            //{ formItemLayout: formItemLayout, fieldName: 'spouseInfoWorkAddress', type: "input", label: fields.spouse_work_address, rules: [{ required: false}], value: this.props.data.customerRelations ? this.props.data.customerRelations[0].companyAddressStreet : null, iconType: "home" },
            //{ formItemLayout: formItemLayout, fieldName: 'spouseInfoNumber', type: "input", label: fields.number, rules: [{ required: false}], value: this.props.data.customerRelations ? this.props.data.customerRelations[0].companyAddressStreet : null, iconType: "home" },
            //{ formItemLayout: formItemLayout, fieldName: 'spouseInfoWorkPhone', type: "input", label: fields.spouse_work_phone, rules: [{ required: false}], iconType: "phone" },
        ]};

        let attorneyInfo1 = {layout: this.props.layout, inputs: [
            { fieldName: 'attorneySurname', type: "input", label: fields.surname, rules: [{ required: this.props.attorneyIsRequired || this.state.hasAttorneyDetails, validator: validateLettersDotsSlashesDashes }], iconType: "user", onChange: (e) => this.handleAttorneyDetailsChange(e) },
            { fieldName: 'attorneyName', type: "input", label: fields.name, rules: [{ required: this.props.attorneyIsRequired || this.state.hasAttorneyDetails , validator: validateLettersDotsSlashesDashes }], iconType: "user", onChange: (e) => this.handleAttorneyDetailsChange(e) },
            { fieldName: 'attorneyProfession', type: "select", label: fields.profession, rules: [{ required: this.props.attorneyIsRequired || this.state.hasAttorneyDetails, message: messages.required }], value: null, selectOptions: this.props.data ? mapStaticDataToSelectItems(this.props.staticData['JOB_TYPE']) : null, showSearch: true, onChange: (e) => this.handleAttorneyProfessionSelected(e) },
        ]};

        let attorneyInfo2 = {layout: this.props.layout, inputs: [
            { fieldName: 'attorneyHomeAddressStreet', type: "input", label: fields.home_address_street, rules: [{ required: this.props.attorneyIsRequired || this.state.hasAttorneyDetails, validator: validateLettersNumbersDotsSlashesDashes }], iconType: "home", onChange: (e) => this.handleAttorneyDetailsChange(e) },
            { fieldName: 'attorneyHomeAddressNumber', type: "input", label: fields.number, rules: [{ required: this.props.attorneyIsRequired || this.state.hasAttorneyDetails, validator: validateHomeAddressNumber }], iconType: "home", onChange: (e) => this.handleAttorneyDetailsChange(e) },
            { fieldName: 'attorneyHomeAddressCity', type: "input", label: fields.city, rules: [{ required: this.props.attorneyIsRequired || this.state.hasAttorneyDetails, validator: validateLettersDotsSlashesDashes }], iconType: "environment", onChange: (e) => this.handleAttorneyDetailsChange(e) },
            { fieldName: 'attorneyHomeAddressPostalCode', type: "input", label: fields.postal_code, rules: [{ required: this.props.attorneyIsRequired || this.state.hasAttorneyDetails, validator: isValidZipCode }], iconType: "bars", onChange: (e) => this.handleAttorneyDetailsChange(e) }
        ]};

        // let branchInfo = {layout: this.props.layout, inputs: [
        //     { fieldName: 'serviceBranchCode', type: "select", label: fields.branch_of_disbursement, rules: [{ required: true, message: messages.required }], value: this.props.data ? this.props.data.serviceBranchCode : null, selectOptions: this.props.data ? mapStaticDataToSelectItems(this.props.branches, true) : null, showSearch: true },
        //     { fieldName: 'sbbEvaluation', type: "select", label: fields.sbb_evaluation, rules: [{ required: false}], iconType: "audit", value: this.props.data ? this.props.data.sbbAssessment : null, selectOptions: this.props.data ? mapStaticDataToSelectItems(this.props.staticData['SBB_ASSESSMENT'], false) : null },
        //     { fieldName: 'mortgageEvaluation', type: "select", label: fields.mortgage_evaluation, rules: [{ required: false}], iconType: "audit", value: this.props.data ? this.props.data.hlbAssessment : null, selectOptions: this.props.data ? mapStaticDataToSelectItems(this.props.staticData['HLB_ASSESSMENT'], false) : null },
        //
        // ]};

        let genericColumnStyle = {sm: 24, md: 12, lg:12};
        //let singleColumnStyle = {sm: 24, md: {span: 13, offset:0}, lg:{span: 18, offset:1}};

        let customCommentsLayout = {
            labelCol: {
                lg: {//desktop-laptop
                    span: 4, offset: 0
                },
                md: {//tablet
                    span: 24, offset: 0
                },
                sm: {//mobile
                    span: 24, offset: 0
                },
                xs: {//mobile
                    span: 24, offset: 0
                }
            },
            wrapperCol: {
                lg: {
                    span: 16, offset: 0
                },
                md: {
                    span: 24, offset: 0
                },
                sm: {
                    span: 24, offset: 0
                },
                xs: {
                    span: 24, offset: 0
                }
            },

        };

        let additionalComments = {layout: customCommentsLayout, inputs: [
                { fieldName: 'comment', type: "textArea", label:fields.comments, rules: [{ validator: validateCommentLength}], value: this.props.data ? this.props.data.comment : null}
            ]};


        const prepareSectionsToRender = () => {
            let formInputs = {};
            let sections = [];

            if(this.props.isSpouseRequired) {
                sections.unshift( {
                    title: fields.spouse_info,
                    orientation: 'left',
                    type: 'board',
                    columns: [
                        {
                            styles: genericColumnStyle,
                            inputs: spouseInfo1
                        },
                        {
                            styles: genericColumnStyle,
                            inputs: spouseInfo2
                        },
                    ]
                });
            }

            sections.push({
                title: fields.attorney_info,
                orientation: 'left',
                type: 'userinfo',
                columns: [
                    {
                        styles: genericColumnStyle,
                        inputs: attorneyInfo1
                    },
                    {
                        styles: genericColumnStyle,
                        inputs: attorneyInfo2
                    }

                ]
            });

            sections.push({
                title: fields.comments,
                orientation: 'left',
                type: 'list',
                columns: [
                    {
                        styles: {sm: 24, md: 24, lg: 24},
                        inputs: additionalComments
                    }
                ]
            });

            formInputs.sections = sections;
            return formInputs.sections
        };

        return (
            <StyledPartialForm onSubmit={e => this.props.update(e, this.props.form, null)} layout='horizontal'>
                {renderFormComponents(prepareSectionsToRender(), getFieldDecorator)}
                <Col span={24}>
                <Col lg={{span: 3, offset:8}} md={{span: 5, offset:7}} sm={24} >
                    <StyledFormItem>
                        <StyledButton id="back-button" type="default" onClick={e => {
                            this.props.update(e, this.props.form, false)
                        }}>{fields.prev_button_lbl}</StyledButton>
                    </StyledFormItem>
                </Col>

                <Col lg={12} md={{span: 5, offset:1}} sm={24} >
                    <StyledFormItem>
                        <StyledButton type="primary" htmlType="submit">{fields.submit}</StyledButton>
                    </StyledFormItem>
                </Col>
                </Col>
            </StyledPartialForm>
        )
    }
}

const BranchInfoStepForm = Form.create({name: 'Branch Info'})(BranchInfoStep);

export default BranchInfoStepForm;