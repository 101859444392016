import { Button } from 'antd';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  background-color: ${props => determineColors(props.type).bc}
  color: ${props => determineColors(props.type).c}

  
  @media (max-width: 765px ) {
        width: 100%;
  }    
`

const determineColors = (type) => {
  switch (type) {
    case 'primary': return {bc: '#0C6DB3', c: 'white'}
    case 'danger': return {bc: '#e52600', c: 'white'}
    case 'warning': return {bc: '#e5c900', c: '#000000a6'}
    case 'link': return {bc: 'white', c: '#0C6DB3'}
    default: return {bc: 'inherit', c: 'inherit'}
  }

} 

export default StyledButton;
