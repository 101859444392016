import {Steps} from 'antd';
import styled from 'styled-components';

export const StyledSteps = styled(Steps)`
    && {
        padding: 2% 5% 2% 5%
    }

    && > .ant-steps-item-process .ant-steps-item-content .ant-steps-item-title {
        &:after {
            background-color: #fff;
        }
    }

    && > .ant-steps-item-wait .ant-steps-item-content .ant-steps-item-title {
        &:after {
            background-color: #fff;
        }
    }
`;
