import React, { Component } from 'react';
import AppPage from '../../components/layout/AppPage';
import { Link } from 'react-router-dom';
import { StyledLandingPage, LandingHistoryButton, LandingWizardButton, StyledAppTitle, StyledAppWelcome } from '../../components/styled-components/layout/StyledLandingPage';
import { Row, Col, Select } from 'antd';
import { fields } from '../../config/fields_el';
import appAuth from "../../services/AuthService";
import { renderOptionItemsCredit } from "../../helpers/InputHelper";


class Landing extends Component {

    render() {
        return (
            <AppPage {...this.props}>
                <StyledLandingPage>
                    <Row type="flex" justify="center">
                        <Col span={24}>
                            <StyledAppTitle>{fields.app_name}</StyledAppTitle>
                        </Col>
                    </Row>

                    {this.props.creditInstitution ? (
                        <div>

                            {appAuth.canAccess(this.props.userInfo, 'CaseWizard') ? (
                                <StyledAppWelcome>{fields.app_descr_upper_line}</StyledAppWelcome>
                            ) : (
                                <StyledAppWelcome>{fields.app_descr_liaison}</StyledAppWelcome>
                            )}

                            {appAuth.canAccess(this.props.userInfo, 'CaseWizard') ? (
                                <StyledAppWelcome>{fields.app_descr_lower_line}</StyledAppWelcome>
                            ) : ("")}

                            <Row type="flex" justify="center">

                                {appAuth.canAccess(this.props.userInfo, 'CaseWizard') ? <Col xs={24} lg={{ span: 4 }} md={{ span: 6 }} sm={24} style={{ textAlign: 'center' }}>
                                    <Link to={'/create-case'}>
                                        <LandingWizardButton type={'button'}>{fields.wizard}</LandingWizardButton>
                                    </Link>
                                </Col> : null}
                                {appAuth.canAccess(this.props.userInfo, 'History') ? <Col xs={24} lg={{ span: 4 }} md={{ span: 6 }} sm={24} style={{ textAlign: 'center' }}>
                                    <Link to={'/cases'}>
                                        <LandingHistoryButton type={'button'}>{fields.history}</LandingHistoryButton>
                                    </Link>
                                </Col> : null}
                            </Row>
                        </div>
                    ) : (
                            <div>
                                <StyledAppWelcome>{fields.app_descr_credit_insitution}</StyledAppWelcome>
                                <Row type="flex" justify="center">
                                    <Select style={{ width: 120 }} onChange={item => this.props.updateCreditInstitution(item)}>
                                       {renderOptionItemsCredit(this.props.userInfo.creditInstitutions)}
                                    </Select>
                                </Row>
                            </div>)}
                </StyledLandingPage>
            </AppPage >
        )
    };
};

export default Landing;
